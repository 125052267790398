import initState from "../InitStates/simCompact-init-state";

const refactorCoins = (coins) => {
	let newCoins = [];

	if (coins.length > 0) {
		let coinsValue = coins.reduce((total, num) => {
			return parseFloat(total) + parseFloat(num);
		});

		while (coinsValue > 0) {
			if (coinsValue >= 10) {
				newCoins.push(10);
				coinsValue -= 10;
			} else if (coinsValue >= 1) {
				newCoins.push(1);
				coinsValue -= 1;
			} else {
				newCoins.push(0.5);
				coinsValue -= 0.5;
			}
		}
	}
	return newCoins;
};

const reducer = (state = initState, action) => {
	console.log("Reducer")
	console.log(action)
	switch (action.type) {
		case "UPDATE_STATE":
			return {
				...state,
				...action.data.state,
			};
		case "UPDATE_CAPITAL":
			return {
				...state,
				CenterBlock: {
					...state.CenterBlock,
					Finance: {
						...state.CenterBlock.Finance,
						equity: action.value,
					},
				},
			};
		case "ADD_CONTAINER":
			if (action.data.alias === "LeftBlock") {
				return {
					...state,
					LeftBlock: state.LeftBlock.map((content, i) =>
						i === action.data.id ? 
							{
								...content,
								hasContainer: true,
								coins: action.data.coins ? action.data.coins : [],
								playerId: action.data.playerId,
								playerName: action.data.playerName
							}
							: 
							content
					)
				}
			} else if (action.data.alias === "RightBlock") {
				return {
					...state,
					RightBlock: state.RightBlock.map((content, i) =>
						i === action.data.id ? 
							{
								...content,
								hasContainer: true,
								coins: action.data.coins ? action.data.coins : [],
								playerId: action.data.playerId,
								playerName: action.data.playerName
							}
							: 
							content
					)
				}
			} else if (action.data.alias === "BottomBlock") {
				return {
					...state,
					BottomBlock: state.BottomBlock.map((content, i) =>
						i === action.data.id ? 
							{
								...content,
								hasContainer: true,
								coins: action.data.coins ? action.data.coins : [],
								playerId: action.data.playerId,
								playerName: action.data.playerName
							}
							: 
							content
					)
				}
			} else if (action.data.alias === "Cash") {
				return {
					...state,
					CenterBlock: {
						...state.CenterBlock,
						Finance: {
							...state.CenterBlock.Finance,
							cash: state.CenterBlock.Finance.cash.map((content, i) =>
								i === action.data.id ? 
									{
										...content,
										hasContainer: true,
										coins: action.data.coins ? action.data.coins : [],
										playerId: action.data.playerId,
										playerName: action.data.playerName
									}
									: 
									content
							)
						}
					}
				}
			} else if (action.data.alias === "Receivables") {
				return {
					...state,
					CenterBlock: {
						...state.CenterBlock,
						Finance: {
							...state.CenterBlock.Finance,
							receivables: state.CenterBlock.Finance.receivables.map((content, i) =>
								i === action.data.id ? 
									{
										...content,
										hasContainer: true,
										coins: action.data.coins ? action.data.coins : [],
										playerId: action.data.playerId,
										playerName: action.data.playerName
									}
									: 
									content
							)
						}
					}
				}
			} else if (action.data.alias === "Sales") {
				return {
					...state,
					CenterBlock: {
						...state.CenterBlock,
						Sales: state.CenterBlock.Sales.map((salesObj, index) => {
							if (index === action.data.mainId) {
								salesObj.containers[action.data.id].hasContainer = true
								salesObj.containers[action.data.id].coins = action.data.coins ? action.data.coins : []
								salesObj.containers[action.data.id].hasCard = action.data.hasCard
								salesObj.containers[action.data.id].cardValue = action.data.cardValue
								salesObj.containers[action.data.id].cardColor = action.data.cardColor
								salesObj.containers[action.data.id].playerId = action.data.playerId
								salesObj.containers[action.data.id].playerName = action.data.playerName
							}
							return salesObj
						})
					}
				};
			} else if (action.data.alias === "Research") {
				return {
					...state,
					CenterBlock: {
						...state.CenterBlock,
						Research: {
							...state.CenterBlock.Research,
							[action.data.fieldType]: {
								...state.CenterBlock.Research[action.data.fieldType],
								hasContainer: true,
								coins: action.data.coins ? action.data.coins : [],
								playerId: action.data.playerId,
								playerName: action.data.playerName
							}
						}
					},
				};
			} else if (action.data.alias === "Purchase") {
				state.CenterBlock.Purchase[action.data.fieldType][
					action.data.id
				].hasContainer = true;
				state.CenterBlock.Purchase[action.data.fieldType][action.data.id].coins =
					action.data.coins ? action.data.coins : [];
				state.CenterBlock.Purchase[action.data.fieldType][action.data.id].playerId =
					action.data.playerId;
				state.CenterBlock.Purchase[action.data.fieldType][action.data.id].playerName =
					action.data.playerName;

				return {
					...state,
					CenterBlock: {
						...state.CenterBlock,
						Purchase: {
							...state.CenterBlock.Purchase,
						},
					},
				};
			} else if (action.data.alias === "Production") {
				return {
					...state,
					CenterBlock: {
						...state.CenterBlock,
						Production: state.CenterBlock.Production.map((content, i) => {
							if (i === action.data.id) {
								content[action.data.fieldType].hasContainer = true;
								content[action.data.fieldType].coins = action.data.coins ? action.data.coins : [];
								content[action.data.fieldType].hasCard = action.data.hasCard;
								content[action.data.fieldType].cardValue = action.data.cardValue;
								content[action.data.fieldType].cardColor = action.data.cardColor;
								content[action.data.fieldType].playerId = action.data.playerId;
								content[action.data.fieldType].playerName = action.data.playerName;
							}
							return content;
						}),
					},
				};
			} else if (action.data.alias === "DirectCost") {
				return {
					...state,
					DirectCost: state.DirectCost.map((content, i) =>
						i === action.data.id
							? {
									...content,
									hasContainer: true,
									coins: action.data.coins ? action.data.coins : [],
									hasCard: action.data.hasCard,
									cardValue: action.data.cardValue,
									cardColor: action.data.cardColor,
									playerId: action.data.playerId,
									playerName: action.data.playerName,
								}
							: content
					),
				};
			}
			break;
		case "REMOVE_CONTAINER":
			if (action.data.alias === "LeftBlock") {
				return {
					...state,
					LeftBlock: state.LeftBlock.map((content, i) =>
						i === action.data.id
							? { ...content, hasContainer: false, coins: [] }
							: content
					),
				};
			} else if (action.data.alias === "RightBlock") {
				return {
					...state,
					RightBlock: state.RightBlock.map((content, i) =>
						i === action.data.id
							? { ...content, hasContainer: false, coins: [] }
							: content
					),
				};
			} else if (action.data.alias === "BottomBlock") {
				return {
					...state,
					BottomBlock: state.BottomBlock.map((content, i) =>
						i === action.data.id
							? { ...content, hasContainer: false, coins: [] }
							: content
					),
				};
			} else if (action.data.alias === "Cash") {
				return {
					...state,
					CenterBlock: {
						...state.CenterBlock,
						Finance: {
							...state.CenterBlock.Finance,
							cash: state.CenterBlock.Finance.cash.map((content, i) =>
								i === action.data.id ? 
									{ 
										...content, 
										hasContainer: false, 
										coins: [] 
									}
									: content
							),
						},
					},
				};
			} else if (action.data.alias === "Receivables") {
				return {
					...state,
					CenterBlock: {
						...state.CenterBlock,
						Finance: {
							...state.CenterBlock.Finance,
							receivables: state.CenterBlock.Finance.receivables.map((content, i) =>
								i === action.data.id ? 
									{ 
										...content, 
										hasContainer: false, 
										coins: [] 
									}
									: 
									content
							),
						},
					},
				};
			} else if (action.data.alias === "Research") {
				state.CenterBlock.Research[action.data.fieldType].hasContainer = false;
				state.CenterBlock.Research[action.data.fieldType].coins = [];

				return {
					...state,
					CenterBlock: {
						...state.CenterBlock,
						Research: {
							...state.CenterBlock.Research,
						},
					},
				};
			} else if (action.data.alias === "Purchase") {
				state.CenterBlock.Purchase[action.data.fieldType][
					action.data.id
				].hasContainer = false;
				state.CenterBlock.Purchase[action.data.fieldType][action.data.id].coins = [];

				return {
					...state,
					CenterBlock: {
						...state.CenterBlock,
						Purchase: {
							...state.CenterBlock.Purchase,
						},
					},
				};
			} else if (action.data.alias === "Production") {
				return {
					...state,
					CenterBlock: {
						...state.CenterBlock,
						Production: state.CenterBlock.Production.map((content, i) => {
							if (i === action.data.id) {
								content[action.data.fieldType].hasContainer = false;
								content[action.data.fieldType].hasCard = false;
								if (content[action.data.fieldType].isFlip) {
									content[action.data.fieldType].isFlip = false;
								}
								content[action.data.fieldType].coins = [];
							}
							return content;
						}),
					},
				};
			} else if (action.data.alias === "DirectCost") {
				return {
					...state,
					RightBlock: {
						...state.RightBlock,
						cash: state.RightBlock.cash.map((content, i) =>
							i === action.data.id
								? { ...content, hasContainer: false, coins: [] }
								: content
						),
					},
				};
			} else if (action.data.alias === "Sales") {
				return {
					...state,
					CenterBlock: {
						...state.CenterBlock,
						Sales: state.CenterBlock.Sales.map((salesObj, index) => {
							if (index === action.data.mainId) {
								salesObj.containers[action.data.id].hasContainer = false
								salesObj.containers[action.data.id].hasCard = false;
								salesObj.containers[action.data.id].coins = []
							}
							return salesObj
						})
					}
				};
			}
			break;
		case "ADD_TOKEN":
			if (
				state.CenterBlock.Finance[action.tokenType].type === "" ||
				state.CenterBlock.Finance[action.tokenType].type ===
					action.droppedTokenType
			) {
				let newValue =
					parseInt(state.CenterBlock.Finance[action.tokenType].value) +
					parseInt(action.value);
				return {
					...state,
					CenterBlock: {
						...state.CenterBlock,
						Finance: {
							...state.CenterBlock.Finance,
							[action.tokenType]: {
								...state.CenterBlock.Finance[action.tokenType],
								value: newValue,
								type: action.droppedTokenType,
							},
						},
					},
				};
			}
			break;
		case "REMOVE_TOKEN":
			if (state.CenterBlock.Finance[action.tokenType].value !== 0) {
				const newValue =
					parseInt(state.CenterBlock.Finance[action.tokenType].value) -
					parseInt(action.value);
				return {
					...state,
					CenterBlock: {
						...state.CenterBlock,
						Finance: {
							...state.CenterBlock.Finance,
							[action.tokenType]: {
								...state.CenterBlock.Finance[action.tokenType],
								value: newValue,
								type:
									newValue === 0
										? ""
										: state.CenterBlock.Finance[action.tokenType].type,
							},
						},
					},
				};
			}
			break;
		case "ADD_SMILE":
			console.log(action);
			return {
				...state,
				BottomBlock: state.BottomBlock.map((content, index) => {
					if (index === action.id) {
						content.smileNum += action.number
					}
					return content
				})
			};
		case "REMOVE_SMILE_FACE":
			return {
				...state,
				BottomBlock: state.BottomBlock.map((content, index) => {
					if (index === action.id) {
						content.smileNum -= action.number

						if (content.smileNum < 0) {
							content.smileNum = 0
						}
					}
					return content
				})
			};

		case "ADD_PIN":
			if (action.alias === "Production") {
				return {
					...state,
					CenterBlock: {
						...state.CenterBlock,
						Production: state.CenterBlock.Production.map((content, i) => {
							if (i === action.id) {
								content.pins[action.speed][action.title] = true;
							}
							return content;
						}),
					},
				};
			} else if (action.alias === "Sales") {
				return {
					...state,
					CenterBlock: {
						...state.CenterBlock,
						Sales: state.CenterBlock.Sales.map((salesObj, index) => {
							if (index === action.id) {
								salesObj.pin = true
							}

							return salesObj
						})
					}
				};
			}
			break;
		case "REMOVE_PIN":
			if (action.alias === "Production") {
				return {
					...state,
					CenterBlock: {
						...state.CenterBlock,
						Production: state.CenterBlock.Production.map((content, i) => {
							if (i === action.id) {
								content.pins[action.speed][action.title] = false;
							}
							return content;
						}),
					},
				};
			} else if (action.alias === "Sales") {
				return {
					...state,
					CenterBlock: {
						...state.CenterBlock,
						Sales: {
							...state.CenterBlock.Sales,
							Pins: state.CenterBlock.Sales.Pins.map((content, i) =>
								i === action.id
									? {
											...content,
											hasPin: false,
											playerId: action.playerId,
											playerName: action.playerName,
										}
									: content
							),
						},
					},
				};
			}
			break;

		case "ADD_COIN":
			if (action.data.alias === "LeftBlock") {
				return {
					...state,
					LeftBlock: state.LeftBlock.map((stateObj, index) => {
						if (index === action.data.id) {
							let coinsList = [...stateObj.coins];
							coinsList.push(action.data.value);
							const lastCoinsList = refactorCoins(coinsList);
							
							stateObj.coins = lastCoinsList
							stateObj.playerId = action.data.playerId
							stateObj.playerName = action.data.playerName
						}

						return stateObj
					})
				};
			} else if (action.data.alias === "RightBlock") {
				return {
					...state,
					RightBlock: state.RightBlock.map((stateObj, index) => {
						if (index === action.data.id) {
							let coinsList = [...stateObj.coins];
							coinsList.push(action.data.value);
							const lastCoinsList = refactorCoins(coinsList);
							
							stateObj.coins = lastCoinsList
							stateObj.playerId = action.data.playerId
							stateObj.playerName = action.data.playerName
						}

						return stateObj
					})
				};
			} else if (action.data.alias === "Cash") {
				return {
					...state,
					CenterBlock: {
						...state.CenterBlock,
						Finance: {
							...state.CenterBlock.Finance,
							cash: state.CenterBlock.Finance.cash.map((stateObj, index) => {
								if (index === action.data.id) {
									let coinsList = [...stateObj.coins];
									coinsList.push(action.data.value);
									const lastCoinsList = refactorCoins(coinsList);
									
									stateObj.coins = lastCoinsList
									stateObj.playerId = action.data.playerId
									stateObj.playerName = action.data.playerName
								}
		
								return stateObj
							})
						},
					},
				};
			} else if (action.data.alias === "Receivables") {
				return {
					...state,
					CenterBlock: {
						...state.CenterBlock,
						Finance: {
							...state.CenterBlock.Finance,
							receivables: state.CenterBlock.Finance.receivables.map((stateObj, index) => {
								if (index === action.data.id) {
									let coinsList = [...stateObj.coins];
									coinsList.push(action.data.value);
									const lastCoinsList = refactorCoins(coinsList);
									
									stateObj.coins = lastCoinsList
									stateObj.playerId = action.data.playerId
									stateObj.playerName = action.data.playerName
								}
		
								return stateObj
							})
						},
					},
				};
			} else if (action.data.alias === "Sales") {
				console.log(action);
				let coinsList = [
					...state.CenterBlock.Sales.Pins[action.data.id.pinId].cash[
						action.data.id.contId
					].coins,
				];
				coinsList.push(action.data.value);
				coinsList = refactorCoins(coinsList);

				state.CenterBlock.Sales.Pins[action.data.id.pinId].cash[action.data.id.contId].coins = coinsList;
				state.CenterBlock.Sales.Pins[action.data.id.pinId].cash[action.data.id.contId].playerId = action.data.playerId;
				state.CenterBlock.Sales.Pins[action.data.id.pinId].cash[action.data.id.contId].playerName = action.data.playerName;
				return {
					...state,
					CenterBlock: {
						...state.CenterBlock,
						Sales: {
							...state.CenterBlock.Sales,
							Pins: [...state.CenterBlock.Sales.Pins],
						},
					},
				};
			} else if (action.data.alias === "Research") {
				let coinsList = [...state.CenterBlock.Research[action.data.fieldType].coins];
				coinsList.push(action.data.value);
				coinsList = refactorCoins(coinsList);

				state.CenterBlock.Research[action.data.fieldType].coins = coinsList;
				state.CenterBlock.Research[action.data.fieldType].playerId = action.data.playerId;
				state.CenterBlock.Research[action.data.fieldType].playerName = action.data.playerName;
				return {
					...state,
					CenterBlock: {
						...state.CenterBlock,
						Research: {
							...state.CenterBlock.Research,
						},
					},
				};
			} else if (action.data.alias === "Purchase") {
				let coinsList = [
					...state.CenterBlock.Purchase[action.data.fieldType][action.data.id].coins,
				];
				coinsList.push(action.data.value);
				coinsList = refactorCoins(coinsList);

				state.CenterBlock.Purchase[action.data.fieldType][action.data.id].coins = coinsList;
				state.CenterBlock.Purchase[action.data.fieldType][action.data.id].playerId = action.data.playerId;
				state.CenterBlock.Purchase[action.data.fieldType][action.data.id].playerName = action.data.playerName;
				return {
					...state,
					CenterBlock: {
						...state.CenterBlock,
						Purchase: {
							...state.CenterBlock.Purchase,
						},
					},
				};
			} else if (action.data.alias === "Production") {
				let coinsList = [
					...state.CenterBlock.Production[action.data.id][action.data.fieldType].coins,
				];
				coinsList.push(action.data.value);
				coinsList = refactorCoins(coinsList);

				state.CenterBlock.Production[action.data.id][action.data.fieldType].coins = coinsList;
				state.CenterBlock.Production[action.data.id][action.data.fieldType].playerId = action.data.playerId;
				state.CenterBlock.Production[action.data.id][action.data.fieldType].playerName = action.data.playerName;
				return {
					...state,
					CenterBlock: {
						...state.CenterBlock,
						Production: [...state.CenterBlock.Production],
					},
				};
			}
			break;
		case "REMOVE_COIN":
			if (action.alias === "LeftBlock") {
				let coinsList = [...state.LeftBlock[action.id].coins];
				coinsList.splice(action.coinIndex, 1);
				coinsList = refactorCoins(coinsList);

				state.LeftBlock[action.id].coins = coinsList;
				state.LeftBlock[action.id].playerId = action.playerId;
				state.LeftBlock[action.id].playerName = action.playerName;
				return {
					...state,
					LeftBlock: [...state.LeftBlock],
				};
			} else if (action.alias === "RightBlock") {
				let coinsList = [...state.RightBlock[action.id].coins];
				coinsList.splice(action.coinIndex, 1);
				coinsList = refactorCoins(coinsList);

				state.RightBlock[action.id].coins = coinsList;
				state.RightBlock[action.id].playerId = action.playerId;
				state.RightBlock[action.id].playerName = action.playerName;
				return {
					...state,
					RightBlock: [...state.RightBlock],
				};
			} else if (action.alias === "BottomBlock") {
				let coinsList = [...state.BottomBlock[action.id].coins];
				coinsList.splice(action.coinIndex, 1);
				coinsList = refactorCoins(coinsList);

				state.BottomBlock[action.id].coins = coinsList;
				state.BottomBlock[action.id].playerId = action.playerId;
				state.BottomBlock[action.id].playerName = action.playerName;
				return {
					...state,
					BottomBlock: [...state.BottomBlock],
				};
			} else if (action.alias === "Receivables") {
				let coinsList = [...state.CenterBlock.Finance.receivables[action.id].coins];
				coinsList.splice(action.coinIndex, 1);
				coinsList = refactorCoins(coinsList);

				state.CenterBlock.Finance.receivables[action.id].coins = coinsList;
				state.CenterBlock.Finance.receivables[action.id].playerId = action.playerId;
				state.CenterBlock.Finance.receivables[action.id].playerName =
					action.playerName;
				return {
					...state,
					CenterBlock: {
						...state.CenterBlock,
						Finance: {
							...state.CenterBlock.Finance,
							receivables: [...state.CenterBlock.Finance.receivables],
						},
					},
				};
			} else if (action.alias === "Cash") {
				let coinsList = [...state.CenterBlock.Finance.cash[action.id].coins];
				coinsList.splice(action.coinIndex, 1);
				coinsList = refactorCoins(coinsList);

				state.CenterBlock.Finance.cash[action.id].coins = coinsList;
				state.CenterBlock.Finance.cash[action.id].playerId = action.playerId;
				state.CenterBlock.Finance.cash[action.id].playerName =
					action.playerName;
				return {
					...state,
					CenterBlock: {
						...state.CenterBlock,
						Finance: {
							...state.CenterBlock.Finance,
							cash: [...state.CenterBlock.Finance.cash],
						},
					},
				};
			} else if (action.alias === "Research") {
				let coinsList = [...state.CenterBlock.Research[action.fieldType].coins];
				coinsList.splice(action.coinIndex, 1);
				coinsList = refactorCoins(coinsList);

				state.CenterBlock.Research[action.fieldType].coins = coinsList;
				state.CenterBlock.Research[action.fieldType].playerId = action.playerId;
				state.CenterBlock.Research[action.fieldType].playerName =
					action.playerName;
				return {
					...state,
					CenterBlock: {
						...state.CenterBlock,
						Research: {
							...state.CenterBlock.Research,
						},
					},
				};
			} else if (action.alias === "Purchase") {
				let coinsList = [
					...state.CenterBlock.Purchase[action.fieldType][action.id].coins,
				];
				coinsList.splice(action.coinIndex, 1);
				coinsList = refactorCoins(coinsList);

				state.CenterBlock.Purchase[action.fieldType][action.id].coins =
					coinsList;
				state.CenterBlock.Purchase[action.fieldType][action.id].playerId =
					action.playerId;
				state.CenterBlock.Purchase[action.fieldType][action.id].playerName =
					action.playerName;
				return {
					...state,
					CenterBlock: {
						...state.CenterBlock,
						Purchase: {
							...state.CenterBlock.Purchase,
						},
					},
				};
			} else if (action.alias === "Production") {
				let coinsList = [
					...state.CenterBlock.Production[action.id][action.fieldType].coins,
				];
				coinsList.splice(action.coinIndex, 1);
				coinsList = refactorCoins(coinsList);

				state.CenterBlock.Production[action.id][action.fieldType].coins =
					coinsList;
				state.CenterBlock.Production[action.id][action.fieldType].playerId =
					action.playerId;
				state.CenterBlock.Production[action.id][action.fieldType].playerName =
					action.playerName;
				return {
					...state,
					CenterBlock: {
						...state.CenterBlock,
						Production: [...state.CenterBlock.Production],
					},
				};
			}
			break;
		case "ADD_COINS":
			if (action.alias === "LeftBlock") {
				let coinsList = state.LeftBlock[action.id].coins;
				coinsList = coinsList.concat(action.coins);
				coinsList = refactorCoins(coinsList);

				return {
					...state,
					LeftBlock: state.LeftBlock.map((content, i) => {
						if (i === action.id) {
							content.coins = coinsList;
							content.playerId = action.playerId;
							content.playerName = action.playerName;
						}
						return content;
					}),
				};
			} else if (action.alias === "RightBlock") {
				let coinsList = state.RightBlock[action.id].coins;
				coinsList = coinsList.concat(action.coins);
				coinsList = refactorCoins(coinsList);

				return {
					...state,
					RightBlock: state.RightBlock.map((content, i) => {
						if (i === action.id) {
							content.coins = coinsList;
							content.playerId = action.playerId;
							content.playerName = action.playerName;
						}
						return content;
					}),
				};
			} else if (action.alias === "BottomBlock") {
				let coinsList = state.BottomBlock[action.id].coins;
				coinsList = coinsList.concat(action.coins);
				coinsList = refactorCoins(coinsList);

				return {
					...state,
					BottomBlock: state.BottomBlock.map((content, i) => {
						if (i === action.id) {
							content.coins = coinsList;
							content.playerId = action.playerId;
							content.playerName = action.playerName;
						}
						return content;
					}),
				};
			} else if (action.alias === "cash") {
				return {
					...state,
					CenterBlock: {
						...state.CenterBlock,
						Finance: {
							...state.CenterBlock.Finance,
							cash: state.CenterBlock.Finance.cash.map((stateObj, index) => {
								if (index === action.id) {
									let coinsList = [...stateObj.coins];
									coinsList = coinsList.concat(action.coins);
									const lastCoinsList = refactorCoins(coinsList);
									
									stateObj.coins = lastCoinsList
									stateObj.playerId = action.playerId
									stateObj.playerName = action.playerName
								}
		
								return stateObj
							})
						},
					},
				};
			} else if (action.alias === "receivables") {
				return {
					...state,
					CenterBlock: {
						...state.CenterBlock,
						Finance: {
							...state.CenterBlock.Finance,
							receivables: state.CenterBlock.Finance.receivables.map((stateObj, index) => {
								if (index === action.id) {
									let coinsList = [...stateObj.coins];
									coinsList = coinsList.concat(action.coins);
									const lastCoinsList = refactorCoins(coinsList);
									
									stateObj.coins = lastCoinsList
									stateObj.playerId = action.playerId
									stateObj.playerName = action.playerName
								}
		
								return stateObj
							})
						},
					},
				};
			} else if (action.alias === "Research") {
				let coinsList = state.CenterBlock.Research[action.fieldType].coins;
				coinsList = coinsList.concat(action.coins);
				coinsList = refactorCoins(coinsList);

				state.CenterBlock.Research[action.fieldType].coins = coinsList;
				state.CenterBlock.Research[action.fieldType].playerId = action.playerId;
				state.CenterBlock.Research[action.fieldType].playerName =
					action.playerName;
				return {
					...state,
					CenterBlock: {
						...state.CenterBlock,
						Research: {
							...state.CenterBlock.Research,
						},
					},
				};
			} else if (action.alias === "Purchase") {
				let coinsList =
					state.CenterBlock.Purchase[action.fieldType][action.id].coins;
				coinsList = coinsList.concat(action.coins);
				coinsList = refactorCoins(coinsList);

				state.CenterBlock.Purchase[action.fieldType][action.id].coins =
					coinsList;
				state.CenterBlock.Purchase[action.fieldType][action.id].playerId =
					action.playerId;
				state.CenterBlock.Purchase[action.fieldType][action.id].playerName =
					action.playerName;
				return {
					...state,
					CenterBlock: {
						...state.CenterBlock,
						Purchase: {
							...state.CenterBlock.Purchase,
						},
					},
				};
			} else if (action.alias === "Production") {
				let coinsList =
					state.CenterBlock.Production[action.id][action.fieldType].coins;
				coinsList = coinsList.concat(action.coins);
				coinsList = refactorCoins(coinsList);

				return {
					...state,
					CenterBlock: {
						...state.CenterBlock,
						Production: state.CenterBlock.Production.map((content, i) => {
							if (i === action.id) {
								content[action.fieldType].coins = coinsList;
								content[action.fieldType].playerId = action.playerId;
								content[action.fieldType].playerName = action.playerName;
							}
							return content;
						}),
					},
				};
			}
			break;
		case "UPDATE_COINS":
			if (action.alias === "LeftBlock") {
				return {
					...state,
					LeftBlock: state.LeftBlock.map((content, i) => {
						if (i === action.id) {
							content.coins = action.coins;
							content.playerId = action.playerId;
							content.playerName = action.playerName;
						}
						return content;
					}),
				};
			} else if (action.alias === "RightBlock") {
				return {
					...state,
					RightBlock: state.RightBlock.map((content, i) => {
						if (i === action.id) {
							content.coins = action.coins;
							content.playerId = action.playerId;
							content.playerName = action.playerName;
						}
						return content;
					}),
				};
			} else if (action.alias === "BottomBlock") {
				return {
					...state,
					BottomBlock: state.BottomBlock.map((content, i) => {
						if (i === action.id) {
							content.coins = action.coins;
							content.playerId = action.playerId;
							content.playerName = action.playerName;
						}
						return content;
					}),
				};
			} else if (action.alias === "cash") {
				return {
					...state,
					CenterBlock: {
						...state.CenterBlock,
						Finance: {
							...state.CenterBlock.Finance,
							cash: state.CenterBlock.Finance.cash.map((content, i) => {
								if (i === action.id) {
									content.coins = action.coins;
									content.playerId = action.playerId;
									content.playerName = action.playerName;
								}
								return content;
							}),
						},
					},
				};
			} else if (action.alias === "receivables") {
				return {
					...state,
					CenterBlock: {
						...state.CenterBlock,
						Finance: {
							...state.CenterBlock.Finance,
							receivables: state.CenterBlock.Finance.receivables.map((stateObj, index) => {
								if (index === action.id) {
									stateObj.coins = action.coins
									stateObj.playerId = action.playerId
									stateObj.playerName = action.playerName
								}
		
								return stateObj
							})
						},
					},
				};
			} else if (action.alias === "Research") {
				state.CenterBlock.Research[action.fieldType].coins = action.coins;
				state.CenterBlock.Research[action.fieldType].playerId = action.playerId;
				state.CenterBlock.Research[action.fieldType].playerName =
					action.playerName;

				return {
					...state,
					CenterBlock: {
						...state.CenterBlock,
						Research: {
							...state.CenterBlock.Research,
						},
					},
				};
			} else if (action.alias === "Purchase") {
				state.CenterBlock.Purchase[action.fieldType][action.id].coins =
					action.coins;
				state.CenterBlock.Purchase[action.fieldType][action.id].playerId =
					action.playerId;
				state.CenterBlock.Purchase[action.fieldType][action.id].playerName =
					action.playerName;

				return {
					...state,
					CenterBlock: {
						...state.CenterBlock,
						Purchase: {
							...state.CenterBlock.Purchase,
						},
					},
				};
			} else if (action.alias === "Production") {
				return {
					...state,
					CenterBlock: {
						...state.CenterBlock,
						Production: state.CenterBlock.Production.map((content, i) => {
							if (i === action.id) {
								content[action.fieldType].coins = action.coins;
								content[action.fieldType].playerId = action.playerId;
								content[action.fieldType].playerName = action.playerName;
							}
							return content;
						}),
					},
				};
			}
			break;
		case "ADD_CARD":
			return {
				...state,
				CenterBlock: {
					...state.CenterBlock,
					Production: state.CenterBlock.Production.map((content, i) => {
						if (i === action.id) {
							content[action.optionType].hasCard = true;
							content[action.optionType].cardColor = action.cardColor;
							content[action.optionType].cardValue = action.cardValue;
						}
						return content;
					}),
				},
			};
		case "FACTORY_PURCHASE":
			return {
				...state,
				CenterBlock: {
					...state.CenterBlock,
					Production: state.CenterBlock.Production.map((content, i) => {
						if (i === action.id) {
							content.factory.isFlip = false;
						}
						return content;
					}),
				},
			};
		case "FACTORY_RENT":
			return {
				...state,
				CenterBlock: {
					...state.CenterBlock,
					Production: state.CenterBlock.Production.map((content, i) => {
						if (i === action.id) {
							content.factory.coins = [];
							content.factory.isFlip = true;
						}
						return content;
					}),
				},
			};
		case "UPDATE_DRAGGING":
			if (action.alias === "LeftBlock") {
				state.LeftBlock[action.data.id].isDragging = action.isDragging;
				state.LeftBlock[action.data.id].type = action.itemType;
				//if (action.isDragging)
				state.LeftBlock[action.data.id].playerId = action.data.playerId;
				state.LeftBlock[action.data.id].playerName = action.data.playerName;

				if (action.itemType === "COIN") {
					state.LeftBlock[action.data.id].coinsDraggingIndex = action.data
						.isDragging
						? action.data.index
						: -1;
				}

				return {
					...state,
					LeftBlock: [...state.LeftBlock],
				};
			} else if (action.alias === "RightBlock") {
				state.RightBlock[action.data.id].isDragging = action.isDragging;
				state.RightBlock[action.data.id].type = action.itemType;
				//if (action.isDragging)
				state.RightBlock[action.data.id].playerId = action.data.playerId;
				state.RightBlock[action.data.id].playerName = action.data.playerName;

				if (action.itemType === "COIN") {
					state.RightBlock[action.data.id].coinsDraggingIndex = action.data
						.isDragging
						? action.data.index
						: -1;
				}

				return {
					...state,
					RightBlock: [...state.RightBlock],
				};
			} else if (action.alias === "BottomBlock") {
				state.BottomBlock[action.data.id].isDragging = action.isDragging;
				state.BottomBlock[action.data.id].type = action.itemType;
				//if (action.isDragging)
				state.BottomBlock[action.data.id].playerId = action.data.playerId;
				state.BottomBlock[action.data.id].playerName = action.data.playerName;

				if (action.itemType === "COIN") {
					state.BottomBlock[action.data.id].coinsDraggingIndex = action.data
						.isDragging
						? action.data.index
						: -1;
				}

				return {
					...state,
					BottomBlock: [...state.BottomBlock],
				};
			} else if (action.alias === "Finance") {
				state.CenterBlock.Finance.cash[action.data.id].isDragging =
					action.isDragging;
				state.CenterBlock.Finance.cash[action.data.id].type = action.itemType;
				//if (action.isDragging)
				state.CenterBlock.Finance.cash[action.data.id].playerId =
					action.data.playerId;
				state.CenterBlock.Finance.cash[action.data.id].playerName =
					action.data.playerName;

				if (action.itemType === "COIN") {
					state.CenterBlock.Finance.cash[action.data.id].coinsDraggingIndex =
						action.isDragging ? action.data.index : -1;
				}

				return {
					...state,
					CenterBlock: {
						...state.CenterBlock,
						Finance: {
							...state.CenterBlock.Finance,
							cash: [...state.CenterBlock.Finance.cash],
						},
					},
				};
			} else if (action.alias === "Research") {
				state.CenterBlock.Research[action.data.fieldType].isDragging =
					action.isDragging;
				state.CenterBlock.Research[action.data.fieldType].type =
					action.itemType;
				//if (action.isDragging)
				state.CenterBlock.Research[action.data.fieldType].playerId =
					action.data.playerId;
				state.CenterBlock.Research[action.data.fieldType].playerName =
					action.data.playerName;

				if (action.itemType === "COIN") {
					state.CenterBlock.Research[action.data.fieldType].coinsDraggingIndex =
						action.data.isDragging ? action.data.index : -1;
				}

				return {
					...state,
					CenterBlock: {
						...state.CenterBlock,
						Research: {
							...state.CenterBlock.Research,
						},
					},
				};
			} else if (action.alias === "Purchase") {
				console.log(action.data)
				state.CenterBlock.Purchase[action.data.fieldType][
					action.data.id
				].isDragging = action.isDragging;
				state.CenterBlock.Purchase[action.data.fieldType][action.data.id].type =
					action.itemType;
				//if (action.isDragging)
				state.CenterBlock.Purchase[action.data.fieldType][
					action.data.id
				].playerId = action.data.playerId;
				state.CenterBlock.Purchase[action.data.fieldType][
					action.data.id
				].playerName = action.data.playerName;

				if (action.itemType === "COIN") {
					state.CenterBlock.Purchase[action.data.fieldType][
						action.data.id
					].coinsDraggingIndex = action.data.isDragging
						? action.data.index
						: -1;
				}

				return {
					...state,
					CenterBlock: {
						...state.CenterBlock,
						Purchase: {
							...state.CenterBlock.Purchase,
						},
					},
				};
			} else if (action.alias === "Production") {
				state.CenterBlock.Production[action.data.id][
					action.data.fieldType
				].isDragging = action.isDragging;
				state.CenterBlock.Production[action.data.id][
					action.data.fieldType
				].type = action.itemType;
				//if (action.isDragging)
				state.CenterBlock.Production[action.data.id][
					action.data.fieldType
				].playerId = action.data.playerId;
				state.CenterBlock.Production[action.data.id][
					action.data.fieldType
				].playerName = action.data.playerName;

				if (action.itemType === "COIN") {
					state.CenterBlock.Production[action.data.id][
						action.data.fieldType
					].coinsDraggingIndex = action.data.isDragging
						? action.data.index
						: -1;
				}

				return {
					...state,
					CenterBlock: {
						...state.CenterBlock,
						Production: [...state.CenterBlock.Production],
					},
				};
			} else if (action.alias === "ColorField") {
				state.CenterBlock.Stock[action.data.fieldType][
					action.data.id
				].isDragging = action.isDragging;
				state.CenterBlock.Stock[action.data.fieldType][action.data.id].type =
					action.itemType;
				//if (action.isDragging){
				state.CenterBlock.Stock[action.data.fieldType][
					action.data.id
				].playerId = action.data.playerId;
				state.CenterBlock.Stock[action.data.fieldType][
					action.data.id
				].playerName = action.data.playerName;
				//}

				return {
					...state,
					CenterBlock: {
						...state.CenterBlock,
						Stock: {
							...state.CenterBlock.Stock,
						},
					},
				};
			}
			break;
		case "UPDATE_CAPITAL_STATUS":
			return {
				...state,
				CenterBlock: {
					...state.CenterBlock,
					Finance: {
						...state.CenterBlock.Finance,
						isDragging: action.isDragging,
						type: action.itemType,
						playerId: action.data.playerId,
						playerName: action.data.playerName
					},
				},
			};
		default:
			break;
	}

	return state;
};

export default reducer;
