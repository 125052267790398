import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";

// Components
import FinanceBlock from "./FinanceBlock/FinanceBlock";
import ProductionBlock from "./ProductionBlock/ProductionBlock";
import PurchaseBlock from "./PurchaseBlock/PurchaseBlock";
import ResearchBlock from "./ResearchBlock/ResearchBlock";
import SalesBlock from "./SalesBlock/SalesBlock";

export default function CenterBlock(props) {
	const [canEdit, setCanEdit] = useState(false);

	useEffect(() => {
		setCanEdit(props.canEdit);
	}, [props.canEdit]);

	return (
		<div className="Center-block">
			<Row className="mb-2">
				<div className="Finance-container-margin">
					<FinanceBlock canEdit={canEdit} />
				</div>
				<div className="Research-Purchasing-container">
					<div className="Research-Container">
						<ResearchBlock canEdit={canEdit} />
					</div>
					<div className="border-left-grey mx-3" />
					<div className="Purchase-Container">
						<PurchaseBlock canEdit={canEdit} />
					</div>
				</div>
			</Row>
			<Row>
				<div className="Production-container">
					<ProductionBlock canEdit={canEdit} />
				</div>
				<div className="Sales-container">
					<SalesBlock canEdit={canEdit} />
				</div>
			</Row>
		</div>
	);
}
