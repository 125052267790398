import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import DroppableField from "../../Common/DroppableField";

import * as RealtimeConnection from "../../RealtimeConnection";
import dropFieldClassNames from "../../../utils/dropFieldClassNames";
import Translate from "../../../utils/Translate";

export default function ResearchRow({ className, title, rowKey, canEdit }) {
	const dispatch = useDispatch()
	const state = useSelector((state) => state.board.CenterBlock.Research);

	const addCoinHandler = (id, coinValue) => {
		RealtimeConnection.addCoin("Research", {
		  id: id,
		  value: coinValue,
		  fieldType: rowKey
		});
	};

	const removeCoinHandler = (id, index, isDropToDelete) => {
		// Update Socket
		RealtimeConnection.removeCoin('Research', {
			id: id,
			index: index,
			fieldType: rowKey,
			isDropToDelete: isDropToDelete
		});
	};

	const appendContainer = (coins, id) => {
		RealtimeConnection.addContainer("Research", {
			id: id,
			coins: coins,
			fieldType: rowKey,
		});
	};

	const isDraggingHandler = (type, isDragging, data) => {
		let dataObj = {
			teamId: window.teamId,
			sessionId: window.sessionId,
			playerId: window.playerId,
			playerName: window.playerName,
			alias: "Research",
			fieldType: rowKey,
			isDragging: isDragging,
			type: type,
		};
		dataObj = { ...dataObj, ...data };

		window.socket.emit("item-dragging", dataObj);
	};
	
	const removeContainerHandler = (id, isDropToDelete) => {
		// Update Socket
		RealtimeConnection.removeContainer("Research", {
			id: id,
			isDropToDelete: isDropToDelete,
			fieldType: rowKey,
		});
	};

	const showContainerProperties = () => {
		dispatch({type: "PROPERTIES", alias: "FIELD", options: { fieldName: "Research", title: rowKey }})
	}

	return (
		<Row className={`Research-row ${className} justify-content-between `}>
			<div className="title Block-center">
				<div><Translate alias="Board" word={title} /></div>
			</div>
			<div className="droppable-field field">
				<Col onClick={ () => showContainerProperties() }>
					<DroppableField
						id={0}
						canEdit={ canEdit }
						class={dropFieldClassNames.Finance}
						isHorizontal={true}
						data={state[rowKey]}
						appendContainer={appendContainer}
						addCoin={addCoinHandler}
						removeCoin={removeCoinHandler}
						kind={"Research"}
						design={"dim"}
						removeContainer={removeContainerHandler}
						isDraggingHandler={isDraggingHandler}
					/>
				</Col>
			</div>
		</Row>
	);
}
