import React, { useState, useEffect } from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import { useSelector } from "react-redux";

// Images
import kitsIcon from "../assets/images/kits-icon.png";

// Dispatchers
// Constants
import coins from "../utils/coins";

import * as RealtimeConnection from "./RealtimeConnection";

// Components
import MainDraggableContainer from "./StarterKit/MainDraggableContainer";
import MainDraggableToken from "./StarterKit/MainDraggableToken";
// import MainDraggableBlackToken from "./StarterKit/MainDraggableBlackToken";
import MainDraggablePin from "./StarterKit/MainDraggablePin";
import MainDraggableCoin from "./StarterKit/MainDraggableCoin";
import MainDraggableFactory from "./StarterKit/MainDraggableFactory";
import MainDraggableMachine from "./StarterKit/MainDraggableMachine";
import MainDroppableDelete from "./StarterKit/MainDroppableDelete";
import MainDraggableSmileFace from "./StarterKit/MainDraggableSmileFace";
import MainDraggableProductCard from "./StarterKit/MainDraggableProductCard"
import Translate from "../utils/Translate";

const KitsAndObjects = (props) => {
	const startingKit = useSelector(state => state.startingKit)

	const [canEdit, setCanEdit] = useState(true)
	const [isMenuOpened, setIsMenuOpened] = useState(false);
	const [lbls, setLbls] = useState([])


	const toggleMenu = () => {
		if (isMenuOpened) {
			setIsMenuOpened(false);
			updateUI("MENU", "close");
		} else {
			setIsMenuOpened(true);
			updateUI("MENU", "open");
		}
	};

	const updateUI = (panelType, status) => {
		const appParentContainer = document.getElementsByClassName("main-app-bg");
		const appContainer = document.getElementsByClassName("App");
		const menuContainer = document.getElementsByClassName("kitContainer");

		if (status === "close") {
			appContainer[0].classList.remove("moveLeft");
			menuContainer[0].classList.remove("fullWidth");
		} else {
			//appParentContainer[0].classList.remove("width");
			//appContainer[0].classList.remove("moveRight");
			appContainer[0].classList.add("moveLeft");

			if (panelType === "MENU") {
				menuContainer[0].classList.add("fullWidth");
			} else {
				this.setState({ isMenuOpened: false }, () => {
					menuContainer[0].classList.remove("fullWidth");
				});
			}
		}
	};

	const decreaseStarterKit = (type, value, color) => {
		// Update Socket
		RealtimeConnection.decreaseStarterKit({
			type: type,
			value: value,
			color: color
		})
	}

	const increaseStarterKit = (type, value, color) => {
		// Update Socket
		RealtimeConnection.increaseStarterKit({
			type: type,
			value: value,
			color: color
		})
	}

	useEffect(() => {
		setLbls([])

		let lblsTmp = [];
		let count = 0;
		if (startingKit.production !== undefined && Object.keys(startingKit.production).length > 0) {
			console.log("startingKit.production")
			console.log(startingKit.production)
			Object.keys(startingKit.production).map( value => {
				console.log(value)
				Object.keys(startingKit.production[value]).map((value2, index) => {
					console.log(value2)
					if(startingKit.production[value][value2] > 0) {
						lblsTmp.push(
							<React.Fragment key={ value + "_" + value2 + "_" + index }>
								{
									(count % 2 === 0) ?
										<div className="w-100"></div>
										:
										null
								}
								<Col>
									<MainDraggableProductCard
										canEdit={canEdit}
										color={value2}
										value={value}
										decreaseStarterKit={decreaseStarterKit}
									/>
								</Col>
							</React.Fragment>
						)
						count++;
					}
				})
			})
		}

		setLbls(lblsTmp)
	}, [startingKit.production])

	console.log(lbls)
	console.log(startingKit)
	return (
		<>
			<div className="kits-objects-btn" onClick={toggleMenu}>
				<div className="button">
				<Image src={kitsIcon} />
				<div className="text">
					<Translate alias="Board" word="Team Table" />
				</div>
				</div>
			</div>
      		<div className="kitContainer">
				<div className="kits-header">
					<div className="kits-header-logo">
						<Image src={kitsIcon} />
						<div className="text"><Translate alias="Board" word="Team Table" /></div>
					</div>
				</div>
				<div className="blur">
					<div className="object-container-div">
						<div className="container-text">
							<Translate alias="Board" word="Containers" />
						</div>
						<MainDraggableContainer />
					</div>
					<div className="kits-separator"></div>
					<div className="object-coins-div">
						<div className="container-text">
							<Translate alias="Board" word="Coins" />
							<div className="object-value">
								<span className="notranslate">{ startingKit.coins }</span>
							</div>
						</div>
						<Container>
							<Row>
								{
									coins.map((value, index) => (
										<Col key={index}>
											<MainDraggableCoin
												decreaseStarterKit={decreaseStarterKit}
												value={value}
												count={ startingKit.coins }
											/>
										</Col>
									))
								}
							</Row>
						</Container>
					</div>
					<div className="kits-separator"></div>

					<div className="object-labels-div">
						<div className="container-text">
							<Translate alias="Board" word="Labels" />
						</div>
						<Container>
							<Row>
								<Col>
									<MainDraggableFactory 
										decreaseStarterKit={decreaseStarterKit}
										count={ startingKit.factory }
									/>
								</Col>
								<Col>
									<MainDraggableMachine
										decreaseStarterKit={decreaseStarterKit}
										count={ startingKit.machine }
									/>
								</Col>
								<div className="w-100"></div>
								<Col>
									<div className="object-value-center">
										<span className="notranslate">
											{ startingKit.factory }
										</span>
									</div>
								</Col>
								<Col>
									<div className="object-value-center">
										<span className="notranslate">
											{ startingKit.machine }
										</span>
									</div>
								</Col>
							</Row>
						</Container>
					</div>
					<div className="kits-separator"></div>
					{
						(startingKit.pins > 0 &&
							<div className="object-pins-div">
								<div className="container-text">
									<Translate alias="Board" word="Pins" />
									<div className="object-value">
										<span className="notranslate">{ startingKit.pins }</span>
									</div>
								</div>
								<div className="draggable-pins-container">
									<Container>
										<Row>
											{
												Array.apply(null, { length: startingKit.pins }).map((e, i) => (
													<React.Fragment key={i}>
														{i % 6 === 0 ? <div className="w-100"></div> : null}
														<MainDraggablePin
															key={i}
															count={ startingKit.pins }
															decreaseStarterKit={decreaseStarterKit}
														/>
													</React.Fragment>
												))
											}
										</Row>
									</Container>
								</div>
							</div>
						)
					}
					<div className="object-pins-div">
						<div className="container-text">
							<Translate alias="Board" word="Smiley Face" />
							<div className="object-value">
								<span className="notranslate">{ startingKit.smileFace }</span>
							</div>
						</div>
						<div className="draggable-pins-container">
						<Container>
							<Row>
								<MainDraggableSmileFace 
									count={ startingKit.smileFace }
									decreaseStarterKit={decreaseStarterKit}
								/>
								{/* <div className="smileDraggable">
									<Image src={SmileFace} />
								</div> */}
							</Row>
						</Container>
						</div>
					</div>
					<div className="kits-separator"></div>
					{
						(startingKit.tokens > 0) ?
							<>
								<div className="object-tokens-div">
									<div className="container-text">
										<Translate alias="Board" word="Liability tokens" />
									</div>
									<Container>
										<Row>
											{
												(startingKit.tokens > 0) ?
													<Col>
														<MainDraggableToken
															decreaseStarterKit={decreaseStarterKit}
															canEdit={ true }
															count={startingKit.tokens}
															value={startingKit.tokens}
														/>
													</Col>
													:
													null
											}
										</Row>
									</Container>
								</div>
								<div className="kits-separator"></div>
							</>
							:
							null
					}
					{
						(lbls.length > 0) ?
							<>
								<div className="object-pins-div">
									<div className="container-text">
										<Translate alias="Board" word="Production units" />
									</div>
									<div style={{ "display": "flex" }}>
										<div style={{ "width": "65%", "margin": "0 auto" }}>
											<Container>
												<Row>
													{lbls}
												</Row>
											</Container>
										</div>
									</div>
								</div>
								<div className="kits-separator"></div>
							</>
							:
							null
					}
					<div className="object-delete-div">
						<div className="container-text">
							<Translate alias="Board" word="Delete" />
						</div>
						<MainDroppableDelete
							increaseStarterKit={increaseStarterKit}
						/>
						<div className="clearfix"></div>
					</div>
					<div className="kits-separator"></div>
					<div style={{ height: "30px" }}></div>
				</div>
      		</div>
		</>
	);
};

export default KitsAndObjects;
