const initState = {
	isFieldSelected: false,
	type: "", // Token, Field
	alias: "",
	options: {}
}


const reducer = (state = initState, action) => {
	if (action.type === "PROPERTIES") {
		switch (action.alias) {
			case "TOKEN":
				return {
					isFieldSelected: true,
					type: "token",
					alias: action.alias,
					options: action.options
				}
			case "FIELD":
				return {
					isFieldSelected: true,
					type: "field",
					alias: action.alias,
					options: action.options
				}
			case "SMILEY":
				return {
					isFieldSelected: true,
					type: "smiley",
					alias: action.alias,
					options: action.options
				}
			default:
				break;
		}
	}else if (action.type === "CLOSE_PROPERTIES") {
		return {
			isFieldSelected: false,
			type: "",
			alias: "",
			options: {}
		}
	}

	return state;
}

export default reducer;