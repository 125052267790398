import React from 'react';
import { Table, FormControl } from 'react-bootstrap';

import Translate from '../../../utils/Translate'

class BalanceSheet extends React.Component {

	handleChange = (field, value, period) => {
		this.props.inputChanged("balanceSheet", field, value, period)
	}

	render() {

		let totalAssets_0 = 0;
		totalAssets_0 += (!isNaN(parseFloat(this.props.balanceSheet.period_0.cash))) ? parseFloat(this.props.balanceSheet.period_0.cash) : 0
		totalAssets_0 += (!isNaN(parseFloat(this.props.balanceSheet.period_0.receivables))) ? parseFloat(this.props.balanceSheet.period_0.receivables) : 0
		totalAssets_0 += (!isNaN(parseFloat(this.props.balanceSheet.period_0.inventories))) ? parseFloat(this.props.balanceSheet.period_0.inventories) : 0
		totalAssets_0 += (!isNaN(parseFloat(this.props.balanceSheet.period_0.machines))) ? parseFloat(this.props.balanceSheet.period_0.machines) : 0
		totalAssets_0 += (!isNaN(parseFloat(this.props.balanceSheet.period_0.productionFacilities))) ? parseFloat(this.props.balanceSheet.period_0.productionFacilities) : 0
		totalAssets_0 += (!isNaN(parseFloat(this.props.balanceSheet.period_0.capitalizedDevelopmentCosts))) ? parseFloat(this.props.balanceSheet.period_0.capitalizedDevelopmentCosts) : 0

		let totalAssets_1 = 0;
		totalAssets_1 += (!isNaN(parseFloat(this.props.balanceSheet.period_1.cash))) ? parseFloat(this.props.balanceSheet.period_1.cash) : 0
		totalAssets_1 += (!isNaN(parseFloat(this.props.balanceSheet.period_1.receivables))) ? parseFloat(this.props.balanceSheet.period_1.receivables) : 0
		totalAssets_1 += (!isNaN(parseFloat(this.props.balanceSheet.period_1.inventories))) ? parseFloat(this.props.balanceSheet.period_1.inventories) : 0
		totalAssets_1 += (!isNaN(parseFloat(this.props.balanceSheet.period_1.machines))) ? parseFloat(this.props.balanceSheet.period_1.machines) : 0
		totalAssets_1 += (!isNaN(parseFloat(this.props.balanceSheet.period_1.productionFacilities))) ? parseFloat(this.props.balanceSheet.period_1.productionFacilities) : 0
		totalAssets_1 += (!isNaN(parseFloat(this.props.balanceSheet.period_1.capitalizedDevelopmentCosts))) ? parseFloat(this.props.balanceSheet.period_1.capitalizedDevelopmentCosts) : 0

		let totalAssets_2 = 0;
		totalAssets_2 += (!isNaN(parseFloat(this.props.balanceSheet.period_2.cash))) ? parseFloat(this.props.balanceSheet.period_2.cash) : 0
		totalAssets_2 += (!isNaN(parseFloat(this.props.balanceSheet.period_2.receivables))) ? parseFloat(this.props.balanceSheet.period_2.receivables) : 0
		totalAssets_2 += (!isNaN(parseFloat(this.props.balanceSheet.period_2.inventories))) ? parseFloat(this.props.balanceSheet.period_2.inventories) : 0
		totalAssets_2 += (!isNaN(parseFloat(this.props.balanceSheet.period_2.machines))) ? parseFloat(this.props.balanceSheet.period_2.machines) : 0
		totalAssets_2 += (!isNaN(parseFloat(this.props.balanceSheet.period_2.productionFacilities))) ? parseFloat(this.props.balanceSheet.period_2.productionFacilities) : 0
		totalAssets_2 += (!isNaN(parseFloat(this.props.balanceSheet.period_2.capitalizedDevelopmentCosts))) ? parseFloat(this.props.balanceSheet.period_2.capitalizedDevelopmentCosts) : 0

		let totalAssets_3 = 0;
		totalAssets_3 += (!isNaN(parseFloat(this.props.balanceSheet.period_3.cash))) ? parseFloat(this.props.balanceSheet.period_3.cash) : 0
		totalAssets_3 += (!isNaN(parseFloat(this.props.balanceSheet.period_3.receivables))) ? parseFloat(this.props.balanceSheet.period_3.receivables) : 0
		totalAssets_3 += (!isNaN(parseFloat(this.props.balanceSheet.period_3.inventories))) ? parseFloat(this.props.balanceSheet.period_3.inventories) : 0
		totalAssets_3 += (!isNaN(parseFloat(this.props.balanceSheet.period_3.machines))) ? parseFloat(this.props.balanceSheet.period_3.machines) : 0
		totalAssets_3 += (!isNaN(parseFloat(this.props.balanceSheet.period_3.productionFacilities))) ? parseFloat(this.props.balanceSheet.period_3.productionFacilities) : 0
		totalAssets_3 += (!isNaN(parseFloat(this.props.balanceSheet.period_3.capitalizedDevelopmentCosts))) ? parseFloat(this.props.balanceSheet.period_3.capitalizedDevelopmentCosts) : 0

		let totalAssets_4 = 0;
		totalAssets_4 += (!isNaN(parseFloat(this.props.balanceSheet.period_4.cash))) ? parseFloat(this.props.balanceSheet.period_4.cash) : 0
		totalAssets_4 += (!isNaN(parseFloat(this.props.balanceSheet.period_4.receivables))) ? parseFloat(this.props.balanceSheet.period_4.receivables) : 0
		totalAssets_4 += (!isNaN(parseFloat(this.props.balanceSheet.period_4.inventories))) ? parseFloat(this.props.balanceSheet.period_4.inventories) : 0
		totalAssets_4 += (!isNaN(parseFloat(this.props.balanceSheet.period_4.machines))) ? parseFloat(this.props.balanceSheet.period_4.machines) : 0
		totalAssets_4 += (!isNaN(parseFloat(this.props.balanceSheet.period_4.productionFacilities))) ? parseFloat(this.props.balanceSheet.period_4.productionFacilities) : 0
		totalAssets_4 += (!isNaN(parseFloat(this.props.balanceSheet.period_4.capitalizedDevelopmentCosts))) ? parseFloat(this.props.balanceSheet.period_4.capitalizedDevelopmentCosts) : 0

		let totalAssets_5 = 0;
		totalAssets_5 += (!isNaN(parseFloat(this.props.balanceSheet.period_5.cash))) ? parseFloat(this.props.balanceSheet.period_5.cash) : 0
		totalAssets_5 += (!isNaN(parseFloat(this.props.balanceSheet.period_5.receivables))) ? parseFloat(this.props.balanceSheet.period_5.receivables) : 0
		totalAssets_5 += (!isNaN(parseFloat(this.props.balanceSheet.period_5.inventories))) ? parseFloat(this.props.balanceSheet.period_5.inventories) : 0
		totalAssets_5 += (!isNaN(parseFloat(this.props.balanceSheet.period_5.machines))) ? parseFloat(this.props.balanceSheet.period_5.machines) : 0
		totalAssets_5 += (!isNaN(parseFloat(this.props.balanceSheet.period_5.productionFacilities))) ? parseFloat(this.props.balanceSheet.period_5.productionFacilities) : 0
		totalAssets_5 += (!isNaN(parseFloat(this.props.balanceSheet.period_5.capitalizedDevelopmentCosts))) ? parseFloat(this.props.balanceSheet.period_5.capitalizedDevelopmentCosts) : 0

		let totalAssets_6 = 0;
		totalAssets_6 += (!isNaN(parseFloat(this.props.balanceSheet.period_6.cash))) ? parseFloat(this.props.balanceSheet.period_6.cash) : 0
		totalAssets_6 += (!isNaN(parseFloat(this.props.balanceSheet.period_6.receivables))) ? parseFloat(this.props.balanceSheet.period_6.receivables) : 0
		totalAssets_6 += (!isNaN(parseFloat(this.props.balanceSheet.period_6.inventories))) ? parseFloat(this.props.balanceSheet.period_6.inventories) : 0
		totalAssets_6 += (!isNaN(parseFloat(this.props.balanceSheet.period_6.machines))) ? parseFloat(this.props.balanceSheet.period_6.machines) : 0
		totalAssets_6 += (!isNaN(parseFloat(this.props.balanceSheet.period_6.productionFacilities))) ? parseFloat(this.props.balanceSheet.period_6.productionFacilities) : 0
		totalAssets_6 += (!isNaN(parseFloat(this.props.balanceSheet.period_6.capitalizedDevelopmentCosts))) ? parseFloat(this.props.balanceSheet.period_6.capitalizedDevelopmentCosts) : 0

		let totalLiabilities_0 = 0;
		totalLiabilities_0 += (!isNaN(parseFloat(this.props.balanceSheet.period_0.payables))) ? parseFloat(this.props.balanceSheet.period_0.payables) : 0
		totalLiabilities_0 += (!isNaN(parseFloat(this.props.balanceSheet.period_0.liabilities))) ? parseFloat(this.props.balanceSheet.period_0.liabilities) : 0
		totalLiabilities_0 += (!isNaN(parseFloat(this.props.balanceSheet.period_0.equity))) ? parseFloat(this.props.balanceSheet.period_0.equity) : 0

		let totalLiabilities_1 = 0;
		totalLiabilities_1 += (!isNaN(parseFloat(this.props.balanceSheet.period_1.payables))) ? parseFloat(this.props.balanceSheet.period_1.payables) : 0
		totalLiabilities_1 += (!isNaN(parseFloat(this.props.balanceSheet.period_1.liabilities))) ? parseFloat(this.props.balanceSheet.period_1.liabilities) : 0
		totalLiabilities_1 += (!isNaN(parseFloat(this.props.balanceSheet.period_1.equity))) ? parseFloat(this.props.balanceSheet.period_1.equity) : 0

		let totalLiabilities_2 = 0;
		totalLiabilities_2 += (!isNaN(parseFloat(this.props.balanceSheet.period_2.payables))) ? parseFloat(this.props.balanceSheet.period_2.payables) : 0
		totalLiabilities_2 += (!isNaN(parseFloat(this.props.balanceSheet.period_2.liabilities))) ? parseFloat(this.props.balanceSheet.period_2.liabilities) : 0
		totalLiabilities_2 += (!isNaN(parseFloat(this.props.balanceSheet.period_2.equity))) ? parseFloat(this.props.balanceSheet.period_2.equity) : 0

		let totalLiabilities_3 = 0;
		totalLiabilities_3 += (!isNaN(parseFloat(this.props.balanceSheet.period_3.payables))) ? parseFloat(this.props.balanceSheet.period_3.payables) : 0
		totalLiabilities_3 += (!isNaN(parseFloat(this.props.balanceSheet.period_3.liabilities))) ? parseFloat(this.props.balanceSheet.period_3.liabilities) : 0
		totalLiabilities_3 += (!isNaN(parseFloat(this.props.balanceSheet.period_3.equity))) ? parseFloat(this.props.balanceSheet.period_3.equity) : 0

		let totalLiabilities_4 = 0;
		totalLiabilities_4 += (!isNaN(parseFloat(this.props.balanceSheet.period_4.payables))) ? parseFloat(this.props.balanceSheet.period_4.payables) : 0
		totalLiabilities_4 += (!isNaN(parseFloat(this.props.balanceSheet.period_4.liabilities))) ? parseFloat(this.props.balanceSheet.period_4.liabilities) : 0
		totalLiabilities_4 += (!isNaN(parseFloat(this.props.balanceSheet.period_4.equity))) ? parseFloat(this.props.balanceSheet.period_4.equity) : 0

		let totalLiabilities_5 = 0;
		totalLiabilities_5 += (!isNaN(parseFloat(this.props.balanceSheet.period_5.payables))) ? parseFloat(this.props.balanceSheet.period_5.payables) : 0
		totalLiabilities_5 += (!isNaN(parseFloat(this.props.balanceSheet.period_5.liabilities))) ? parseFloat(this.props.balanceSheet.period_5.liabilities) : 0
		totalLiabilities_5 += (!isNaN(parseFloat(this.props.balanceSheet.period_5.equity))) ? parseFloat(this.props.balanceSheet.period_5.equity) : 0

		let totalLiabilities_6 = 0;
		totalLiabilities_6 += (!isNaN(parseFloat(this.props.balanceSheet.period_6.payables))) ? parseFloat(this.props.balanceSheet.period_6.payables) : 0
		totalLiabilities_6 += (!isNaN(parseFloat(this.props.balanceSheet.period_6.liabilities))) ? parseFloat(this.props.balanceSheet.period_6.liabilities) : 0
		totalLiabilities_6 += (!isNaN(parseFloat(this.props.balanceSheet.period_6.equity))) ? parseFloat(this.props.balanceSheet.period_6.equity) : 0
		
		return (
			<Table bordered>
				<thead>
					<tr>
						<th></th>
						<th style={{ padding: 5 }}>
							<div style={{ display: "flex" }}>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3, textAlign: "center" }}>Period 0</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3, textAlign: "center" }}>Period 1</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3, textAlign: "center" }}>Period 2</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3, textAlign: "center" }}>Period 3</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3, textAlign: "center" }}>Period 4</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3, textAlign: "center" }}>Period 5</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3, textAlign: "center" }}>Period 6</div>
							</div>
						</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<th>
							<div className="bold"><Translate alias="Forms" word="Assets" /></div>
						</th>
					</tr>
					<tr>
						<td>
							<div className="bold"><Translate alias="Forms" word="Cash" /></div>
						</td>
						<td>
							<div style={{ display: "flex" }}>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ this.props.balanceSheet.period_0.cash }
										onChange={ (e) => this.handleChange("cash", e.target.value, "period_0" )} 
										disabled={ !this.props.status }
									/>
								</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ this.props.balanceSheet.period_1.cash }
										onChange={ (e) => this.handleChange("cash", e.target.value, "period_1" )} 
										disabled={ !this.props.status }
									/>
								</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ this.props.balanceSheet.period_2.cash }
										onChange={ (e) => this.handleChange("cash", e.target.value, "period_2" )} 
										disabled={ !this.props.status }
									/>
								</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ this.props.balanceSheet.period_3.cash }
										onChange={ (e) => this.handleChange("cash", e.target.value, "period_3" )} 
										disabled={ !this.props.status }
									/>
								</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ this.props.balanceSheet.period_4.cash }
										onChange={ (e) => this.handleChange("cash", e.target.value, "period_4" )} 
										disabled={ !this.props.status }
									/>
								</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ this.props.balanceSheet.period_5.cash }
										onChange={ (e) => this.handleChange("cash", e.target.value, "period_5" )} 
										disabled={ !this.props.status }
									/>
								</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ this.props.balanceSheet.period_6.cash }
										onChange={ (e) => this.handleChange("cash", e.target.value, "period_6" )} 
										disabled={ !this.props.status }
									/>
								</div>
							</div>
						</td>
					</tr>
					<tr>
						<td>
							<div className="bold"><Translate alias="Forms" word="Receivables" /></div>
						</td>
						<td>
							<div style={{ display: "flex" }}>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ this.props.balanceSheet.period_0.receivables }
										onChange={ (e) => this.handleChange("receivables", e.target.value, "period_0" )} 
										disabled={ !this.props.status }
									/>
								</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ this.props.balanceSheet.period_1.receivables }
										onChange={ (e) => this.handleChange("receivables", e.target.value, "period_1" )} 
										disabled={ !this.props.status }
									/>
								</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ this.props.balanceSheet.period_2.receivables }
										onChange={ (e) => this.handleChange("receivables", e.target.value, "period_2" )} 
										disabled={ !this.props.status }
									/>
								</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ this.props.balanceSheet.period_3.receivables }
										onChange={ (e) => this.handleChange("receivables", e.target.value, "period_3" )} 
										disabled={ !this.props.status }
									/>
								</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ this.props.balanceSheet.period_4.receivables }
										onChange={ (e) => this.handleChange("receivables", e.target.value, "period_4" )} 
										disabled={ !this.props.status }
									/>
								</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ this.props.balanceSheet.period_5.receivables }
										onChange={ (e) => this.handleChange("receivables", e.target.value, "period_5" )} 
										disabled={ !this.props.status }
									/>
								</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ this.props.balanceSheet.period_6.receivables }
										onChange={ (e) => this.handleChange("receivables", e.target.value, "period_6" )} 
										disabled={ !this.props.status }
									/>
								</div>
							</div>
						</td>
					</tr>
					<tr>
						<td>
							<div className="bold"><Translate alias="Forms" word="Inventories" /></div>
						</td>
						<td>
							<div style={{ display: "flex" }}>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ this.props.balanceSheet.period_0.inventories }
										onChange={ (e) => this.handleChange("inventories", e.target.value, "period_0" )} 
										disabled={ !this.props.status }
									/>
								</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ this.props.balanceSheet.period_1.inventories }
										onChange={ (e) => this.handleChange("inventories", e.target.value, "period_1" )} 
										disabled={ !this.props.status }
									/>
								</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ this.props.balanceSheet.period_2.inventories }
										onChange={ (e) => this.handleChange("inventories", e.target.value, "period_2" )} 
										disabled={ !this.props.status }
									/>
								</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ this.props.balanceSheet.period_3.inventories }
										onChange={ (e) => this.handleChange("inventories", e.target.value, "period_3" )} 
										disabled={ !this.props.status }
									/>
								</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ this.props.balanceSheet.period_4.inventories }
										onChange={ (e) => this.handleChange("inventories", e.target.value, "period_4" )} 
										disabled={ !this.props.status }
									/>
								</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ this.props.balanceSheet.period_5.inventories }
										onChange={ (e) => this.handleChange("inventories", e.target.value, "period_5" )} 
										disabled={ !this.props.status }
									/>
								</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ this.props.balanceSheet.period_6.inventories }
										onChange={ (e) => this.handleChange("inventories", e.target.value, "period_6" )} 
										disabled={ !this.props.status }
									/>
								</div>
							</div>
						</td>
					</tr>
					<tr>
						<td>
							<div className="bold"><Translate alias="Forms" word="Machines" /></div>
						</td>
						<td>
							<div style={{ display: "flex" }}>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ this.props.balanceSheet.period_0.machines }
										onChange={ (e) => this.handleChange("machines", e.target.value, "period_0" )} 
										disabled={ !this.props.status }
									/>
								</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ this.props.balanceSheet.period_1.machines }
										onChange={ (e) => this.handleChange("machines", e.target.value, "period_1" )} 
										disabled={ !this.props.status }
									/>
								</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ this.props.balanceSheet.period_2.machines }
										onChange={ (e) => this.handleChange("machines", e.target.value, "period_2" )} 
										disabled={ !this.props.status }
									/>
								</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ this.props.balanceSheet.period_3.machines }
										onChange={ (e) => this.handleChange("machines", e.target.value, "period_3" )} 
										disabled={ !this.props.status }
									/>
								</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ this.props.balanceSheet.period_4.machines }
										onChange={ (e) => this.handleChange("machines", e.target.value, "period_4" )} 
										disabled={ !this.props.status }
									/>
								</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ this.props.balanceSheet.period_5.machines }
										onChange={ (e) => this.handleChange("machines", e.target.value, "period_5" )} 
										disabled={ !this.props.status }
									/>
								</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ this.props.balanceSheet.period_6.machines }
										onChange={ (e) => this.handleChange("machines", e.target.value, "period_6" )} 
										disabled={ !this.props.status }
									/>
								</div>
							</div>
						</td>
					</tr>
					<tr>
						<td>
							<div className="bold"><Translate alias="Forms" word="Production Facilities" /></div>
						</td>
						<td>
							<div style={{ display: "flex" }}>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ this.props.balanceSheet.period_0.productionFacilities }
										onChange={ (e) => this.handleChange("productionFacilities", e.target.value, "period_0" )} 
										disabled={ !this.props.status }
									/>
								</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ this.props.balanceSheet.period_1.productionFacilities }
										onChange={ (e) => this.handleChange("productionFacilities", e.target.value, "period_1" )} 
										disabled={ !this.props.status }
									/>
								</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ this.props.balanceSheet.period_2.productionFacilities }
										onChange={ (e) => this.handleChange("productionFacilities", e.target.value, "period_2" )} 
										disabled={ !this.props.status }
									/>
								</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ this.props.balanceSheet.period_3.productionFacilities }
										onChange={ (e) => this.handleChange("productionFacilities", e.target.value, "period_3" )} 
										disabled={ !this.props.status }
									/>
								</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ this.props.balanceSheet.period_4.productionFacilities }
										onChange={ (e) => this.handleChange("productionFacilities", e.target.value, "period_4" )} 
										disabled={ !this.props.status }
									/>
								</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ this.props.balanceSheet.period_5.productionFacilities }
										onChange={ (e) => this.handleChange("productionFacilities", e.target.value, "period_5" )} 
										disabled={ !this.props.status }
									/>
								</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ this.props.balanceSheet.period_6.productionFacilities }
										onChange={ (e) => this.handleChange("productionFacilities", e.target.value, "period_6" )} 
										disabled={ !this.props.status }
									/>
								</div>
							</div>
						</td>
					</tr>
					<tr>
						<td>
							<div className="bold"><Translate alias="Forms" word="Capitalized Development Costs" /></div>
						</td>
						<td>
							<div style={{ display: "flex" }}>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ this.props.balanceSheet.period_0.capitalizedDevelopmentCosts }
										onChange={ (e) => this.handleChange("capitalizedDevelopmentCosts", e.target.value, "period_0" )} 
										disabled={ !this.props.status }
									/>
								</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ this.props.balanceSheet.period_1.capitalizedDevelopmentCosts }
										onChange={ (e) => this.handleChange("capitalizedDevelopmentCosts", e.target.value, "period_1" )} 
										disabled={ !this.props.status }
									/>
								</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ this.props.balanceSheet.period_2.capitalizedDevelopmentCosts }
										onChange={ (e) => this.handleChange("capitalizedDevelopmentCosts", e.target.value, "period_2" )} 
										disabled={ !this.props.status }
									/>
								</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ this.props.balanceSheet.period_3.capitalizedDevelopmentCosts }
										onChange={ (e) => this.handleChange("capitalizedDevelopmentCosts", e.target.value, "period_3" )} 
										disabled={ !this.props.status }
									/>
								</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ this.props.balanceSheet.period_4.capitalizedDevelopmentCosts }
										onChange={ (e) => this.handleChange("capitalizedDevelopmentCosts", e.target.value, "period_4" )} 
										disabled={ !this.props.status }
									/>
								</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ this.props.balanceSheet.period_5.capitalizedDevelopmentCosts }
										onChange={ (e) => this.handleChange("capitalizedDevelopmentCosts", e.target.value, "period_5" )} 
										disabled={ !this.props.status }
									/>
								</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ this.props.balanceSheet.period_6.capitalizedDevelopmentCosts }
										onChange={ (e) => this.handleChange("capitalizedDevelopmentCosts", e.target.value, "period_6" )} 
										disabled={ !this.props.status }
									/>
								</div>
							</div>
						</td>
					</tr>
					<tr className="autofillFieldBackground">
						<td>
							<div className="bold"><Translate alias="Forms" word="Total" /></div>
						</td>
						<td>
							<div style={{ display: "flex" }}>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ totalAssets_0 }
										disabled
									/>
								</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ totalAssets_1 }
										disabled
									/>
								</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ totalAssets_2 }
										disabled
									/>
								</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ totalAssets_3 }
										disabled
									/>
								</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ totalAssets_4 }
										disabled
									/>
								</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ totalAssets_5 }
										disabled
									/>
								</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ totalAssets_6 }
										disabled
									/>
								</div>
							</div>
						</td>
					</tr>
					<tr>
						<td></td>
						<td></td>
					</tr>
				</tbody>
				<thead>
					<tr>
						<th colSpan="2"><div className="bold"><Translate alias="Forms" word="Equity & Liabilities" /></div></th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>
							<div className="bold"><Translate alias="Forms" word="Payables" /></div>
						</td>
						<td>
							<div style={{ display: "flex" }}>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ this.props.balanceSheet.period_0.payables }
										onChange={ (e) => this.handleChange("payables", e.target.value, "period_0" )} 
										disabled={ !this.props.status }
									/>
								</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ this.props.balanceSheet.period_1.payables }
										onChange={ (e) => this.handleChange("payables", e.target.value, "period_1" )} 
										disabled={ !this.props.status }
									/>
								</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ this.props.balanceSheet.period_2.payables }
										onChange={ (e) => this.handleChange("payables", e.target.value, "period_2" )} 
										disabled={ !this.props.status }
									/>
								</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ this.props.balanceSheet.period_3.payables }
										onChange={ (e) => this.handleChange("payables", e.target.value, "period_3" )} 
										disabled={ !this.props.status }
									/>
								</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ this.props.balanceSheet.period_4.payables }
										onChange={ (e) => this.handleChange("payables", e.target.value, "period_4" )} 
										disabled={ !this.props.status }
									/>
								</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ this.props.balanceSheet.period_5.payables }
										onChange={ (e) => this.handleChange("payables", e.target.value, "period_5" )} 
										disabled={ !this.props.status }
									/>
								</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ this.props.balanceSheet.period_6.payables }
										onChange={ (e) => this.handleChange("payables", e.target.value, "period_6" )} 
										disabled={ !this.props.status }
									/>
								</div>
							</div>
						</td>
					</tr>
					<tr>
						<td>
							<div className="bold"><Translate alias="Forms" word="Liabilities" /></div>
						</td>
						<td>
							<div style={{ display: "flex" }}>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ this.props.balanceSheet.period_0.liabilities }
										onChange={ (e) => this.handleChange("liabilities", e.target.value, "period_0" )} 
										disabled={ !this.props.status }
									/>
								</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ this.props.balanceSheet.period_1.liabilities }
										onChange={ (e) => this.handleChange("liabilities", e.target.value, "period_1" )} 
										disabled={ !this.props.status }
									/>
								</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ this.props.balanceSheet.period_2.liabilities }
										onChange={ (e) => this.handleChange("liabilities", e.target.value, "period_2" )} 
										disabled={ !this.props.status }
									/>
								</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ this.props.balanceSheet.period_3.liabilities }
										onChange={ (e) => this.handleChange("liabilities", e.target.value, "period_3" )} 
										disabled={ !this.props.status }
									/>
								</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ this.props.balanceSheet.period_4.liabilities }
										onChange={ (e) => this.handleChange("liabilities", e.target.value, "period_4" )} 
										disabled={ !this.props.status }
									/>
								</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ this.props.balanceSheet.period_5.liabilities }
										onChange={ (e) => this.handleChange("liabilities", e.target.value, "period_5" )} 
										disabled={ !this.props.status }
									/>
								</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ this.props.balanceSheet.period_6.liabilities }
										onChange={ (e) => this.handleChange("liabilities", e.target.value, "period_6" )} 
										disabled={ !this.props.status }
									/>
								</div>
							</div>
						</td>
					</tr>
					<tr>
						<td>
							<div className="bold"><Translate alias="Forms" word="Equity" /></div>
						</td>
						<td>
							<div style={{ display: "flex" }}>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ this.props.balanceSheet.period_0.equity }
										onChange={ (e) => this.handleChange("equity", e.target.value, "period_0" )} 
										disabled={ !this.props.status }
									/>
								</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ this.props.balanceSheet.period_1.equity }
										onChange={ (e) => this.handleChange("equity", e.target.value, "period_1" )} 
										disabled={ !this.props.status }
									/>
								</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ this.props.balanceSheet.period_2.equity }
										onChange={ (e) => this.handleChange("equity", e.target.value, "period_2" )} 
										disabled={ !this.props.status }
									/>
								</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ this.props.balanceSheet.period_3.equity }
										onChange={ (e) => this.handleChange("equity", e.target.value, "period_3" )} 
										disabled={ !this.props.status }
									/>
								</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ this.props.balanceSheet.period_4.equity }
										onChange={ (e) => this.handleChange("equity", e.target.value, "period_4" )} 
										disabled={ !this.props.status }
									/>
								</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ this.props.balanceSheet.period_5.equity }
										onChange={ (e) => this.handleChange("equity", e.target.value, "period_5" )} 
										disabled={ !this.props.status }
									/>
								</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ this.props.balanceSheet.period_6.equity }
										onChange={ (e) => this.handleChange("equity", e.target.value, "period_6" )} 
										disabled={ !this.props.status }
									/>
								</div>
							</div>
						</td>
					</tr>
					<tr className="autofillFieldBackground">
						<td>
							<div className="bold"><Translate alias="Forms" word="Total" /></div>
						</td>
						<td>
							<div style={{ display: "flex" }}>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ totalLiabilities_0 }
										disabled
									/>
								</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ totalLiabilities_1 }
										disabled
									/>
								</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ totalLiabilities_2 }
										disabled
									/>
								</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ totalLiabilities_3 }
										disabled
									/>
								</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ totalLiabilities_4 }
										disabled
									/>
								</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ totalLiabilities_5 }
										disabled
									/>
								</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ totalLiabilities_6 }
										disabled
									/>
								</div>
							</div>
						</td>
					</tr>
					<tr>
						<td>
							<div className="bold"><Translate alias="Forms" word="Employees" /></div>
						</td>
						<td>
							<div style={{ display: "flex" }}>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ this.props.balanceSheet.period_0.employees }
										onChange={ (e) => this.handleChange("employees", e.target.value, "period_0" )} 
										disabled={ !this.props.status }
									/>
								</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ this.props.balanceSheet.period_1.employees }
										onChange={ (e) => this.handleChange("employees", e.target.value, "period_1" )} 
										disabled={ !this.props.status }
									/>
								</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ this.props.balanceSheet.period_2.employees }
										onChange={ (e) => this.handleChange("employees", e.target.value, "period_2" )} 
										disabled={ !this.props.status }
									/>
								</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ this.props.balanceSheet.period_3.employees }
										onChange={ (e) => this.handleChange("employees", e.target.value, "period_3" )} 
										disabled={ !this.props.status }
									/>
								</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ this.props.balanceSheet.period_4.employees }
										onChange={ (e) => this.handleChange("employees", e.target.value, "period_4" )} 
										disabled={ !this.props.status }
									/>
								</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ this.props.balanceSheet.period_5.employees }
										onChange={ (e) => this.handleChange("employees", e.target.value, "period_5" )} 
										disabled={ !this.props.status }
									/>
								</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ this.props.balanceSheet.period_6.employees }
										onChange={ (e) => this.handleChange("employees", e.target.value, "period_6" )} 
										disabled={ !this.props.status }
									/>
								</div>
							</div>
						</td>
					</tr>
					<tr>
						<td>
							<div className="bold"><Translate alias="Forms" word="Share holder" /></div>
						</td>
						<td>
							<div style={{ display: "flex" }}>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ this.props.balanceSheet.period_0.shareHolder }
										onChange={ (e) => this.handleChange("shareHolder", e.target.value, "period_0" )} 
										disabled={ !this.props.status }
									/>
								</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ this.props.balanceSheet.period_1.shareHolder }
										onChange={ (e) => this.handleChange("shareHolder", e.target.value, "period_1" )} 
										disabled={ !this.props.status }
									/>
								</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ this.props.balanceSheet.period_2.shareHolder }
										onChange={ (e) => this.handleChange("shareHolder", e.target.value, "period_2" )} 
										disabled={ !this.props.status }
									/>
								</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ this.props.balanceSheet.period_3.shareHolder }
										onChange={ (e) => this.handleChange("shareHolder", e.target.value, "period_3" )} 
										disabled={ !this.props.status }
									/>
								</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ this.props.balanceSheet.period_4.shareHolder }
										onChange={ (e) => this.handleChange("shareHolder", e.target.value, "period_4" )} 
										disabled={ !this.props.status }
									/>
								</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ this.props.balanceSheet.period_5.shareHolder }
										onChange={ (e) => this.handleChange("shareHolder", e.target.value, "period_5" )} 
										disabled={ !this.props.status }
									/>
								</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ this.props.balanceSheet.period_6.shareHolder }
										onChange={ (e) => this.handleChange("shareHolder", e.target.value, "period_6" )} 
										disabled={ !this.props.status }
									/>
								</div>
							</div>
						</td>
					</tr>
					<tr>
						<td>
							<div className="bold"><Translate alias="Forms" word="Customer" /></div>
						</td>
						<td>
							<div style={{ display: "flex" }}>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ this.props.balanceSheet.period_0.customer }
										onChange={ (e) => this.handleChange("customer", e.target.value, "period_0" )} 
										disabled={ !this.props.status }
									/>
								</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ this.props.balanceSheet.period_1.customer }
										onChange={ (e) => this.handleChange("customer", e.target.value, "period_1" )} 
										disabled={ !this.props.status }
									/>
								</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ this.props.balanceSheet.period_2.customer }
										onChange={ (e) => this.handleChange("customer", e.target.value, "period_2" )} 
										disabled={ !this.props.status }
									/>
								</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ this.props.balanceSheet.period_3.customer }
										onChange={ (e) => this.handleChange("customer", e.target.value, "period_3" )} 
										disabled={ !this.props.status }
									/>
								</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ this.props.balanceSheet.period_4.customer }
										onChange={ (e) => this.handleChange("customer", e.target.value, "period_4" )} 
										disabled={ !this.props.status }
									/>
								</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ this.props.balanceSheet.period_5.customer }
										onChange={ (e) => this.handleChange("customer", e.target.value, "period_5" )} 
										disabled={ !this.props.status }
									/>
								</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ this.props.balanceSheet.period_6.customer }
										onChange={ (e) => this.handleChange("customer", e.target.value, "period_6" )} 
										disabled={ !this.props.status }
									/>
								</div>
							</div>
						</td>
					</tr>
				</tbody>
			</Table>
		);
	}

}

export default BalanceSheet;