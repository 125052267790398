const startingKit = {
	price: {
		RegionA: {
			economy: "",
			elite: ""
		},
		RegionB: {
			economy: "",
			elite: ""
		}
	},
	qualityExpenses: {
		RegionA: {
			economy: "",
			elite: ""
		},
		RegionB: {
			economy: "",
			elite: ""
		}
	},
	salesExpenses: {
		RegionA: {
			economy: "",
			elite: ""
		},
		RegionB: {
			economy: "",
			elite: ""
		}
	},
	invoiceRatio: {
		RegionA: {
			economy: "",
			elite: ""
		},
		RegionB: {
			economy: "",
			elite: ""
		}
	},
	unitsOffered: {
		RegionA: {
			economy: "",
			elite: ""
		},
		RegionB: {
			economy: "",
			elite: ""
		}
	}
}

export default startingKit;