import React, { useEffect, useState} from "react";

// Dispatcher
import * as RealtimeConnection from "../../RealtimeConnection";

// Components
import TokenDroppableField from "./TokenDroppableField";

const TokenDroppableContainer = (props) => {

	const [canEdit, setCanEdit] = useState(false)

	useEffect(() => {
		setCanEdit(props.canEdit)
	}, [props.canEdit])

	const appendToken = (value, type) => {
		// Update Socket
		RealtimeConnection.addToken({
			type: props.fieldType,
			value: value,
			tokenType: type
		})
	}

	const removeToken = (tokenValue) => {
		// Update Socket
		RealtimeConnection.removeToken({
			type: props.fieldType,
			value: tokenValue
		})
	}

	return (
		<TokenDroppableField
			canEdit={canEdit}
			fieldType={ props.fieldType }
			type={props.type}
			value={props.value}
			appendToken={appendToken}
			removeToken={removeToken}
		/>
	);
}

export default TokenDroppableContainer;