import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

// Components
import DroppableField from "../../Common/DroppableField";

// Helpers
import * as RealtimeConnection from "../../RealtimeConnection";
import dropFieldClassNames from "../../../utils/dropFieldClassNames";
import items from "../../../utils/items";
import Translate from "../../../utils/Translate";

const ProductionBlockSection = ({ id, title }) => {
	const dispatch = useDispatch()

	const productionState = useSelector(state => state.board.CenterBlock.Production)

	const [canEdit, setCanEdit] = useState(true)

	const appendContainer = (fieldType, coins, id, hasCard, cardValue, cardColor) => {
		// Update Socket
		RealtimeConnection.addContainer("Production", {
			fieldType: fieldType,
			id: id,
			coins: coins,
			hasCard: hasCard,
			cardValue: cardValue,
			cardColor: cardColor
		})
	}

	const removeContainer = (fieldType, id, isDropToDelete) => {
		// Update Socket
		RealtimeConnection.removeContainer("Production", {
			fieldType: fieldType,
			id: id,
			isDropToDelete: isDropToDelete
		})
	}

	const addCoin = (fieldType, coinValue, id) => {
		// Update Socket
		RealtimeConnection.addCoin("Production", {
			fieldType: fieldType,
			id: id,
			value: coinValue
		})
	}

	const removeCoin = (fieldType, index, id, isDropToDelete) => {
		// Update Socket
		RealtimeConnection.removeCoin("Production", {
			fieldType: fieldType,
			id: id,
			index: index,
			isDropToDelete: isDropToDelete
		})
	}

	const addCard = (optionType, id, value, color) => {
		// Update Socket
		RealtimeConnection.addCard("Production", {
			optionType: optionType,
			id: id,
			value: value,
			color: color
		})
	}

	const isDraggingHandler = (type, isDragging, data) => {
		let dataObj = {
			teamId: window.teamId,
			sessionId: window.sessionId,
			playerId: window.playerId,
			playerName: window.playerName,
			alias: "Production",
			isDragging: isDragging,
			type: type
		}
		dataObj = { ...dataObj, ...data }
		window.socket.emit('item-dragging', dataObj);
	}

	const showContainerProperties = (optionType) => {
		dispatch({ type: "PROPERTIES", alias: 'FIELD', options: { 
			fieldName: 'Production', 
			optionType: optionType, 
			id: id 
		}})
		//props.openContainerPropertiesWithOption('FIELD', 'Production', optionType, rowId);
	}

	return (
		<Container className="Production-block-container">
			<Row className="custom-height">
				<Col xs={12}>
					<div className="Block-title-bold-text Production-title-text-size">
						<Translate alias="Board" word={ title } />
					</div>
				</Col>
			</Row>

			<Row style={{ margin: "10px" }}>
				<div style={{ width: "32%" }}>
					<Container>
						<Row>
							<div className="vertical-text-container">
								<div className="vertical-text first text-bold">
									<Translate alias="Board" word="purchased production plant" />
								</div>
							</div>
							<div className="production-field-container" onClick={ () => showContainerProperties("factory")}>
								<DroppableField
									canEdit={canEdit}
									class={ dropFieldClassNames.Production }
									id={ id }
									data={ productionState[id].factory }
									containerType={items.factoryContainer}
									productionType={ "factory" }
									isHorizontal={ false }
									appendContainer={ appendContainer }
									removeContainer={ removeContainer }
									addCoin={ addCoin }
									removeCoin={ removeCoin }
									addCard={ addCard }
									isDraggingHandler={ isDraggingHandler }
								/>
							</div>
						</Row>
					</Container>
				</div>
				<div style={{ width: "27.5%" }}>
					<Container>
						<Row>
							<div className="vertical-text-container2">
								<div className="vertical-text second text-bold">
									<Translate alias="Board" word="Machine" />
								</div>
							</div>
							<div className="production-field-container2" onClick={ () => showContainerProperties("machine")}>
								<DroppableField
									canEdit={canEdit}
									class={ dropFieldClassNames.Production }
									id={ id }
									data={ productionState[id].machine }
									containerType={items.machineContainer}
									productionType={ "machine" }
									isHorizontal={ false }
									appendContainer={ appendContainer }
									removeContainer={ removeContainer }
									addCoin={ addCoin }
									removeCoin={ removeCoin }
									addCard={ addCard }
									isDraggingHandler={ isDraggingHandler }
								/>
							</div>
						</Row>
					</Container>
				</div>
				<div style={{ width: "27.5%" }}>
					<Container>
						<Row>
							<div className="vertical-text-container2">
								<div className="vertical-text second text-bold">
									<Translate alias="Board" word="Products" />
								</div>
							</div>
							<div className="production-field-container2" onClick={ () => showContainerProperties("product")}>
								<DroppableField
									canEdit={canEdit}
									class={ dropFieldClassNames.Production }
									id={ id }
									data={ productionState[id].product }
									containerType={items.productionContainer}
									productionType={ "product" }
									isHorizontal={ false }
									appendContainer={ appendContainer }
									removeContainer={ removeContainer }
									addCoin={ addCoin }
									removeCoin={ removeCoin }
									addCard={ addCard }
									isDraggingHandler={ isDraggingHandler }
								/>
							</div>
						</Row>
					</Container>
				</div>
			</Row>
		</Container>
	);
}

export default ProductionBlockSection