import React, { useEffect, useState } from "react";
import {connect} from "react-redux";
import {Row, Col, Container, Button} from "react-bootstrap";

import Translate from "../utils/Translate"

// Dispatchers
import mapDispatchToProps from "../Redux/dispatchers";
import * as RealtimeConnection from "./RealtimeConnection";

const TokenProperties = (props) => {

	const [canEdit, setCanEdit] = useState(false)
	const [showAddTokenQuestion, setShowAddTokenQuestion] = useState(false)
	const [showSubtractTokenQuestion, setShowSubtractTokenQuestion] = useState(false)
	const [addTokenValue, setAddtokenValue] = useState(0)
	const [subtractTokenValue, setSubtractTokenValue] = useState(0)

	useEffect(() => {
		setCanEdit(props.canEdit)
	}, [props.canEdit])

	useEffect(() => {
		if (showAddTokenQuestion) {
			setShowSubtractTokenQuestion(false)
		}
	}, [showAddTokenQuestion])

	useEffect(() => {
		if (showSubtractTokenQuestion) {
			setShowAddTokenQuestion(false)
		}
	}, [showSubtractTokenQuestion])

	const addTokenAction = (totalTokens) => {
		if (totalTokens >= addTokenValue) {
			let tokenValue = addTokenValue
			tokenValue = Math.ceil(tokenValue * 2) / 2;

			let droppedTokenType = props.board.CenterBlock.Finance[props.properties.options.tokenType].type;
			if (droppedTokenType === "") {
				if (props.properties.options.tokenType === "token_black") {
					droppedTokenType = "BlackToken"
				} else {
					droppedTokenType = "Token"
				}
			}

			//props.addToken(props.properties.options.tokenType, tokenValue, droppedTokenType)

			// Update Socket
			RealtimeConnection.addToken({
				type: props.properties.options.tokenType,
				value: tokenValue,
				tokenType: droppedTokenType
			})
			if (droppedTokenType === "Token") {
				RealtimeConnection.decreaseStarterKit({
					type: "tokens",
					value: tokenValue
				})
			}else if (droppedTokenType === "BlackToken") {
				RealtimeConnection.decreaseStarterKit({
					type: "blackTokens",
					value: tokenValue
				})
			}

			props.closePanel();
		}
	}

	const subtractTokenAction = (tokenValue) => {
		if (tokenValue >= subtractTokenValue) {
			let tokenValue = subtractTokenValue
			tokenValue = Math.ceil(tokenValue * 2) / 2;

			const droppedTokenType = props.board.CenterBlock.Finance[props.properties.options.tokenType].type;

			//props.removeToken(props.properties.options.tokenType, tokenValue)
			
			// Update Socket
			RealtimeConnection.removeToken({
				type: props.properties.options.tokenType,
				value: tokenValue,
				tokenType: droppedTokenType
			})
			if (droppedTokenType === "Token") {
				RealtimeConnection.increaseStarterKit({
					type: "tokens",
					value: tokenValue
				})
			}else if (droppedTokenType === "BlackToken") {
				RealtimeConnection.increaseStarterKit({
					type: "blackTokens",
					value: tokenValue
				})
			}

			props.closePanel();
		}
	}

	const properties = props.properties;
	const boardData = props.board;

	const tokenType = boardData.CenterBlock.Finance[properties.options.tokenType].type;
	const tokenValue = boardData.CenterBlock.Finance[properties.options.tokenType].value;

	let totalTokens = 0;
	if (tokenType === "BlackToken") {
		totalTokens = props.startingKit.blackTokens;
	}else{
		totalTokens = props.startingKit.tokens;
	}
	
	return (
		<>
			<div className={ `Token-droppable-field ${ (tokenType === "BlackToken") ? "token_black" : "" }` }>
				{
					(tokenType === "Token" && 
						<div className="Token-container">
							<div className="Token-value">{tokenValue}</div>
							<div className="Token-text">
								<Translate alias="Board" word="liability" />
							</div>
						</div>
					)
				}
				{
					(tokenType === "BlackToken" &&
						<div className="Token-container black">
							<div className="Token-value">{tokenValue}</div>
							<div className="Token-text">
								<Translate alias="Board" word="venture capital" />
							</div>
						</div>
					)
				}
			</div>
			{
				(canEdit) ?
					<div className="container-actions">
						<Container style={{ marginTop: "5px" }}>
							<Row>
								<Col xs={1}></Col>
								<Col>
									<Button
										className="purchase-btn"
										onClick={ () => setShowAddTokenQuestion(true) }
										size="sm"
									>
										<Translate alias="Board" word="Add" />
									</Button>
								</Col>
								<Col xs={2}></Col>
								<Col>
									<Button
										className="rent-btn"
										onClick={ () => setShowSubtractTokenQuestion(true) }
										size="sm"
									>
										<Translate alias="Board" word="Subtract" />
									</Button>
								</Col>
								<Col xs={1}></Col>
							</Row>
							<Row>
								<Col className="questions-container">
									{
										(showAddTokenQuestion) ?
											<>
												<div className="question-text">
													<Translate alias="Board" word="How much cash do you want to add?" />
												</div>
												<div className="question-input">
													<input
														type="text"
														autoFocus
														id="units"
														pattern="^-?[0-9]\d*\.?\d*$"
														name="units"
														onKeyPress={
															(event) => {
																if (event.key === "Enter") {
																	addTokenAction(totalTokens)
																}
															}
														}
														onChange={e => setAddtokenValue(parseInt(e.target.value)) }
													/>
												</div>
												<div className="question-btn">
													<Button size="sm" onClick={ () => addTokenAction(totalTokens) }>
														<Translate alias="Board" word="Confirm" />
													</Button>
												</div>
											</>
											:
											null
									}
									{
										(showSubtractTokenQuestion) ?
											<>
												<div className="question-text">
													<Translate alias="Board" word="How much cash do you want to subtract?" />
												</div>
												<div className="question-input">
													<input
														type="text"
														autoFocus
														id="units2"
														pattern="^-?[0-9]\d*\.?\d*$"
														name="units2"
														onKeyPress={
															(event) => {
																if (event.key === "Enter") {
																	subtractTokenAction(tokenValue)
																}
															}
														}
														onChange={e => setSubtractTokenValue(parseInt(e.target.value)) }
													/>
												</div>
												<div className="question-btn">
													<Button size="sm" onClick={ () => subtractTokenAction(tokenValue) }>
														<Translate alias="Board" word="Confirm" />
													</Button>
												</div>
											</>
											:
											null
									}
								</Col>
							</Row>
						</Container>
					</div>
					:
					null
			}
		</>
	)
}

export default connect(null, mapDispatchToProps)(TokenProperties);