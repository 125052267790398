import React from "react";
import { Row, Col } from "react-bootstrap";
import Translate from "../../../utils/Translate";

// Components
import ResearchRow from "./ResearchRow";

export default function ResearchBlock({ canEdit }) {

	return (
		<div className="Research-block-container">
			<Row>
				<Col>
					<p className="Research-bold-text Research-title-text-size">
						<Translate alias="Board" word="R & D" />
					</p>
					<div className="Research-subtitle-text-size">
						<Translate alias="Board" word="capitalized development costs" />
					</div>
				</Col>
			</Row>
			<ResearchRow
				className="Standard-row"
				rowKey="economy"
				title={"ECONOMY"}
				canEdit={ canEdit }
			/>

			<ResearchRow
				className="Luxus-row"
				rowKey="elite"
				title={"ELITE"}
				canEdit={ canEdit }
			/>
		</div>
	);
}
