import React from 'react';
import {connect} from "react-redux";
import { Container, Row, Col, Tabs, Tab, Dropdown, DropdownButton, ButtonGroup } from 'react-bootstrap';

import Translate from '../../utils/Translate'

import "../../assets/css/Forms.css";

// Dispatcher
import mapDispatchToProps from "../../Redux/dispatchers";
import * as RealtimeConnection from "../RealtimeConnection";

// Components
import BalanceSheet from "./Partials/BalanceSheet";
import ProfitLoss from "./Partials/ProfitLoss";

class AnnualReport extends React.Component {

	closeForm = () => {
		this.props.openFormView("")
	}

	inputChanged = (section, field, value, period) => {
		this.props.updateFormOption("AnnualReport", section, field, period, value)

		// Update Socket
		RealtimeConnection.updateForm({
			year: this.props.session.year,
			type: "AnnualReport",
			section: section,
			field: field,
			option: period,
			value: value
		})
	}

	changeFormYear = (selectedYear) => {
		this.props.changeFormYear(selectedYear);

		RealtimeConnection.formChangeYear({
			year: selectedYear,
			type: "AnnualReport"
		})
	}

	render() {
		const selectedLang = this.props.session.selectedLang;

		let yearsOptions = [];
		for(let i = 0; i <= this.props.session.year; i++) {
			yearsOptions.push(<Dropdown.Item key={i} eventKey={i} onClick={ this.changeFormYear.bind(this, i) }>Year { i }</Dropdown.Item>)
		}

		let isCurrentYear = false;
		if (this.props.session.year === this.props.session.formSelectedYear) {
			isCurrentYear = true
		}

		if (!this.props.canEdit) {
			isCurrentYear = false
		}

		return (
			<Container className="formContainer">
				<Row>
					<Col>
						<div className="formHeader">
							<div className="headerTitle bold">
								<Translate alias="Forms" word="Annual Report" />
								<DropdownButton
									as={ButtonGroup}
									variant="outline-secondary"
									title={ `Year ${this.props.session.formSelectedYear}` }
									size="sm"
									style={{ marginLeft: "10px" }}
								>
									{ yearsOptions }
								</DropdownButton>
							</div>
							<div className="closeForm" onClick={ this.closeForm.bind(this) }>X</div>
						</div>
					</Col>
				</Row>
				<Row>
					<Col>
						<Tabs defaultActiveKey="incomeStatement" id="uncontrolled-tab-example">
							<Tab eventKey="balanceSheet" title={ <Translate alias="Forms" word="Balance Sheet" /> }>
								<BalanceSheet
									inputChanged={ this.inputChanged }
									balanceSheet={ this.props.annualReport.balanceSheet }
									selectedLang={ selectedLang }
									status={ this.props.session.formStatus.annualReport && isCurrentYear }
								/>
							</Tab>
							<Tab eventKey="cashFlow" title={ <Translate alias="Forms" word="P & L" /> }>
								<ProfitLoss
									inputChanged={ this.inputChanged }
									profitLoss={ this.props.annualReport.profitLoss }
									selectedLang={ selectedLang }
									status={ this.props.session.formStatus.annualReport && isCurrentYear }
								/>
							</Tab>
						</Tabs>
					</Col>
				</Row>
			</Container>
		);
	}

}

const mapStateToProps = (state) => {
	return {
		session: state.session,
		annualReport: state.form.AnnualReport
	}
}
export default connect(mapStateToProps, mapDispatchToProps)(AnnualReport);