import React, { Component, useState, useEffect } from "react";
import { Col, Container, Image, Row, DropdownButton, Dropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import moment from 'moment';
import Popup from "reactjs-popup";

// Images
import logo from "../assets/images/header-logo.png";
import zoomIn from "../assets/images/zoom-in.png";
import zoomOut from "../assets/images/zoom-out.png";
import chatIcon from "../assets/images/chat-icon.png";
import logoutIcon from "../assets/images/logout-icon.png";

// Components
import Properties from "./Properties";

// Utils
import Translate from "../utils/Translate";
import urls from "../utils/urls";
import * as RealtimeConnection from "./RealtimeConnection"

let counterIntervalTimer = undefined

export default function Header(props) {
	const dispatch = useDispatch()
	const sessionState = useSelector( state => state.session )
	const propertiesState = useSelector( state => state.properties )
	const boardState = useSelector( state => state.board )
	const startingKitState = useSelector(state => state.startingKit)

	const [zoomLevel, setZoomLevel] = useState(0);
	const [canEdit, setCanEdit] = useState(false)
	const [teamName, setTeamName] = useState("Team-" + window.teamId)
	const [editTeamName, setEditTeamName] = useState(false)

	const [selectedLanguageTitle, setSelectedLanguageTitle] = useState("English");
  	const [languages, setLanguages] = useState([]);

	const [counter, setCounter] = useState(undefined)
	const [counterData, setCounterData] = useState({
		name: "",
		counterMinutes: 0,
		counterSeconds: 0
	})

	const [showPopup, setShowPopup] = useState(false)

	useEffect(() => {
		setCanEdit(props.canEdit)
	}, [props.canEdit])

	useEffect(() => {
		if (languages.length === 0) {
		  setLanguages(props.languages);
		}
	}, [props.languages]);

	const fetchCounter = async () => {
		const result = await axios.post(urls.activeCounter, {
			id: window.sessionId
		})

		let response = result.data;
		if (response.data) {
			calculateCounter(response.data)
		}
	}

	useEffect(() => {
		fetchCounter()
	}, [])

	useEffect(() => {
		if (counter) {
			counterIntervalTimer = setInterval(() => {
				calculateCounter(counter)
			}, 1000);
		}
	}, [counter])

	useEffect(() => {
		if (sessionState.timer.duration !== undefined) {
			const counterTimer = { ...sessionState.timer };

			if (sessionState.timer.duration === 0) {
				setCounterData({
					name: counterData.name,
					counterMinutes: 0,
					counterSeconds: 0
				})
				if (counterIntervalTimer) {
					clearInterval(counterIntervalTimer)
				}
			}else{
				fetchCounter()
			}
		}
	}, [sessionState.timer])
	
	useEffect(() => {
		if (sessionState.teamName) {
			setTeamName(sessionState.teamName)
		}else if (props.teamName) {
			setTeamName(props.teamName)
		}
	}, [props.teamName, sessionState.teamName])

	useEffect(() => {
		const selectedLang = languages.filter(
		  (lang) => lang.id === parseInt(localStorage.langId)
		);
		if (selectedLang.length > 0) {
		  setSelectedLanguageTitle(selectedLang[0].name);
		}
	}, [languages]);

	const calculateCounter = (timerData) => {
		const currentDate = moment().utc()
		const startDate = moment.utc(timerData.createdAt);
		const endDate = startDate.clone().add(parseInt(timerData.duration), 'minutes');
		
		const minutes = endDate.diff(currentDate)
		if (minutes > 0) {
			let minutesValue = 0
			let secondsValue = 0
			if (Math.ceil((minutes / 1000) % 60) === 60) {
				minutesValue = Math.ceil(minutes / 1000 / 60);
				secondsValue = 0;
			}else{
				if (minutes > 60000){
					minutesValue = Math.ceil(minutes / 1000 / 60) - 1;
				}else{
					minutesValue = 0
				}
				secondsValue = Math.ceil((minutes / 1000) % 60);
			}

			setCounterData({
				name: timerData.name,
				counterMinutes: minutesValue,
				counterSeconds: secondsValue
			})
			if (!counter) {
				setCounter(timerData)
			}else if (counter.createdAt !== timerData.createdAt) {
				setCounter(timerData)
			}
		}else{
			setCounterData({
				name: timerData.name,
				counterMinutes: 0,
				counterSeconds: 0
			})
			if (counterIntervalTimer) {
				clearInterval(counterIntervalTimer)
			}
		}
	}

	const updateEditTeamName = () => {
		//if (parseInt(props.session.year) === 0) {
		setEditTeamName(true)
		//}
	}

	const updateTeamName = async () => {
		const dataObj = {
			playerId: window.playerId,
			teamId: window.teamId,
			token: window.token,
			name: teamName
		};
		await axios.post(urls.renameTeam, dataObj)

		setEditTeamName(false)

		// Update Socket
		RealtimeConnection.changeTeamName({
			value: dataObj.name
		})
	}

	const zoomInEvent = () => {
		if (props.zoomValue < 150) {
			props.setZoomValue(props.zoomValue + 10)
		}

		/*if (zoomLevel < 100) {
			let appContainer = document.getElementsByClassName("App");
			let kitContainer = document.getElementsByClassName("main-app-bg");

			appContainer[0].classList.remove(`zoom-${zoomLevel}`);
			kitContainer[0].classList.remove(`zoom-value-${zoomLevel}`);
			kitContainer[0].classList.remove(`zoom-${zoomLevel}`);

			setZoomLevel((prevValue) => prevValue + 20);
		}*/
	};

	const zoomOutEvent = () => {
		if (props.zoomValue > 70) {
			props.setZoomValue(props.zoomValue - 10)
		}
												

		/*if (zoomLevel > 0) {
			let appContainer = document.getElementsByClassName("App");
			let kitContainer = document.getElementsByClassName("main-app-bg");

			appContainer[0].classList.remove(`zoom-${zoomLevel}`);
			kitContainer[0].classList.remove(`zoom-value-${zoomLevel}`);
			kitContainer[0].classList.remove(`zoom-${zoomLevel}`);

			setZoomLevel((prevValue) => prevValue - 20);
		}*/
	};
	
	useEffect(() => {
		if (propertiesState.isFieldSelected) {
			setShowPopup(true)
		}
	}, [propertiesState.isFieldSelected])

	const closePanel = () => {
		dispatch({ type: "CLOSE_PROPERTIES" })
		setShowPopup(false)
	}

	const changeLanguage = (language) => {
		if (localStorage.langId !== language) {
		  localStorage.langId = language;
	
		  window.location.reload();
		}
	};

	return (
		<div className="App-header">
			<div className="App-header-container">
				<div className="header-logo-container">
					<Image src={logo} className={"App-logo"} />
				</div> 
				<div className="change-team-name-container">
					{
						(canEdit && editTeamName) ?
							<Container className="bg-blue form">
								<div className="team-data-content">
									<Row>
										<Col className="team-form-container">
											<input type="text" value={ teamName } onChange={e => setTeamName(e.target.value) } />
										</Col>
									</Row>
									<Row>
										<Col className="team-form-container">
											<div
												className="team-name-btn"
												onClick={ updateTeamName }
											>
												<Translate alias="Board" word="Save" />
											</div>
										</Col>
									</Row>
								</div>
							</Container>
							:
							<Container className="bg-blue">
								<div className="widget-content">
									<div className="header-section">
										<Translate alias="Board" word="Team" />
									</div>
									<div className="team-data-content">
										<Row className="cursorPointer" onClick={ updateEditTeamName }>
											<Col>
												<div className="team-name-text" >
													{ teamName }
												</div>
											</Col>
										</Row>
									</div>
								</div>
							</Container>
					}
				</div>
				<div className="player-name-container">
					<Container className="bg-green">
						<div className="widget-content">
							<div className="header-section">
								<Translate alias="Board" word="Player" />
							</div>
							<div className="team-data-content">
								<Row>
									<Col>
										<div className="player-name">
											{ (window.playerName) ? `${ window.playerName }` : `Player ${ window.playerId }` }
										</div>
									</Col>
								</Row>
							</div>
						</div>
					</Container>
				</div>
				<div className="year-container">
					<Container className="bg-blue">
						<div className="widget-content">
							<div className="team-data-content">
								<Row>
									<Col style={{ display: "flex" }}>
										<div style={{ display: "flex", margin: "0 auto" }}>
											<div className="header-section">
												<Translate alias="Board" word="Year" />
											</div>
											<div className="year-value">{ sessionState.year }</div>
										</div>
									</Col>
								</Row>
							</div>
						</div>
					</Container>
				</div>
				<div className="counter-container">
					{
						(counterData.name) ?
							<Container className="bg-red">
								<div className="widget-content">
									<div className="header-section">
										{ counterData.name }
									</div>
									<div className="team-data-content">
										<Row>
											<Col>
												<div className="counter-value"
													style={{ color: (counterData.counterMinutes === 0 && counterData.counterSeconds > 0) ? "#FF0000" : "#0A2955" }}
												>
													{counterData.counterMinutes > 9 ? counterData.counterMinutes : `0${counterData.counterMinutes}`} :{" "}
													{counterData.counterSeconds > 9 ? counterData.counterSeconds : `0${counterData.counterSeconds}`}
												</div>
											</Col>
										</Row>
									</div>
								</div>
								<div className="header-separator-2">&nbsp;</div>
							</Container>
							:
							null
					}
				</div>
				<div className="zoom-container">
					<Container>
						<Row>
							<Col>
								<div className="zoom-btn-container" onClick={zoomInEvent}>
									<Image src={zoomIn} className="zoom-btn" />
								</div>
							</Col>
							<Col>
								<div className="zoom-btn-container" onClick={zoomOutEvent}>
									<Image src={zoomOut} className="zoom-btn" />
								</div>
							</Col>
						</Row>
					</Container>
				</div>
				<div className="chat-icon-container">
					<div className="header-separator">&nbsp;</div>

					{ /*<div className="chat-container" >
						<Image src={chatIcon} />
						<div className="chat-title">
						Chat
						</div>
						
					</div>*/ }
				</div>
				<div className="logout-btn-container">
					<div className="language-btn">
						<DropdownButton id="dropdown-item-button" title={selectedLanguageTitle}>
							{languages.map((lang) => (
								<Dropdown.Item key={lang.id} as="button" onClick={() => changeLanguage(lang.id)}>
									{lang.name}
								</Dropdown.Item>
							))}
						</DropdownButton>
					</div>
					{ /*<div className="logout-btn" >
						<Image src={logoutIcon} />
					</div>*/ }
				</div>
			</div>

			{
				(showPopup)?
					<div className="field-properties-popup">
						<Popup
							open
							modal
							onClose={ closePanel }
						>
							
							
								<div className="popup-body">
									{
										(propertiesState.isFieldSelected)?
											<Properties
												canEdit={canEdit}
												startingKit={startingKitState}
												properties={propertiesState}
												board={boardState}
												closePanel={closePanel}
											/>
											:
											null
									}
								</div>
							
						</Popup>
					</div>
					:
					null
			}
		</div>
	)
}
