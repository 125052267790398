import React, { useEffect, useState } from "react";
import { Col, Image, Row } from "react-bootstrap";
import SmileFace from "../../assets/images/smile-face.jpg";
import { useDispatch, useSelector } from "react-redux";
import { addSmile } from "../../Redux/dispatchers";
import DropSmileFace from "./DropSmileFace";
import * as RealtimeConnection from "../RealtimeConnection";
import Translate from "../../utils/Translate";

export default function BottomBlock() {
	const dispatch = useDispatch();
	const { State } = useSelector((state) => ({
		State: state.board.BottomBlock,
	}));

	const AddSmile = (id, name,number) => {
		//dispatch(addSmile(id, name));
		RealtimeConnection.addSmile("BottomBlock", {
			id:id,
			title: name,
			number:number
		})
	};

	const removeSmileFace = (id, isDropToDelete) => {
		RealtimeConnection.removeSmileFace("BottomBlock", {
			id: id,
			number: 1,
			isDropToDelete: isDropToDelete
		})
	}

	const showSmileyProperties = (id) => {
		dispatch({type: "PROPERTIES", alias: "SMILEY", options: { id: id }})
	}

	return (
		<div className="Remove-padding">
			<div className="Bottom-block d-flex">
				{State.map((data, index) => (
					<Row key={index}>
						<div className="text-column">
							{
								(index === 0 &&
									<p className="Text-bold-size">
										<Translate alias="Board" word="employees" />
									</p>
								)
							}
							{
								(index === 1 &&
									<p className="Text-bold-size">
										<Translate alias="Board" word="share holder" />
									</p>
								)
							}
							{
								(index === 2 &&
									<p className="Text-bold-size">
										<Translate alias="Board" word="customer" />
									</p>
								)
							}
						</div>
						<div className="field-column">
							<div className="Vertical-droppable-field" style={{ position: "relative" }} onClick={ () => showSmileyProperties(index) }>
								<DropSmileFace
									AddSmile={AddSmile}
									removeSmileFace={ removeSmileFace }
									name={data.name}
									id={index}
									smileNum={data.smileNum}
								/>
								<p style={{ color: "#000" }}>{data.smileNum}</p>
							</div>
						</div>
					</Row>
				))}
			</div>
		</div>
	);
}
