import React from "react";
import { useDrag, DragPreviewImage } from 'react-dnd';
import {Image} from "react-bootstrap";

// Constants
import items from "../../utils/items";

// Images
import factory from "../../assets/images/factory-card.png";
import previewImg from "../../assets/images/factory-card-preview.png";


export default function MainDraggableFactory(props) {
	const [, drag, preview] = useDrag({
		item: { type: items.factoryLbl, count: props.count },
		collect: monitor => ({
			isDragging: !!monitor.isDragging(),
		}),
		end: (dropResult, monitor) => {
			let droppedItem = monitor.getDropResult();
			if (droppedItem !== null && droppedItem.type !== undefined) {
				if (monitor.didDrop() && dropResult.count >= 1) {
					props.decreaseStarterKit("factory", 1);
				}
			}
		}
	});

	const canDrag = drag //(props.canEdit) ? drag : null
	return (
		<>
			<DragPreviewImage connect={ preview } src={ previewImg } />
			<div className="labelDraggable" ref={ canDrag }>
				<Image src={ factory } />
			</div>
		</>
	);
}