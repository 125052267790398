import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";

export default function BottomRightBlockRowField(props) {
  return (
    <Col className="Droppable-container-margin">
      {props.children}
      {/* <div className="Vertical-droppable-field"></div> */}
    </Col>
  );
}
