import { combineReducers } from 'redux'

import simflexReducer from './Reducers/simCompact-reducer'
import propertiesReducer from "./Reducers/simCompact-property-reducer";
import startingKit from "./Reducers/starting-kit-reducer";
import sessionReducer from "./Reducers/simflex-session-reducer";
import formReducer from "./Reducers/forms-reducer";

export default combineReducers({
	board: simflexReducer,
	properties: propertiesReducer,
	startingKit: startingKit,
	session: sessionReducer,
	form: formReducer
})