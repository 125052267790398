const initState = {
	balanceSheet: {
		period_0: {
			cash: "",
			receivables: "",
			inventories: "",
			machines: "",
			productionFacilities: "",
			capitalizedDevelopmentCosts: "",
			payables: "",
			liabilities: "",
			equity: "",
			employees: "",
			shareHolder: "",
			customer: ""
		},
		period_1: {
			cash: "",
			receivables: "",
			inventories: "",
			machines: "",
			productionFacilities: "",
			capitalizedDevelopmentCosts: "",
			payables: "",
			liabilities: "",
			equity: "",
			employees: "",
			shareHolder: "",
			customer: ""
		},
		period_2: {
			cash: "",
			receivables: "",
			inventories: "",
			machines: "",
			productionFacilities: "",
			capitalizedDevelopmentCosts: "",
			payables: "",
			liabilities: "",
			equity: "",
			employees: "",
			shareHolder: "",
			customer: ""
		},
		period_3: {
			cash: "",
			receivables: "",
			inventories: "",
			machines: "",
			productionFacilities: "",
			capitalizedDevelopmentCosts: "",
			payables: "",
			liabilities: "",
			equity: "",
			employees: "",
			shareHolder: "",
			customer: ""
		},
		period_4: {
			cash: "",
			receivables: "",
			inventories: "",
			machines: "",
			productionFacilities: "",
			capitalizedDevelopmentCosts: "",
			payables: "",
			liabilities: "",
			equity: "",
			employees: "",
			shareHolder: "",
			customer: ""
		},
		period_5: {
			cash: "",
			receivables: "",
			inventories: "",
			machines: "",
			productionFacilities: "",
			capitalizedDevelopmentCosts: "",
			payables: "",
			liabilities: "",
			equity: "",
			employees: "",
			shareHolder: "",
			customer: ""
		},
		period_6: {
			cash: "",
			receivables: "",
			inventories: "",
			machines: "",
			productionFacilities: "",
			capitalizedDevelopmentCosts: "",
			payables: "",
			liabilities: "",
			equity: "",
			employees: "",
			shareHolder: "",
			customer: ""
		}
	},
	profitLoss: {
		period_1: {
			revenue: "",
			directCosts: "",
			sales: "",
			overheads: "",
			deprecitation: "",
			interest: "",
			EBITRatio: "",
			returnOnSales: ""
		},
		period_2: {
			revenue: "",
			directCosts: "",
			sales: "",
			overheads: "",
			deprecitation: "",
			interest: "",
			EBITRatio: "",
			returnOnSales: ""
		},
		period_3: {
			revenue: "",
			directCosts: "",
			sales: "",
			overheads: "",
			deprecitation: "",
			interest: "",
			EBITRatio: "",
			returnOnSales: ""
		},
		period_4: {
			revenue: "",
			directCosts: "",
			sales: "",
			overheads: "",
			deprecitation: "",
			interest: "",
			EBITRatio: "",
			returnOnSales: ""
		},
		period_5: {
			revenue: "",
			directCosts: "",
			sales: "",
			overheads: "",
			deprecitation: "",
			interest: "",
			EBITRatio: "",
			returnOnSales: ""
		},
		period_6: {
			revenue: "",
			directCosts: "",
			sales: "",
			overheads: "",
			deprecitation: "",
			interest: "",
			EBITRatio: "",
			returnOnSales: ""
		}
	}
};

export default initState;