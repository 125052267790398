import React from "react";
import { Row, Col, Image } from "react-bootstrap";
import { useSelector } from "react-redux";

// CSS
import "../../assets/css/SimCompact/RightBlock.css";

// Images
import rightArrow from "../../assets/images/right-arrow.png";

// Components
import BottomRightBlockRowField from "./BottomRightBlockRowField";
import * as RealtimeConnection from "../RealtimeConnection";

import DroppableDirectCostField from "./Fields/DroppableDirectCostField";
import Translate from "../../utils/Translate";

export default function BottomRightBlock() {

	const { State } = useSelector((state) => ({
		State: state.board.DirectCost,
	}));

	const appendContainer = (id, coins, hasCard, cardValue, cardColor, oldCardValue, stockId, mainStockId) => {
		let directCostCoins = []
		let stockCoins = []

		const diffCardValue = oldCardValue - cardValue;
		if (diffCardValue > 0) {

			if (coins.length > 0) {
				let totalCoins = coins.reduce((total, num) => {
					return parseFloat(total) + parseFloat(num);
				})

				const productValue = parseInt(totalCoins) / parseInt(oldCardValue);
				let directCostValue = productValue * cardValue;

				while (directCostValue > 0) {
					if (directCostValue >= 10) {
						directCostCoins.push(10)
						directCostValue -= 10
					}else if(directCostValue >= 1) {
						directCostCoins.push(1)
						directCostValue -= 1
					}else{
						directCostCoins.push(0.5)
						directCostValue -= 0.5
					}
				}

				let salesCostValue = productValue * diffCardValue;
				while (salesCostValue > 0) {
					if (salesCostValue >= 10) {
						stockCoins.push(10)
						salesCostValue -= 10
					}else if(salesCostValue >= 1) {
						stockCoins.push(1)
						salesCostValue -= 1
					}else{
						stockCoins.push(0.5)
						salesCostValue -= 0.5
					}
				}

				// Update Socket
				RealtimeConnection.addContainer("DirectCost", {
					id: id,
					coins: directCostCoins,
					hasCard: hasCard,
					cardValue: cardValue,
					cardColor: cardColor
				})
				RealtimeConnection.addContainer("Sales", {
					mainId: mainStockId,
					id: stockId,
					coins: stockCoins,
					hasCard: hasCard,
					cardValue: diffCardValue,
					cardColor: cardColor
				})
			}

		}else {
			directCostCoins = coins
			RealtimeConnection.addContainer("DirectCost", {
				id: id,
				coins: directCostCoins,
				hasCard: hasCard,
				cardValue: cardValue,
				cardColor: cardColor
			})

			RealtimeConnection.removeContainer("Sales", {
				mainId: mainStockId,
				id: stockId,
				isDropToDelete: false
			})
		}
	};

	const addCoinHandler = (id, coinValue, kind) => {
		// dispatch(addCoin(kind, id, coinValue, 200, "ahmed"));
		RealtimeConnection.addCoin(kind, {
			id: id,
			value: coinValue,
		});
	};
	const removeContainerHandler = (id, isDropToDelete, kind) => {
		// dispatch(removeContainer(kind, id));

		// Update Socket
		RealtimeConnection.removeContainer(kind, {
			id: id,
			isDropToDelete: isDropToDelete,
		});
	};
	const isDraggingHandler = (type, isDragging, data, kind) => {
		let dataObj = {
			teamId: window.teamId,
			sessionId: window.sessionId,
			playerId: window.playerId,
			playerName: window.playerName,
			alias: kind,
			isDragging: isDragging,
			type: type,
		};
		dataObj = { ...dataObj, ...data };

		window.socket.emit("item-dragging", dataObj);
	};

	const droppableFieldsList = State.map((value, index) => {
		return (
			<React.Fragment key={index}>
				{index % 3 === 0 ? <div className="w-100"></div> : null}
				<BottomRightBlockRowField id={index}>
					<DroppableDirectCostField
						id={index}
						class={"Vertical-droppable-field"}
						canEdit={ true }
						isHorizontal={false}
						data={value}
						appendContainer={appendContainer}
						addCoin={addCoinHandler}
						kind={"DirectCost"}
						removeContainer={removeContainerHandler}
						isDraggingHandler={isDraggingHandler}
					/>
				</BottomRightBlockRowField>
			</React.Fragment>
		);
	});

	return (
		<div className="Right-block left-right-block bottom">
			<Row>
				<Col>
					<div className="Text-bold-size">
						<Translate alias="Board" word="Direct Costs" />
					</div>
				</Col>
			</Row>
			<Row>{droppableFieldsList}</Row>
			<Image src={rightArrow} className="Arrow-class" style={{ top: "20%" }} />
		</div>
	);
}
