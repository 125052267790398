import React from "react";
import { useDrag, DragPreviewImage } from "react-dnd";
import { Image } from "react-bootstrap";

import items from "../../utils/items";

// Images
//import previewImg from "../../assets/images/faces/faceYellow-preview.svg"
import smileImg from "../../assets/images/faces/faceYellow.svg";

export default function DraggableSmileFace(props) {

	const [, drag, preview] = useDrag({
		item: { type: items.Smile },
		collect: (monitor) => ({
			isDragging: !!monitor.isDragging(),
		}),
		end: (dropResult, monitor) => {
			let doppableItem = monitor.getDropResult();
			if (monitor.didDrop()) {
				const isDropToDelete = doppableItem.droppableType === "delete" ? true : false;

				props.removeSmileFace(props.id, isDropToDelete);
			}
		}
	});

	return (
		<div>
			{ /*<DragPreviewImage connect={preview} src={previewImg} />*/ }
			<Image src={smileImg} ref={drag} />
		</div>
	);
}
