import React, { useEffect, useState } from "react";
import {Row, Col} from "react-bootstrap";
import Translate from "../../../utils/Translate";

// Components
import PurchaseRow from "./PurchaseRow";


export default function PurchaseBlock({ canEdit }) {
	return (
		<div className="Purchase-block-container">
			<Row>
				<Col>
					<div className="Purchase-bold-text Purchase-title-text-size">
						<Translate alias="Board" word="Purchasing" />
					</div>
					<div className="Purchase-subtitle-text-size">
						<Translate alias="Board" word="inventories" />
					</div>
				</Col>
			</Row>
			<PurchaseRow  className="Standard-row" rowKey="economy" canEdit={ canEdit } />
			<PurchaseRow  className="Luxus-row" rowKey="elite" canEdit={ canEdit } />
		</div>
	)
}
