import React, { useState, useEffect } from 'react';
import {useDispatch, useSelector} from "react-redux";
import { Container, Row, Col, Table, Dropdown, DropdownButton, ButtonGroup } from 'react-bootstrap';

import Translate from '../../utils/Translate'

// Dispatcher
import * as RealtimeConnection from "../RealtimeConnection";

import "../../assets/css/Forms.css";

const DecisionSheet = (props) => {
	const dispatch = useDispatch()
	const formState = useSelector(state => state.form.DecisionSheet)
	const sessionState = useSelector(state => state.session)

	const [yearsOptions, setYearsOptions] = useState([])
	const [isCurrentYear, setIsCurrentYear] = useState(false)

	useEffect(() => {
		if (sessionState.year !== undefined) {
			let options = []
			for(let i = 0; i <= sessionState.year; i++) {
				options.push(<Dropdown.Item key={i} eventKey={i} onClick={ () => changeFormYear(i) }>Year { i }</Dropdown.Item>)
			}
	
			let isCurrentYear = false;
			if (sessionState.year === sessionState.formSelectedYear) {
				isCurrentYear = true
			}
	
			if (!props.canEdit) {
				isCurrentYear = false
			}

			setYearsOptions(options)
			setIsCurrentYear(isCurrentYear)
		}
	}, [sessionState])

	const changeFormYear = (selectedYear) => {
		//this.props.changeFormYear(selectedYear);
		dispatch({ type: "CHANGE_FORM_YEAR", year: selectedYear })

		RealtimeConnection.formChangeYear({
			year: selectedYear,
			type: "DecisionSheet"
		})
	}

	const closeForm = () => {
		dispatch({ type: "OPEN_FORM", value: ""})
		//this.props.openFormView("")
	}

	const inputChanged = (section, field, option, value) => {
		// Update Socket
		RealtimeConnection.updateForm({
			year: sessionState.year,
			type: "DecisionSheet",
			section: section,
			field: field,
			option: option,
			value: value
		})
	}

	return (
		<Container className="formContainer">
			<Row>
				<Col>
					<div className="formHeader">
						<div className="headerTitle bold">
							<Translate alias="Forms" word="Decision Sheet" />
							<DropdownButton
								as={ButtonGroup}
								variant="outline-secondary"
								title={ `Year ${sessionState.formSelectedYear}` }
								size="sm"
								style={{ marginLeft: "10px" }}
							>
								{ yearsOptions }
							</DropdownButton>
						</div>
						<div className="closeForm" onClick={ closeForm }>X</div>
					</div>
				</Col>
			</Row>
			<Row>
				<Col>
					<Table bordered>
						<thead>
							<tr>
								<th style={{ width: "100px" }}></th>
								<th style={{ width: "100px" }}>
									<div className="bold"><Translate alias="Forms" word="Region A" /></div>
								</th>
								<th style={{ width: "100px" }}>
									<div className="bold"><Translate alias="Forms" word="Region B" /></div>
								</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>
									<div className="bold"><Translate alias="Forms" word="Price" /></div>
									<br />
									<div className="bold">4-7</div>
									<div className="bold">5-9</div>
								</td>
								<td>
									<div className="bold"><Translate alias="Forms" word="Economy" /></div>
									<input 
										style={{ width: "100px" }} 
										value={ formState.price.RegionA.economy }
										onChange={ (e) => inputChanged("price", "RegionA", "economy", e.target.value) }
										//disabled={ !this.props.status }
									/>
									<br />
									<div className="bold"><Translate alias="Forms" word="Elite" /></div>
									<input 
										style={{ width: "100px" }} 
										value={ formState.price.RegionA.elite }
										onChange={ (e) => inputChanged("price", "RegionA", "elite", e.target.value) }
										//disabled={ !this.props.status }
									/>
								</td>
								<td>
									<div className="bold"><Translate alias="Forms" word="Economy" /></div>
									<input 
										style={{ width: "100px" }} 
										value={ formState.price.RegionB.economy }
										onChange={ (e) => inputChanged("price", "RegionB", "economy", e.target.value) }
										//disabled={ !this.props.status }
									/>
									<br />
									<div className="bold"><Translate alias="Forms" word="Elite" /></div>
									<input 
										style={{ width: "100px" }} 
										value={ formState.price.RegionB.elite }
										onChange={ (e) => inputChanged("price", "RegionB", "elite", e.target.value) }
										//disabled={ !this.props.status }
									/>
								</td>
							</tr>
							<tr>
								<td>
									<div className="bold"><Translate alias="Forms" word="Quality Expenses" /></div>
								</td>
								<td>
									<div className="bold"><Translate alias="Forms" word="Economy" /></div>
									<input 
										style={{ width: "100px" }} 
										value={ formState.qualityExpenses.RegionA.economy }
										onChange={ (e) => inputChanged("qualityExpenses", "RegionA", "economy", e.target.value) }
										//disabled={ !this.props.status }
									/>
									<br />
									<div className="bold"><Translate alias="Forms" word="Elite" /></div>
									<input 
										style={{ width: "100px" }} 
										value={ formState.qualityExpenses.RegionA.elite }
										onChange={ (e) => inputChanged("qualityExpenses", "RegionA", "elite", e.target.value) }
										//disabled={ !this.props.status }
									/>
								</td>
								<td>
									<div className="bold"><Translate alias="Forms" word="Economy" /></div>
									<input 
										style={{ width: "100px" }} 
										value={ formState.qualityExpenses.RegionB.economy }
										onChange={ (e) => inputChanged("qualityExpenses", "RegionB", "economy", e.target.value) }
										//disabled={ !this.props.status }
									/>
									<br />
									<div className="bold"><Translate alias="Forms" word="Elite" /></div>
									<input 
										style={{ width: "100px" }} 
										value={ formState.qualityExpenses.RegionB.elite }
										onChange={ (e) => inputChanged("qualityExpenses", "RegionB", "elite", e.target.value) }
										//disabled={ !this.props.status }
									/>
								</td>
							</tr>
							<tr>
								<td>
									<div className="bold"><Translate alias="Forms" word="Sales Expenses" /></div>
								</td>
								<td>
									<div className="bold"><Translate alias="Forms" word="Economy" /></div>
									<input 
										style={{ width: "100px" }} 
										value={ formState.salesExpenses.RegionA.economy }
										onChange={ (e) => inputChanged("salesExpenses", "RegionA", "economy", e.target.value) }
										//disabled={ !this.props.status }
									/>
									<br />
									<div className="bold"><Translate alias="Forms" word="Elite" /></div>
									<input 
										style={{ width: "100px" }} 
										value={ formState.salesExpenses.RegionA.elite }
										onChange={ (e) => inputChanged("salesExpenses", "RegionA", "elite", e.target.value) }
										//disabled={ !this.props.status }
									/>
								</td>
								<td>
									<div className="bold"><Translate alias="Forms" word="Economy" /></div>
									<input 
										style={{ width: "100px" }} 
										value={ formState.salesExpenses.RegionB.economy }
										onChange={ (e) => inputChanged("salesExpenses", "RegionB", "economy", e.target.value) }
										//disabled={ !this.props.status }
									/>
									<br />
									<div className="bold"><Translate alias="Forms" word="Elite" /></div>
									<input 
										style={{ width: "100px" }} 
										value={ formState.salesExpenses.RegionB.elite }
										onChange={ (e) => inputChanged("salesExpenses", "RegionB", "elite", e.target.value) }
										//disabled={ !this.props.status }
									/>
								</td>
							</tr>
							<tr>
								<td>
									<div className="bold"><Translate alias="Forms" word="Invoice Ratio in %" /></div>
								</td>
								<td>
									<div className="bold"><Translate alias="Forms" word="Economy" /></div>
									<input 
										style={{ width: "100px" }} 
										value={ formState.invoiceRatio.RegionA.economy }
										onChange={ (e) => inputChanged("invoiceRatio", "RegionA", "economy", e.target.value) }
										//disabled={ !this.props.status }
									/>
									<br />
									<div className="bold"><Translate alias="Forms" word="Elite" /></div>
									<input 
										style={{ width: "100px" }} 
										value={ formState.invoiceRatio.RegionA.elite }
										onChange={ (e) => inputChanged("invoiceRatio", "RegionA", "elite", e.target.value) }
										//disabled={ !this.props.status }
									/>
								</td>
								<td>
									<div className="bold"><Translate alias="Forms" word="Economy" /></div>
									<input 
										style={{ width: "100px" }} 
										value={ formState.invoiceRatio.RegionB.economy }
										onChange={ (e) => inputChanged("invoiceRatio", "RegionB", "economy", e.target.value) }
										//disabled={ !this.props.status }
									/>
									<br />
									<div className="bold"><Translate alias="Forms" word="Elite" /></div>
									<input 
										style={{ width: "100px" }} 
										value={ formState.invoiceRatio.RegionB.elite }
										onChange={ (e) => inputChanged("invoiceRatio", "RegionB", "elite", e.target.value) }
										//disabled={ !this.props.status }
									/>
								</td>
							</tr>
							<tr>
								<td>
									<div className="bold"><Translate alias="Forms" word="Units offered" /></div>
								</td>
								<td>
									<div className="bold"><Translate alias="Forms" word="Economy" /></div>
									<input 
										style={{ width: "100px" }} 
										value={ formState.unitsOffered.RegionA.economy }
										onChange={ (e) => inputChanged("unitsOffered", "RegionA", "economy", e.target.value) }
										//disabled={ !this.props.status }
									/>
									<br />
									<div className="bold"><Translate alias="Forms" word="Elite" /></div>
									<input 
										style={{ width: "100px" }} 
										value={ formState.unitsOffered.RegionA.elite }
										onChange={ (e) => inputChanged("unitsOffered", "RegionA", "elite", e.target.value) }
										//disabled={ !this.props.status }
									/>
								</td>
								<td>
									<div className="bold"><Translate alias="Forms" word="Economy" /></div>
									<input 
										style={{ width: "100px" }} 
										value={ formState.unitsOffered.RegionB.economy }
										onChange={ (e) => inputChanged("unitsOffered", "RegionB", "economy", e.target.value) }
										//disabled={ !this.props.status }
									/>
									<br />
									<div className="bold"><Translate alias="Forms" word="Elite" /></div>
									<input 
										style={{ width: "100px" }} 
										value={ formState.unitsOffered.RegionB.elite }
										onChange={ (e) => inputChanged("unitsOffered", "RegionB", "elite", e.target.value) }
										//disabled={ !this.props.status }
									/>
								</td>
							</tr>
						</tbody>
					</Table>
				</Col>
			</Row>
		</Container>
	)
}

export default DecisionSheet