const startingKit = {
	productDevelopment: {
		economy: true,
		elite: false
	},
	salesMarket: {
		RegionA: true,
		RegionB: false
	},
	productionFacility: {
		RegionA1: true,
		RegionA2: true,
		RegionB1: false,
		RegionB2: false
	},
	machine: {
		RegionA1: false,
		RegionA2: false,
		RegionB1: false,
		RegionB2: false
	},
	productionVolume: {
		RegionA1: "",
		RegionA2: "",
		RegionB1: "",
		RegionB2: "",
		RegionA1Economy: false,
		RegionA1Elite: false,
		RegionA2Economy: false,
		RegionA2Elite: false,
		RegionB1Economy: false,
		RegionB1Elite: false,
		RegionB2Economy: false,
		RegionB2Elite: false
	},
	liabilities: {
		value: ""
	}
}

export default startingKit;