import React from "react";
import { useDrag, DragPreviewImage } from "react-dnd";

// Constants
import items from "../../utils/items";

// Images
import previewGoldCoinImg from "../../assets/images/dollar_coin-preview.png";
import previewSilverCoinImg from "../../assets/images/dollar_coin_silver-preview.png";
import previewBronzeCoinImg from "../../assets/images/dollar_coin_bronze-preview.png";

let numberOfUpdates = 0;

export default function DraggableCoin(props) {
  const [{ isDragging }, drag, preview] = useDrag({
    item: {
      type: items.coin,
      value: props.value,
      containerId: props.containerId,
    },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
    end: (dropResult, monitor) => {
      let doppableItem = monitor.getDropResult();
      if (monitor.didDrop()) {
        const isDropToDelete =
          doppableItem.droppableType === "delete" ? true : false;

        if (doppableItem !== null && doppableItem.type !== undefined) {
          if (props.productionType) {
            props.removeCoin(
              props.productionType,
              props.index,
              props.id,
              isDropToDelete,
              props.kind
            );
          } else {
            props.id !== undefined
              ? props.removeCoin(props.id, props.index, isDropToDelete,props.kind)
              : props.removeCoin(props.index, isDropToDelete,props.kind);
          }
        }

        numberOfUpdates = 0;

        // Update Dragging Status
        let dragObjData = {};
        if (props.id !== undefined) dragObjData.id = props.id;
        if (props.productionType !== undefined)
          dragObjData.fieldType = props.productionType;

        props.isDraggingHandler("COIN", false, dragObjData);
      }
    },
  });

  let previewImg = previewGoldCoinImg;
  let coinClass = props.isLastCoin ? "last-coin " : "not-last-coin ";
  switch (props.value) {
    case 10:
      coinClass += "gold";
      previewImg = previewGoldCoinImg;
      break;
    case 1:
      coinClass += "silver";
      previewImg = previewSilverCoinImg;
      break;
    case 0.5:
      coinClass += "bronze";
      previewImg = previewBronzeCoinImg;
      break;
    default:
      break;
  }

  let canDrag = props.canEdit ? drag : null;

  let dragClass = "";
  if (isDragging) {
    dragClass = "dragging";

    if (numberOfUpdates === 0) {
      numberOfUpdates = 1;
      const dragObjData = {
        id: props.id,
        fieldType: props.productionType,
        index: props.index,
      };
      props.isDraggingHandler("COIN", isDragging, dragObjData);
    }
  } else if (
    props.coinDraggingIndex === props.index &&
    props.draggingType === "COIN"
  ) {
    dragClass = "dragging";
    canDrag = null;

    if (props.playerId === window.playerId) {
      if (numberOfUpdates === 1) {
        numberOfUpdates = 0;
        const dragObjData = {
          id: props.id,
          fieldType: props.productionType,
          index: props.index,
        };
        props.isDraggingHandler("COIN", isDragging, dragObjData);
      }
    }
  }

  return (
    <>
      <DragPreviewImage connect={preview} src={previewImg} />
      <div
        ref={canDrag}
        className={["coin", coinClass, dragClass].join(" ")}
      ></div>
    </>
  );
}

/**/
/*<Image ref={canDrag} src={coinImg} className="coin"/>*/
