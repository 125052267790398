import React, { useEffect, useState } from "react";
import {Col, Row} from "react-bootstrap";
import Translate from "../../../utils/Translate";

// Components
import ProductionBlockSection from "./ProductionBlockSection";


export default function ProductionBlock() {
	return (
		<div >
			<Row>
				<Col>
					<div className="Block-title-bold-text Block-title-text-size Same-line">
						<Translate alias="Board" word="Production" />
					</div>
				</Col>
			</Row>
			<Row className="justify-content-center" >
				<div className="Production-column">
					<ProductionBlockSection id={0} title="Region A" />
				</div>
				<div className="Production-column">
					<ProductionBlockSection id={2} title="Region B" />
				</div>
			</Row>
			<Row className="justify-content-center">
				<div className="Production-column">
					<ProductionBlockSection id={1} title="Region A" />
				</div>
				<div className="Production-column">
					<ProductionBlockSection id={3} title="Region B" />
				</div>
				
			</Row>
		</div>
	)
}
