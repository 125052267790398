import React from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";

// Components
import SalesBlockSection from "./SalesBlockSection";
import * as RealtimeConnection from "../../RealtimeConnection";
import Translate from "../../../utils/Translate";

const SalesBlock = (props) => {
	const salesState = useSelector((state) => state.board.CenterBlock.Sales);

	const appendPin = (id) => {
		console.log(id);
		
		RealtimeConnection.addPin("Sales", {
			id: id,
		});
	};

	const removePin = (title, speed, id, isDropToDelete) => {
		// Update Socket
		RealtimeConnection.removePin("Sales", {
			title: title,
			speed: speed,
			id: id,
			isDropToDelete: isDropToDelete,
		});
	};

	return (
		<div>
			<Row>
				<Col>
					<div className="Block-title-bold-text Block-title-text-size Same-line">
						<Translate alias="Board" word="Sales" />
					</div>
				</Col>
			</Row>
			<Col className="justify-content-center">
				{
					salesState.map((data, index) => (
						<div key={index} className="px-4 pb-4">
							<SalesBlockSection
								data={data}
								id={index}
								appendPin={appendPin}
								removePin={removePin}
							/>
						</div>
					))
				}
			</Col>
		</div>
	);
};

export default SalesBlock;
