import React from "react"

const Translate = ({ alias, word }) => {

	let translatedWord = word
	if (window.staticWords) {
		if (window.staticWords[alias]) {
			if (window.staticWords[alias][word]) {
				translatedWord = window.staticWords[alias][word]
			}
		}
	}

	return (
		<React.Fragment>
			{ translatedWord }
		</React.Fragment>
	)
}

export default Translate

