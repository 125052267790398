import React from "react";
import { useDrag } from 'react-dnd';
import {Image} from "react-bootstrap";

// Constants
import items from "../../utils/items";

// Images
import green from "../../assets/images/green-card.png";
import blue from "../../assets/images/blue-card.png";
import yellow from "../../assets/images/yellow-card.png";
import red from "../../assets/images/red-card.png";


export default function MainDraggableProductCard(props) {
	const [, drag] = useDrag({
		item: { type: items.productLbl, value: props.value, color: props.color },
		collect: monitor => ({
			isDragging: !!monitor.isDragging(),
		}),
		end: (dropResult, monitor) => {
			let droppedItem = monitor.getDropResult();
			if (droppedItem !== null && droppedItem.type !== undefined) {
				if (monitor.didDrop()) {
					props.decreaseStarterKit("production", dropResult.value, dropResult.color);
				}
			}
		}
	});

	const canDrag = (props.canEdit) ? drag : null
	console.log(props.color)
	return (
		<>
			{
				(props.value > 0) ?
					<div className="productCardDraggable" ref={canDrag}>
						{
							(props.color === "economy" &&
								<Image className="productCardDraggableImg" src={green} />)
						}
						{
							(props.color === "elite" &&
								<Image className="productCardDraggableImg" src={red} />)
						}
						<div className="productCardValue">{ props.value }</div>
					</div>
					:
					null
			}
		</>
	);
}

/*<DragPreviewImage connect={preview} src={previewImg} /> */