import React from "react";
import { Image } from "react-bootstrap";
import { useDrop } from "react-dnd";

import items from "../../utils/items";
import colors from "../../utils/colors";

import WhiteFace from "../../assets/images/faces/faceWhite.svg"

import DraggableSmileFace from "../Common/DraggableSmileFace";

const DropSmileFace = (props) => {
	const [{ isOver, canDrop }, drop] = useDrop({
		accept: [items.Smile],
		collect: (monitor) => ({
			isOver: monitor.isOver(),
			canDrop: monitor.canDrop(),
		}),
		drop: (item) => {
			if (item.count >= item.value) {
				props.AddSmile(props.id, props.name, 1);
				return item;
			}else{
				return {}
			}
		},
	});

	const backgroundColor = isOver && canDrop ? colors.activeBackground : "";

	return (
		<div 
			className="smileDraggable" 
			ref={drop}
			style={{ backgroundColor: backgroundColor }}
		>
			{
				(props.smileNum > 0) ?
					<DraggableSmileFace 
						id={ props.id }
						removeSmileFace={ props.removeSmileFace }
					/>
					:
					<Image src={WhiteFace} />
			}
		</div>
	);
};

export default DropSmileFace;
