import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col, Table, Dropdown, DropdownButton, ButtonGroup, FormControl } from 'react-bootstrap';

import { FaCheck } from 'react-icons/fa';

// CSS
import "../../assets/css/Forms.css";

// Utils
import * as RealtimeConnection from "../RealtimeConnection";
import Translate from '../../utils/Translate'


const StrategyPaper = (props) => {
	const dispatch = useDispatch()
	const sessionState = useSelector( state => state.session )
	const formState = useSelector( state => state.form )


	const [yearsOptions, setYearsOptions] = useState([])
	const [isCurrentYear, setIsCurrentYear] = useState(false)

	useEffect(() => {
		if (sessionState.year !== undefined) {
			let options = []
			for(let i = 0; i <= sessionState.year; i++) {
				options.push(<Dropdown.Item key={i} eventKey={i} onClick={ () => changeFormYear(i) }>Year { i }</Dropdown.Item>)
			}
	
			let isCurrentYear = false;
			if (sessionState.year === sessionState.formSelectedYear) {
				isCurrentYear = true
			}
	
			if (!props.canEdit) {
				isCurrentYear = false
			}

			setYearsOptions(options)
			setIsCurrentYear(isCurrentYear)
		}
	}, [sessionState])
		
	const changeFormYear = (selectedYear) => {
		console.log(selectedYear)
		dispatch({ type: "CHANGE_FORM_YEAR", year: selectedYear })

		RealtimeConnection.formChangeYear({
			year: selectedYear,
			type: "StrategyPaper"
		})
	}

	const closeForm = () => {
		dispatch({ type: "OPEN_FORM", value: "" })
	}

	const checkboxPressed = (section, field) => {
		if (props.canEdit) {
			if (sessionState.formStatus.strategyPaper) {
				if (sessionState.year === sessionState.formSelectedYear) {
					const value = formState.StrategyPaper[section][field]
					
					dispatch({type: "StrategyPaper", section: section, field: field, value: !value})
					// Update Socket
					RealtimeConnection.updateForm({
						year: sessionState.year,
						type: "StrategyPaper",
						section: section,
						field: field,
						value: !value
					})
				}
			}
		}
	}

	const inputChanged = (section, field, value) => {
		dispatch({type: "StrategyPaper", section: section, field: field, value: value})

		// Update Socket
		RealtimeConnection.updateForm({
			year: sessionState.year,
			type: "StrategyPaper",
			section: section,
			field: field,
			value: value
		})
	}

	//onClick={ this.checkboxPressed.bind(this, "developmentOfProducts", "compact")}
	return (
		<Container className="formContainer">
			<Row>
				<Col>
					<div className="formHeader">
						<div className="headerTitle bold">
							<Translate alias="Forms" word="Strategy Paper" />
							<DropdownButton
								as={ ButtonGroup }
								variant="outline-secondary"
								title={ `Year ${ sessionState.formSelectedYear }` }
								size="sm"
								style={{ marginLeft: "10px" }}
							>
								{ yearsOptions }
							</DropdownButton>
						</div>
						<div className="closeForm" onClick={ closeForm }>X</div>
					</div>
				</Col>
			</Row>
			<Row>
				<Col>
					<Table bordered>
						<tbody>
							<tr>
								<td style={{ width: "100px" }}>
									<div className="bold">
										<Translate alias="Forms" word="Product Development" /> (5)
									</div>
								</td>
								<td>
									<Container>
										<Row>
											<Col md="12" className="removeLeftPadding">
												<div className="bold">
													<Translate alias="Forms" word="Economy" />
													<div className="checkContainer" onClick={ () => checkboxPressed("productDevelopment", "economy") }>
														{
															(formState.StrategyPaper.productDevelopment.economy) ?
																<FaCheck />
																:
																null
														}
													</div>
												</div>
											</Col>
											<Col md="12" className="removeLeftPadding">
												<div className="bold">
													<Translate alias="Forms" word="Elite" />
													<div className="checkContainer" onClick={ () => checkboxPressed("productDevelopment", "elite") }>
														{
															(formState.StrategyPaper.productDevelopment.elite) ?
																<FaCheck />
																:
																null
														}
													</div>
												</div>
											</Col>
										</Row>
									</Container>
								</td>
							</tr>
							<tr>
								<td style={{ width: "100px" }}>
									<div className="bold">
										<Translate alias="Forms" word="Sales Market" /> (5)
									</div>
								</td>
								<td>
									<Container>
										<Row>
											<Col md="12" className="removeLeftPadding">
												<div className="bold">
													<Translate alias="Forms" word="Region A" />
													<div className="checkContainer" onClick={ () => checkboxPressed("salesMarket", "RegionA") }>
														{
															(formState.StrategyPaper.salesMarket.RegionA) ?
																<FaCheck />
																:
																null
														}
													</div>
												</div>
											</Col>
											<Col md="12" className="removeLeftPadding">
												<div className="bold">
													<Translate alias="Forms" word="Region B" />
													<div className="checkContainer" onClick={ () => checkboxPressed("salesMarket", "RegionB") }>
														{
															(formState.StrategyPaper.salesMarket.RegionB) ?
																<FaCheck />
																:
																null
														}
													</div>
												</div>
											</Col>
										</Row>
									</Container>
								</td>
							</tr>
							<tr>
								<td style={{ width: "100px" }}>
									<div className="bold">
										<Translate alias="Forms" word="Production Facility" /> (10)
									</div>
								</td>
								<td>
									<Container>
										<Row>
											<Col md="12" className="removeLeftPadding">
												<div className="bold">
													<Translate alias="Forms" word="Region A1" />
													<div className="checkContainer" onClick={ () => checkboxPressed("productionFacility", "RegionA1") }>
														{
															(formState.StrategyPaper.productionFacility.RegionA1) ?
																<FaCheck />
																:
																null
														}
													</div>
												</div>
											</Col>
											<Col md="12" className="removeLeftPadding">
												<div className="bold">
													<Translate alias="Forms" word="Region A2" />
													<div className="checkContainer" onClick={ () => checkboxPressed("productionFacility", "RegionA2") }>
														{
															(formState.StrategyPaper.productionFacility.RegionA2) ?
																<FaCheck />
																:
																null
														}
													</div>
												</div>
											</Col>
										</Row>
										<hr />
										<Row>
											<Col md="12" className="removeLeftPadding">
												<div className="bold">
													<Translate alias="Forms" word="Region B1" />
													<div className="checkContainer" onClick={ () => checkboxPressed("productionFacility", "RegionB1") }>
														{
															(formState.StrategyPaper.productionFacility.RegionB1) ?
																<FaCheck />
																:
																null
														}
													</div>
												</div>
											</Col>
											<Col md="12" className="removeLeftPadding">
												<div className="bold">
													<Translate alias="Forms" word="Region B2" />
													<div className="checkContainer" onClick={ () => checkboxPressed("productionFacility", "RegionB2") }>
														{
															(formState.StrategyPaper.productionFacility.RegionB2) ?
																<FaCheck />
																:
																null
														}
													</div>
												</div>
											</Col>
										</Row>
									</Container>
								</td>
							</tr>
							<tr>
								<td style={{ width: "100px" }}>
									<div className="bold">
										<Translate alias="Forms" word="Machine" /> (5)
									</div>
									<div className="hintForm">
										<Translate alias="Forms" word="Maximum Production Capacity 10" />
									</div>
								</td>
								<td>
									<Container>
										<Row>
											<Col md="12" className="removeLeftPadding">
												<div className="bold">
													<Translate alias="Forms" word="Region A1" />
													<div className="checkContainer" onClick={ () => checkboxPressed("machine", "RegionA1") }>
														{
															(formState.StrategyPaper.machine.RegionA1) ?
																<FaCheck />
																:
																null
														}
													</div>
												</div>
											</Col>
											<Col md="12" className="removeLeftPadding">
												<div className="bold">
													<Translate alias="Forms" word="Region A2" />
													<div className="checkContainer" onClick={ () => checkboxPressed("machine", "RegionA2") }>
														{
															(formState.StrategyPaper.machine.RegionA2) ?
																<FaCheck />
																:
																null
														}
													</div>
												</div>
											</Col>
										</Row>
										<hr />
										<Row>
											<Col md="12" className="removeLeftPadding">
												<div className="bold">
													<Translate alias="Forms" word="Region B1" />
													<div className="checkContainer" onClick={ () => checkboxPressed("machine", "RegionB1") }>
														{
															(formState.StrategyPaper.machine.RegionB1) ?
																<FaCheck />
																:
																null
														}
													</div>
												</div>
											</Col>
											<Col md="12" className="removeLeftPadding">
												<div className="bold">
													<Translate alias="Forms" word="Region B2" />
													<div className="checkContainer" onClick={ () => checkboxPressed("machine", "RegionB2") }>
														{
															(formState.StrategyPaper.machine.RegionB2) ?
																<FaCheck />
																:
																null
														}
													</div>
												</div>
											</Col>
										</Row>
									</Container>
								</td>
							</tr>
							<tr>
								<td style={{ width: "100px" }}>
									<div className="bold">
										<Translate alias="Forms" word="Production Volume" />
									</div>
									<div className="hintForm">
										<Translate alias="Forms" word="Economy Material/Labour 2/2" />
										<br />
										<Translate alias="Forms" word="Elite Material/Labour 3/2" />
									</div>
								</td>
								<td>
									<Container>
										<Row>
											<Col md="12" className="removeLeftPadding">
												<div className="bold">
													<Translate alias="Forms" word="Region A1" />
													<div style={{ display: "flex" }}>
														<div
															className='strategyPaperEconomyEliteCheckboxes economy'
															onClick={ () => checkboxPressed("productionVolume", "RegionA1Economy") }
														>
															{
																(formState.StrategyPaper.productionVolume.RegionA1Economy) ?
																	<FaCheck />
																	:
																	null
															}
														</div>
														<div
															className='strategyPaperEconomyEliteCheckboxes elite'
															onClick={ () => checkboxPressed("productionVolume", "RegionA1Elite") }
														>
															{
																(formState.StrategyPaper.productionVolume.RegionA1Elite) ?
																	<FaCheck />
																	:
																	null
															}
														</div>
														<div style={{ width: 70, height: 25 }}>
															<FormControl 
																type="number"
																aria-label="Small" 
																size="sm" 
																style={{ height: 25 }}
																value={ formState.StrategyPaper.productionVolume.RegionA1 } 
																onChange={ (e) => inputChanged("productionVolume", "RegionA1", e.target.value) }
																min={ 0 }
																max={ 10 }
																//disabled={ !this.props.status }
															/>
														</div>
													</div>
												</div>
											</Col>
											<Col md="12" className="removeLeftPadding">
												<div className="bold">
													<Translate alias="Forms" word="Region A2" />
													<div style={{ display: "flex" }}>
														<div
															className='strategyPaperEconomyEliteCheckboxes economy'
															onClick={ () => checkboxPressed("productionVolume", "RegionA2Economy") }
														>
															{
																(formState.StrategyPaper.productionVolume.RegionA2Economy) ?
																	<FaCheck />
																	:
																	null
															}
														</div>
														<div 
															className='strategyPaperEconomyEliteCheckboxes elite'
															onClick={ () => checkboxPressed("productionVolume", "RegionA2Elite") }
														>
															{
																(formState.StrategyPaper.productionVolume.RegionA2Elite) ?
																	<FaCheck />
																	:
																	null
															}
														</div>
														<div style={{ width: 70, height: 25 }}>
															<FormControl 
																type="number"
																aria-label="Small" 
																size="sm" 
																style={{ height: 25 }}
																value={ formState.StrategyPaper.productionVolume.RegionA2 } 
																onChange={ (e) => inputChanged("productionVolume", "RegionA2", e.target.value) }
																min={ 0 }
																max={ 10 }
																//disabled={ !this.props.status }
															/>
														</div>
													</div>
												</div>
											</Col>
										</Row>
										<hr />
										<Row>
											<Col md="12" className="removeLeftPadding">
												<div className="bold">
													<Translate alias="Forms" word="Region B1" />
													<div style={{ display: "flex" }}>
														<div
															className='strategyPaperEconomyEliteCheckboxes economy'
															onClick={ () => checkboxPressed("productionVolume", "RegionB1Economy") }
														>
															{
																(formState.StrategyPaper.productionVolume.RegionB1Economy) ?
																	<FaCheck />
																	:
																	null
															}
														</div>
														<div 
															className='strategyPaperEconomyEliteCheckboxes elite'
															onClick={ () => checkboxPressed("productionVolume", "RegionB1Elite") }
														>
															{
																(formState.StrategyPaper.productionVolume.RegionB1Elite) ?
																	<FaCheck />
																	:
																	null
															}
														</div>
														<div style={{ width: 70, height: 25 }}>
															<FormControl 
																type="number"
																aria-label="Small" 
																size="sm" 
																style={{ height: 25 }}
																value={ formState.StrategyPaper.productionVolume.RegionB1 } 
																onChange={ (e) => inputChanged("productionVolume", "RegionB1", e.target.value) }
																min={ 0 }
																max={ 10 }
																//disabled={ !this.props.status }
															/>
														</div>
													</div>
												</div>
											</Col>
											<Col md="12" className="removeLeftPadding">
												<div className="bold">
													<Translate alias="Forms" word="Region B2" />
													<div style={{ display: "flex" }}>
														<div
															className='strategyPaperEconomyEliteCheckboxes economy'
															onClick={ () => checkboxPressed("productionVolume", "RegionB2Economy") }
														>
															{
																(formState.StrategyPaper.productionVolume.RegionB2Economy) ?
																	<FaCheck />
																	:
																	null
															}
														</div>
														<div 
															className='strategyPaperEconomyEliteCheckboxes elite'
															onClick={ () => checkboxPressed("productionVolume", "RegionB2Elite") }
														>
															{
																(formState.StrategyPaper.productionVolume.RegionB2Elite) ?
																	<FaCheck />
																	:
																	null
															}
														</div>
														<div style={{ width: 70, height: 25 }}>
															<FormControl 
																type="number"
																aria-label="Small" 
																size="sm" 
																style={{ height: 25 }}
																value={ formState.StrategyPaper.productionVolume.RegionB2 } 
																onChange={ (e) => inputChanged("productionVolume", "RegionB2", e.target.value) }
																min={ 0 }
																max={ 10 }
																//disabled={ !this.props.status }
															/>
														</div>
													</div>
												</div>
											</Col>
										</Row>
									</Container>
								</td>
							</tr>
							<tr>
								<td style={{ width: "100px" }}>
									<div className="bold">
										<Translate alias="Forms" word="Liabilities" />
									</div>
									<div className="hintForm">
										<Translate alias="Forms" word="+Take up/-Repay" />
									</div>
								</td>
								<td>
									<Container>
										<Row>
											<Col md="12" className="removeLeftPadding">
												<div className="bold">
													<span style={{ display: "flex", alignItems: "center" }}>
														+/-
														<FormControl 
															aria-label="Small" 
															size="sm" 
															value={ formState.StrategyPaper.liabilities.value } 
															onChange={ (e) => inputChanged("liabilities", "value", e.target.value) }
															//disabled={ !this.props.status }
														/>
													</span>
												</div>
											</Col>
										</Row>
									</Container>
								</td>
							</tr>
						</tbody>
					</Table>
				</Col>
			</Row>
		</Container>
	)
}

export default StrategyPaper;

/*class StrategyPaper extends React.Component {

	closeForm = () => {
		this.props.openFormView("")
	}

	checkboxPressed = (section, field) => {
		if (this.props.canEdit) {
			if (this.props.session.formStatus.strategyPaper) {
				if (this.props.session.year === this.props.session.formSelectedYear) {
					if (this.props.strategyPaper[section][field]) {
						this.props.updateFormOption("StrategyPaper", section, field, undefined, false)
			
						// Update Socket
						RealtimeConnection.updateForm({
							year: this.props.session.year,
							type: "StrategyPaper",
							section: section,
							field: field,
							value: false
						})
					}else{
						this.props.updateFormOption("StrategyPaper", section, field, undefined, true)
			
						// Update Socket
						RealtimeConnection.updateForm({
							year: this.props.session.year,
							type: "StrategyPaper",
							section: section,
							field: field,
							value: true
						})
					}
				}
			}
		}
	}

	inputChanged = (field, value) => {
		this.props.updateFormOption("StrategyPaper", "credits", field, undefined, value)

		// Update Socket
		RealtimeConnection.updateForm({
			year: this.props.session.year,
			type: "StrategyPaper",
			section: "credits",
			field: field,
			value: value
		})
	}

	optionChanged = (section, field, option, value) => {
		this.props.updateFormOption("StrategyPaper", section, field, option, value)

		// Update Socket
		RealtimeConnection.updateForm({
			year: this.props.session.year,
			type: "StrategyPaper",
			section: section,
			field: field,
			option: option,
			value: value
		})
	}

	changeFormYear = (selectedYear) => {
		this.props.changeFormYear(selectedYear);

		RealtimeConnection.formChangeYear({
			year: selectedYear,
			type: "StrategyPaper"
		})
	}

	render() {
		const selectedLang = this.props.session.selectedLang;

		let yearsOptions = [];
		for(let i = 0; i <= this.props.session.year; i++) {
			yearsOptions.push(<Dropdown.Item key={i} eventKey={i} onClick={ this.changeFormYear.bind(this, i) }>Year { i }</Dropdown.Item>)
		}

		let isCurrentYear = false;
		if (this.props.session.year === this.props.session.formSelectedYear) {
			isCurrentYear = true
		}

		if (!this.props.canEdit) {
			isCurrentYear = false
		}

		return (
			<Container className="formContainer">
				<Row>
					<Col>
						<div className="formHeader">
							<div className="headerTitle bold">
								<Translate alias="Forms" word="Strategy Paper" />
								<DropdownButton
									as={ButtonGroup}
									variant="outline-secondary"
									title={ `Year ${this.props.session.formSelectedYear}` }
									size="sm"
									style={{ marginLeft: "10px" }}
								>
									{ yearsOptions }
								</DropdownButton>
							</div>
							<div className="closeForm" onClick={ this.closeForm.bind(this) }>X</div>
						</div>
					</Col>
				</Row>
				<Row>
					<Col>
						<Table bordered>
							<tbody>
								<tr>
									<td style={{ width: "100px" }}>
										<div className="bold">
											<Translate alias="Forms" word="Development of products" />
										</div>
									</td>
									<td>
										<Container>
											<Row>
												<Col md="7" className="removeLeftPadding">
													<div className="bold">
														<Translate alias="Forms" word="Compact" />
													</div>
													<div className="hintForm">2 <Translate alias="Forms" word="years" /> <Translate alias="Forms" word="costs" /> 6 (3/3)</div>
												</Col>
												<Col md="5">
													<div className="checkContainer" onClick={ this.checkboxPressed.bind(this, "developmentOfProducts", "compact")}>
														{
															(this.props.strategyPaper.developmentOfProducts.compact) ?
																<FaCheck />
															:
																null
														}
													</div>
												</Col>
											</Row>
											<Row>
												<Col md="7" className="removeLeftPadding">
													<div className="bold">
														<Translate alias="Forms" word="Plus" />
													</div>
													<div className="hintForm">3 <Translate alias="Forms" word="years" /> <Translate alias="Forms" word="costs" /> 8 (3/3/2)</div>
												</Col>
												<Col md="5">
													<div className="checkContainer" onClick={ this.checkboxPressed.bind(this, "developmentOfProducts", "plus")}>
														{
															(this.props.strategyPaper.developmentOfProducts.plus) ?
																<FaCheck />
															:
																null
														}
													</div>
												</Col>
											</Row>
											<Row>
												<Col md="7" className="removeLeftPadding">
													<div className="bold"><Translate alias="Forms" word="Luxus" /></div>
													<div className="hintForm">5 <Translate alias="Forms" word="years" /> <Translate alias="Forms" word="costs" /> 10 (2/2/2/2/2)</div>
												</Col>
												<Col md="5">
													<div className="checkContainer" onClick={ this.checkboxPressed.bind(this, "developmentOfProducts", "luxus")}>
														{
															(this.props.strategyPaper.developmentOfProducts.luxus) ?
																<FaCheck />
															:
																null
														}
													</div>
												</Col>
											</Row>
										</Container>
									</td>
								</tr>
								<tr>
									<td>
										<div className="bold">
											<Translate alias="Forms" word="Opening-up of markets" />
										</div>
									</td>
									<td>
										<Container>
											<Row>
												<Col md="7" className="removeLeftPadding">
													<div className="bold">
														<Translate alias="Forms" word="International" />
													</div>
													<div className="hintForm">1 <Translate alias="Forms" word="year" /> <Translate alias="Forms" word="costs" /> 2 (2)</div>
												</Col>
												<Col md="5">
													<div className="checkContainer" onClick={ this.checkboxPressed.bind(this, "openingMarket", "international") }>
														{
															(this.props.strategyPaper.openingMarket.international) ?
																<FaCheck />
															:
																null
														}
													</div>
												</Col>
											</Row>
											<Row>
												<Col md="7" className="removeLeftPadding">
													<div className="bold"><Translate alias="Forms" word="Intercontinental 1" /></div>
													<div className="hintForm">2 <Translate alias="Forms" word="years" /> <Translate alias="Forms" word="costs" /> 4 (2/2)</div>
												</Col>
												<Col md="5">
													<div className="checkContainer" onClick={ this.checkboxPressed.bind(this, "openingMarket", "intercontinental1") }>
														{
															(this.props.strategyPaper.openingMarket.intercontinental1) ?
																<FaCheck />
															:
																null
														}
													</div>
												</Col>
											</Row>
											<Row>
												<Col md="7" className="removeLeftPadding">
													<div className="bold"><Translate alias="Forms" word="Intercontinental 2" /></div>
													<div className="hintForm">4 <Translate alias="Forms" word="years" /> <Translate alias="Forms" word="costs" /> 8 (2/2/2/2)</div>
												</Col>
												<Col md="5">
													<div className="checkContainer" onClick={ this.checkboxPressed.bind(this, "openingMarket", "intercontinental2") }>
														{
															(this.props.strategyPaper.openingMarket.intercontinental2) ?
																<FaCheck />
															:
																null
														}
													</div>
												</Col>
											</Row>
										</Container>
									</td>
								</tr>
								<tr>
									<td>
										<div className="bold"><Translate alias="Forms" word="Fixed assets" /></div>
										<div className="hintForm">- <Translate alias="Forms" word="Prod. facilities" />?</div>
										<div className="hintForm">- <Translate alias="Forms" word="Purchase" />/<Translate alias="Forms" word="Rent" />?</div>
										<div className="hintForm">- <Translate alias="Forms" word="Machines" />?</div>
										<div className="hintForm">&nbsp; <span className="capitalizeLetter"><Translate alias="Forms" word="f" /></span> - <Translate alias="Forms" word="Fast" /> 10</div>
										<div className="hintForm">&nbsp; <span className="capitalizeLetter"><Translate alias="Forms" word="m" /></span> - <Translate alias="Forms" word="Medium" /> 7.5</div>
										<div className="hintForm">&nbsp; <span className="capitalizeLetter"><Translate alias="Forms" word="s" /></span> - <Translate alias="Forms" word="Slow" /> 5</div>
									</td>
									<td>
										<Container>
											<FixedAsset
												option="national_1"
												optionChanged={ this.optionChanged }
												field={ this.props.strategyPaper.fixedAssets.national_1 } 
												index={ 0 } 
												selectedLang={ selectedLang }
												status={ this.props.session.formStatus.strategyPaper && isCurrentYear }
											/>
											<FixedAsset 
												option="national_2"
												optionChanged={ this.optionChanged }
												field={ this.props.strategyPaper.fixedAssets.national_2 } 
												index={ 1 } 
												selectedLang={ selectedLang }
												status={ this.props.session.formStatus.strategyPaper && isCurrentYear }
											/>
											<FixedAsset 
												option="national_3"
												optionChanged={ this.optionChanged }
												field={ this.props.strategyPaper.fixedAssets.national_3 } 
												index={ 2 } 
												selectedLang={ selectedLang }
												status={ this.props.session.formStatus.strategyPaper && isCurrentYear }
											/>
											<FixedAsset 
												option="international_1"
												optionChanged={ this.optionChanged }
												field={ this.props.strategyPaper.fixedAssets.international_1 } 
												index={ 3 } 
												selectedLang={ selectedLang }
												status={ this.props.session.formStatus.strategyPaper && isCurrentYear }
											/>
											<FixedAsset 
												option="international_2"
												optionChanged={ this.optionChanged }
												field={ this.props.strategyPaper.fixedAssets.international_2 } 
												index={ 4 } 
												selectedLang={ selectedLang }
												status={ this.props.session.formStatus.strategyPaper && isCurrentYear }
											/>
											<FixedAsset 
												option="international_3"
												optionChanged={ this.optionChanged }
												field={ this.props.strategyPaper.fixedAssets.international_3 } 
												index={ 5 } 
												selectedLang={ selectedLang }
												status={ this.props.session.formStatus.strategyPaper && isCurrentYear }
											/>
											<FixedAsset 
												option="intercontinental1_1"
												optionChanged={ this.optionChanged }
												field={ this.props.strategyPaper.fixedAssets.intercontinental1_1 } 
												index={ 6 } 
												selectedLang={ selectedLang }
												status={ this.props.session.formStatus.strategyPaper && isCurrentYear }
											/>
											<FixedAsset 
												option="intercontinental1_2"
												optionChanged={ this.optionChanged }
												field={ this.props.strategyPaper.fixedAssets.intercontinental1_2 } 
												index={ 7 } 
												selectedLang={ selectedLang }
												status={ this.props.session.formStatus.strategyPaper && isCurrentYear }
											/>
											<FixedAsset 
												option="intercontinental2_1"
												optionChanged={ this.optionChanged }
												field={ this.props.strategyPaper.fixedAssets.intercontinental2_1 } 
												index={ 8 } 
												selectedLang={ selectedLang }
												status={ this.props.session.formStatus.strategyPaper && isCurrentYear }
											/>
											<FixedAsset 
												option="intercontinental2_2"
												optionChanged={ this.optionChanged }
												field={ this.props.strategyPaper.fixedAssets.intercontinental2_2 } 
												index={ 9 } 
												selectedLang={ selectedLang }
												status={ this.props.session.formStatus.strategyPaper && isCurrentYear }
											/>
										</Container>
									</td>
								</tr>
								<tr>
									<td>
										<div className="bold"><Translate alias="Forms" word="Production in Prod. facility" /></div>
										<div className="hintForm">- <Translate alias="Forms" word="Product" />?</div>
										<div className="hintForm">- <Translate alias="Forms" word="Quantity" />?</div>
									</td>
									<td>
										<StrategyPaperProduction
											option="national_1"
											optionChanged={ this.optionChanged }
											field={ this.props.strategyPaper.production.national_1 } 
											index={ 0 } 
											selectedLang={ selectedLang }
											status={ this.props.session.formStatus.strategyPaper && isCurrentYear }
										/>
										<StrategyPaperProduction 
											option="national_2"
											optionChanged={ this.optionChanged }
											field={ this.props.strategyPaper.production.national_2 } 
											index={ 1 } 
											selectedLang={ selectedLang }
											status={ this.props.session.formStatus.strategyPaper && isCurrentYear }
										/>
										<StrategyPaperProduction 
											option="national_3"
											optionChanged={ this.optionChanged }
											field={ this.props.strategyPaper.production.national_3 } 
											index={ 2 } 
											selectedLang={ selectedLang }
											status={ this.props.session.formStatus.strategyPaper && isCurrentYear }
										/>
										<StrategyPaperProduction 
											option="international_1"
											optionChanged={ this.optionChanged }
											field={ this.props.strategyPaper.production.international_1 } 
											index={ 3 } 
											selectedLang={ selectedLang }
											status={ this.props.session.formStatus.strategyPaper && isCurrentYear }
										/>
										<StrategyPaperProduction 
											option="international_2"
											optionChanged={ this.optionChanged }
											field={ this.props.strategyPaper.production.international_2 } 
											index={ 4 } 
											selectedLang={ selectedLang }
											status={ this.props.session.formStatus.strategyPaper && isCurrentYear }
										/>
										<StrategyPaperProduction 
											option="international_3"
											optionChanged={ this.optionChanged }
											field={ this.props.strategyPaper.production.international_3 } 
											index={ 5 } 
											selectedLang={ selectedLang }
											status={ this.props.session.formStatus.strategyPaper && isCurrentYear }
										/>
										<StrategyPaperProduction 
											option="intercontinental1_1"
											optionChanged={ this.optionChanged }
											field={ this.props.strategyPaper.production.intercontinental1_1 } 
											index={ 6 } 
											selectedLang={ selectedLang }
											status={ this.props.session.formStatus.strategyPaper && isCurrentYear }
										/>
										<StrategyPaperProduction 
											option="intercontinental1_2"
											optionChanged={ this.optionChanged }
											field={ this.props.strategyPaper.production.intercontinental1_2 } 
											index={ 7 } 
											selectedLang={ selectedLang }
											status={ this.props.session.formStatus.strategyPaper && isCurrentYear }
										/>
										<StrategyPaperProduction 
											option="intercontinental2_1"
											optionChanged={ this.optionChanged }
											field={ this.props.strategyPaper.production.intercontinental2_1 } 
											index={ 8 } 
											selectedLang={ selectedLang }
											status={ this.props.session.formStatus.strategyPaper && isCurrentYear }
										/>
										<StrategyPaperProduction 
											option="intercontinental2_2"
											optionChanged={ this.optionChanged }
											field={ this.props.strategyPaper.production.intercontinental2_2 } 
											index={ 9 } 
											selectedLang={ selectedLang }
											status={ this.props.session.formStatus.strategyPaper && isCurrentYear }
										/>
									</td>
								</tr>
								<tr>
									<td>
										<div className="bold">
											<Translate alias="Forms" word="Credit take-up" />
										</div>
									</td>
									<td>
										<Container>
											<Row>
												<Col>
													<div className="bold"><Translate alias="Forms" word="Credit status 5%" /></div>
												</Col>
												<Col>
													<StrategyPaperFormControl
														inputChanged={ this.inputChanged }
														field={ "nonCurrent" }
														value={ this.props.strategyPaper.credits.nonCurrent }
														status={ this.props.session.formStatus.strategyPaper && isCurrentYear }
													/>
												</Col>
											</Row>
											<Row>
												<Col>
													<div className="bold"><Translate alias="Forms" word="Credit status 10%" /></div>
												</Col>
												<Col>
													<StrategyPaperFormControl
														inputChanged={ this.inputChanged }
														field={ "current" }
														value={ this.props.strategyPaper.credits.current }
														status={ this.props.session.formStatus.strategyPaper && isCurrentYear }
													/>
												</Col>
											</Row>
											<Row>
												<Col>
													<div className="bold"><Translate alias="Forms" word="Credit take-up/repayment" /></div>
												</Col>
												<Col>
													<StrategyPaperFormControl
														inputChanged={ this.inputChanged }
														field={ "takeUpCredit" }
														value={ this.props.strategyPaper.credits.takeUpCredit }
														status={ this.props.session.formStatus.strategyPaper && isCurrentYear }
													/>
												</Col>
											</Row>
											<Row>
												<Col>
													<div className="bold"><Translate alias="Forms" word="New credit status" /></div>
												</Col>
												<Col>
													<StrategyPaperFormControl
														inputChanged={ this.inputChanged }
														field={ "creditStatus" }
														value={ this.props.strategyPaper.credits.creditStatus }
														status={ this.props.session.formStatus.strategyPaper && isCurrentYear }
													/>
												</Col>
											</Row>
										</Container>
									</td>
								</tr>
							</tbody>
						</Table>
					</Col>
				</Row>
			</Container>
		);
	}

}

const mapStateToProps = (state) => {
	return {
		session: state.session,
		strategyPaper: state.form.StrategyPaper
	}
}
export default connect(mapStateToProps, mapDispatchToProps)(StrategyPaper);
*/
//value={ this.props.strategyPaper.credits.nonCurrent }