const sessionState = {
	year: 0,
	teamName: "",
	timer: {},
	files: [],
	isChatOpened: false,
	messages: [],
	unreadCount: 0,
	checkList: {},
	openedForm: "",
	selectedLang: "3",
	formSelectedYear: 0,
	formStatus: {
		decisionSheet: true,
		strategyPaper: true,
		annualReport: true
	},
	localizations: {}
}

export default sessionState;