import React, { useState, useEffect } from "react";
import { Container, Row, Image } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
	addCoin,
	addContainer,
	openContainerProperties,
	removeCoin,
	removeContainer,
} from "../../Redux/dispatchers";
import * as RealtimeConnection from "../RealtimeConnection";

// Images
import leftArrow from "../../assets/images/left-arrow.png";
import DroppableField from "../Common/DroppableField";
import dropFieldClassNames from "../../utils/dropFieldClassNames";
import Translate from "../../utils/Translate";

export default function LeftBlock(props) {
	const dispatch = useDispatch();
	const [canEdit, setCanEdit] = useState(false);
	const [blockId, setBlockId] = useState(0);
	const [blockTitle, setBlockTitle] = useState("");

	const { State } = useSelector((state) => ({
		State: state.board.LeftBlock,
	}));
	useEffect(() => {
		setCanEdit(props.canEdit);
	}, [props.canEdit]);

	useEffect(() => {
		setBlockId(props.id);
	}, [props.id]);

	useEffect(() => {
		setBlockTitle(props.title);
	}, [props.title]);

	const appendContainer = (coins) => {
		// Update Socket
		RealtimeConnection.addContainer("LeftBlock", {
			id: blockId,
			coins: coins,
		});
	};

	const removeContainerHandler = (isDropToDelete) => {
		// Update Socket
		RealtimeConnection.removeContainer("LeftBlock", {
			id: blockId,
			isDropToDelete: isDropToDelete,
		});
	};

	const addCoinHandler = (id, coinValue) => {
		console.log("Add Coin Handler")
		console.log(coinValue)
		RealtimeConnection.addCoin("LeftBlock", {
			id: blockId,
			value: coinValue,
		});
	};

	const removeCoinHandler = (index, isDropToDelete) => {
		// Update Socket
		RealtimeConnection.removeCoin("LeftBlock", {
			id: blockId,
			index: index,
			isDropToDelete: isDropToDelete,
		});
	};

	const isDraggingHandler = (type, isDragging, data) => {
		let dataObj = {
			teamId: window.teamId,
			sessionId: window.sessionId,
			playerId: window.playerId,
			playerName: window.playerName,
			alias: "LeftBlock",
			isDragging: isDragging,
			type: type,
			id: blockId,
		};
		dataObj = { ...data, ...dataObj };

		window.socket.emit("item-dragging", dataObj);
	};

	const showContainerProperties = () => {
		dispatch(openContainerProperties("FIELD", "LeftBlock", blockId));
	};

	return (
		<Container className="left-right-block Left-block">
			<Row>
				<div className="field-column" onClick={showContainerProperties}>
					<DroppableField
						canEdit={canEdit}
						class={dropFieldClassNames.LeftBlock}
						data={State[blockId]}
						isHorizontal={false}
						appendContainer={appendContainer}
						removeContainer={removeContainerHandler}
						addCoin={addCoinHandler}
						removeCoin={removeCoinHandler}
						isDraggingHandler={isDraggingHandler}
					/>
				</div>
				<div className="text-column">
					<p className="Text-bold-size">
						<Translate alias="Board" word={ blockTitle } />
					</p>
				</div>
			</Row>
			<Image src={leftArrow} className="Arrow-class" />
		</Container>
	);
}
