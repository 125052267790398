import React from "react";
import { useDrag, DragPreviewImage, useDrop } from "react-dnd";
import { Image } from "react-bootstrap";

// Constants
import items from "../../../utils/items";

// Components
import DraggableCoin from "./SalesDraggableCoin";

// Images
import previewImg from "../../../assets/images/draggable-container-preview.png";
import horizontalContainerImg from "../../../assets/images/container-h.png";
import verticalContainerImg from "../../../assets/images/container-v.png";
import flipContainerImg from "../../../assets/images/box-container-v-flipped.png";
import machineCardImg from "../../../assets/images/machine-img-h.png";
import verticalMachineCardImg from "../../../assets/images/machine-img-v.png";
import factoryCardImg from "../../../assets/images/factory-img-h.png";
import verticalFactoryCardImg from "../../../assets/images/factory-img-v.png";
import flippedFactoryCard from "../../../assets/images/factory-card.png";

import tilledGreen from "../../../assets/images/tiled-green-card.png";
import tilledYellow from "../../../assets/images/tiled-yellow-card.png";
import tilledBlue from "../../../assets/images/tiled-blue-card.png";
import tilledRed from "../../../assets/images/tiled-red-card.png";

let numberOfUpdates = 0;

export default function SalesDraggableContainer(props) {
	const [{ isDragging }, drag, preview] = useDrag({
		item: {
			type: items.stockContainer,
			coins: props.data.coins,
			hasCard: props.data.hasCard,
			cardValue: props.data.cardValue,
			cardColor: props.data.cardColor,
			id: props.id,
			pinId: props.pinId
		},
		collect: (monitor) => ({
			isDragging: !!monitor.isDragging(),
		}),
		end: (dropResult, monitor) => {
			let isDropToDelete = false;

			const doppableItem = monitor.getDropResult();
			if (doppableItem !== null) {
				isDropToDelete = doppableItem.droppableType === "delete" ? true : false;
			}

			if (monitor.didDrop()) {
				if (props.productionType) {
					let droppedItem = monitor.getDropResult();
					if (droppedItem !== null && droppedItem.type !== undefined) {
						props.removeContainer(
							props.productionType,
							props.id,
							isDropToDelete,props.kind
						);
					}
				} else {
					props.id !== undefined
						? props.removeContainer(props.id, isDropToDelete,props.kind)
						: props.removeContainer(isDropToDelete,props.kind);
				}

				numberOfUpdates = 0;
				// Update Dragging Status
				let dragObjData = {};
				if (props.id !== undefined) dragObjData.id = props.id;
				if (props.productionType !== undefined)
					dragObjData.fieldType = props.productionType;

				props.isDraggingHandler("CONTAINER", false, dragObjData);
			}
		},
	});

	const coinsContainerClass = props.isHorizontal
		? "Coins-droppable"
		: "Coins-vertical-droppable";
	const containerImg = props.isHorizontal
		? horizontalContainerImg
		: verticalContainerImg;
	const factoryImg = props.isHorizontal ? factoryCardImg : verticalFactoryCardImg;
	const machineImg = props.isHorizontal
		? machineCardImg
		: verticalMachineCardImg;

	let dragClass = "";
	if (isDragging) {
		dragClass = "dragging";

		if (numberOfUpdates === 0) {
			numberOfUpdates = 1;

			let dragObjData = {};
			if (props.id !== undefined) {
				dragObjData.id = props.id;
			}
			if (props.productionType !== undefined) {
				dragObjData.fieldType = props.productionType;
			}
			props.isDraggingHandler("CONTAINER", isDragging, dragObjData, props.kind);
		}
	} else if (props.data.isDragging && props.data.type === "CONTAINER") {
		dragClass = "dragging";

		if (props.data.playerId === window.playerId) {
			if (numberOfUpdates === 1) {
				numberOfUpdates = 0;

				let dragObjData = {};
				if (props.id !== undefined) {
					dragObjData.id = props.id;
				}
				if (props.productionType !== undefined) {
					dragObjData.fieldType = props.productionType;
				}
				props.isDraggingHandler("CONTAINER", isDragging, dragObjData);
			}
		}
	}

	return (
		<>
			<DragPreviewImage connect={preview} src={previewImg} />
				<div className={`containerObjClass ${dragClass}`} ref={drag}>
				<span className="helper"></span>
				<Image className="objModelClass" src={containerImg} />
				<div className={coinsContainerClass}>
					{props.isHorizontal ? <span className="helper"></span> : null}
					{props.data.coins.map((value, index) => (
						<DraggableCoin
							canEdit={props.canEdit}
							key={index}
							id={props.id}
							index={index}
							value={value}
							draggingType={props.data.type}
							coinDraggingIndex={
								props.data.coinsDraggingIndex !== undefined
									? props.data.coinsDraggingIndex
									: -1
							}
							playerId={props.data.playerId}
							isLastCoin={
								props.data.coins.length === index + 1 && (index + 1) % 5 === 0
									? true
									: false
							}
							isHorizontal={props.isHorizontal}
							productionType={props.productionType}
							removeCoin={props.removeCoin}
							isDraggingHandler={props.isDraggingHandler}
							kind={props.kind}
						/>
					))}
					{props.data.hasCard && props.data.cardType === "label" ? (
						<div className={ props.isHorizontal ? "containerVerticalProductCardDraggable" : "containerProductCardDraggable" }>
							{(props.data.cardColor === "economy" && (
								<Image
									className="containerProductCardDraggableImg"
									src={tilledGreen}
								/>
							)) ||
								(props.data.cardColor === "elite" && (
									<Image
										className="containerProductCardDraggableImg"
										src={tilledRed}
									/>
								))}
							{props.data.cardValue > 0 ? (
								<div className="containerProductCardValue">
									{props.data.cardValue}
								</div>
							) : null}
						</div>
					) : null}
				</div>
			</div>
		</>
	);
}

/*
isHorizontal
<x-model class={props.objectClasses} src="/models/test-box.glb"></x-model>
<x-model class={props.objectClasses} src="/models/Box3-3.glb"></x-model>
 */
