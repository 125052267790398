import React, {useEffect, useState} from "react";
import { Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import DroppableField from "../../Common/DroppableField";

import * as RealtimeConnection from "../../RealtimeConnection";
import dropFieldClassNames from "../../../utils/dropFieldClassNames";

export default function PurchaseRow({className, rowKey, canEdit}) {
	const dispatch = useDispatch()
	
	const state = useSelector((state) => state.board.CenterBlock.Purchase);

	const addCoinHandler = (id, coinValue) => {
		// dispatch(addCoin(kind, id, coinValue, 200, "ahmed"));
		RealtimeConnection.addCoin("Purchase", {
		  id: id,
		  value: coinValue,
		  fieldType: rowKey
		});
	};

	const removeCoinHandler = (id, index, isDropToDelete) => {
		// Update Socket
		RealtimeConnection.removeCoin('Purchase', {
			id: id,
			index: index,
			fieldType: rowKey,
			isDropToDelete: isDropToDelete
		});
	};

	const appendContainer = (coins, id) => {
		RealtimeConnection.addContainer("Purchase", {
			id: id,
			coins: coins,
			fieldType: rowKey
		});
	};

	const isDraggingHandler = (type, isDragging, data) => {
		let dataObj = {
			teamId: window.teamId,
			sessionId: window.sessionId,
			playerId: window.playerId,
			playerName: window.playerName,
			alias: "Purchase",
			fieldType: rowKey,
			isDragging: isDragging,
			type: type,
		};
		dataObj = { ...dataObj, ...data };

		window.socket.emit("item-dragging", dataObj);
	};
	
	const removeContainerHandler = (id, isDropToDelete) => {
		// Update Socket
		RealtimeConnection.removeContainer("Purchase", {
			id: id,
			isDropToDelete: isDropToDelete,
			fieldType: rowKey,
		});
	};

	const showContainerProperties = (id) => {
		dispatch({type: "PROPERTIES", alias: "FIELD", options: { fieldName: "Purchase", title: rowKey, id: id }})
	}

	return (
		<Row className={`Purchase-row ${ className }`}>
			<Col onClick={ () => showContainerProperties(0) }>
				<DroppableField
					id={0}
					canEdit={ canEdit }
					class={dropFieldClassNames.Finance}
					isHorizontal={true}
					data={state[rowKey][0]}
					appendContainer={appendContainer}
					addCoin={addCoinHandler}
					removeCoin={removeCoinHandler}
					kind={rowKey}
					design={"dim"}
					removeContainer={removeContainerHandler}
					isDraggingHandler={isDraggingHandler}
				/>
			</Col>
			<Col className="ml-1" onClick={ () => showContainerProperties(1) }>
				<DroppableField
					id={1}
					canEdit={ canEdit }
					class={dropFieldClassNames.Finance}
					isHorizontal={true}
					data={state[rowKey][1]}
					appendContainer={appendContainer}
					addCoin={addCoinHandler}
					removeCoin={removeCoinHandler}
					kind={rowKey}
					design={"dim"}
					removeContainer={removeContainerHandler}
					isDraggingHandler={isDraggingHandler}
				/>
			</Col>
		</Row>
	)
}
