import React, { useEffect, useState } from "react";
import { applyMiddleware, createStore, compose } from "redux";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import { Image } from "react-bootstrap";

import AppProvider from "./AppProvider";
import reducer from "./Redux/index";
import axios from "axios";
import urls from "./utils/urls";

import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/SimCompact/Style3dModel.css";
import "./assets/css/SimCompact/Zoom.css";
import "./assets/css/PropertiesPanel.css";
import "rc-tooltip/assets/bootstrap.css";

import loaderImg from "./assets/images/loader.gif";
import HomeComponent from "./HomeComponent";

//import './assets/css/SimCompact/ScreenSize.css';
// import 'rc-tooltip/assets/bootstrap.css';

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(reducer, composeEnhancer(applyMiddleware(thunk)));

function findGetParameter(parameterName) {
  let result = null,
    tmp = [];
  let items = window.location.search.substr(1).split("&");
  for (let index = 0; index < items.length; index++) {
    tmp = items[index].split("=");
    if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
  }
  return result;
}

window.sessionId = parseInt(findGetParameter("session_id"));
window.playerId = parseInt(findGetParameter("user_id"));
window.adminId = parseInt(findGetParameter("admin_id"));
window.teamId = parseInt(findGetParameter("team_id"));
window.token = findGetParameter("token");

function App() {
  const [state, setState] = useState({
    loading: true,
    isAuthorized: false,
    notAuthorizedMessage: "",
    canEdit: false,
    isLoggedOut: false,
    teamName: "",
    languages: [],
  });
  const fetchAuthenticate = async () => {
    const result = await axios.post(
      urls.authenticate,
      {
        playerId: !isNaN(window.playerId) ? window.playerId : 0,
        adminId: !isNaN(window.adminId) ? window.adminId : 0,
        teamId: window.teamId,
        token: window.token,
        sessionId: window.sessionId,
        langId: localStorage.langId,
      },
      { headers: { "content-type": "application/json" } }
    );

    console.log(result.data);

    if (result.data.success) {
      let response = result.data;
      if (!isNaN(window.adminId)) {
        window.playerId = window.adminId;
        window.playerName =
          response.player.firstName + " " + response.player.lastName;
      } else {
        window.playerName = response.player.name;
      }

      window.staticWords = response.localizations.staticWords;

      setState((prevState) => {
        return {
          ...prevState,
          loading: false,
          isAuthorized: true,
          languages: response.languages,
          teamName: response.teamData
            ? response.teamData.name
            : response.player.Teams[0].name,
          canEdit: response.canEdit, //response.data.can_edit,
          isAdmin: !isNaN(window.adminId) ? true : false, //response.data.is_admin,
        };
      });
    } else {
      setState((prevState) => {
        return {
          ...prevState,
          loading: false,
          isAuthorized: false,
          notAuthorizedMessage: result.data.message,
        };
      });
    }
  };

  useEffect(() => {
    fetchAuthenticate();
  }, []);

  const logout = () => {
    setState((prevState) => {
      return {
        ...prevState,

        loading: true,
        isAuthorized: false,
        canEdit: false,
      };
    });
    logoutSession();
  };

  const logoutSession = async () => {
    const result = await axios.post(urls.logout, {
      user_id: window.playerId,
      token: window.token,
    });

    let response = result.data;
    if (response.status === 200) {
      this.setState({ loading: false, isLoggedOut: true });
    } else {
      window.location.reload(false);
    }
  };

  return (
    <Provider store={store}>
      {state.isAuthorized ? (
        <AppProvider
          canEdit={state.canEdit}
          isAdmin={state.isAdmin}
          logoutAction={logout}
          teamName={state.teamName}
          languages={state.languages}
        />
      ) : (
        <div className="AuthorizationContainer">
          <div className="relativeContainer">
            {state.loading ? (
              <div className="NotAuthorizedContainerLoaderImg">
                <div className="loaderContainer">
                  <Image src={loaderImg} />
                </div>
              </div>
            ) : (
              <HomeComponent
                isLoggedOut={state.isLoggedOut}
                notAuthorizedMessage={state.notAuthorizedMessage}
              />
            )}
          </div>
        </div>
      )}
    </Provider>
  );
}

export default App;
