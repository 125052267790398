import React, { useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

// Images
import documentIcon from "../assets/images/document-icon.png";
import checkListIcon from "../assets/images/checklist-icon.png";

import Translate from "../utils/Translate"
import URLs from "../utils/urls"

// Components
import DecisionSheet from "./Forms/DecisionSheet";
import StrategyPaper from "./Forms/StrategyPaper";
import AnnualReport from "./Forms/AnnualReport";


const FilesLibrary = ({ checklist }) => {
	const dispatch = useDispatch()
	const sessionState = useSelector(state => state.session)

	const [isMenuOpened, setIsMenuOpened] = useState(false)
	const [isCheckListOpened, setIsCheckListOpened] = useState(false)
	const [canEdit, setCanEdit] = useState(true)
	const [files, setFiles] = useState([])
	const [links, setLinks] = useState([])


	useEffect(() => {
		let filesList = [];
		let linksList = [];
		sessionState.files.forEach(obj => {
			if (obj.type === "file") {
				filesList.push(obj);
			}else{
				linksList.push(obj);
			}
		})

		setFiles(filesList)
		setLinks(linksList)
	}, [sessionState])

	const toggleFilesMenu = () => {
		if (isMenuOpened) {
			setIsMenuOpened(false)
			updateUI("FILES", "close");
		}else{
			setIsMenuOpened(true)
			setIsCheckListOpened(false)
			updateUI("FILES", "open");
		}
	}

	const toggleCheckListMenu = () => {
		if (isCheckListOpened) {
			setIsCheckListOpened(false)
			updateUI("CHECKLIST", "close");
		}else{
			setIsCheckListOpened(true)
			setIsMenuOpened(false)
			updateUI("CHECKLIST", "open");
		}
	}

	const updateUI = (panelType, status) => {
		const appParentContainer = document.getElementsByClassName("main-app-bg");
		const appContainer = document.getElementsByClassName("App");
		const filesContainer = document.getElementsByClassName("filesContainer");
		const checkListContainer = document.getElementsByClassName("checkListContainer");

		if (status === "close") {
			appContainer[0].classList.remove('moveRight');
			filesContainer[0].classList.remove('fullWidth');
			checkListContainer[0].classList.remove('fullWidth');
		}else{
			if (panelType === "FILES") {
				//appParentContainer[0].classList.add("width");
				appContainer[0].classList.add('moveRight');
				filesContainer[0].classList.add('fullWidth');
				checkListContainer[0].classList.remove('fullWidth');
			}else if (panelType === "CHECKLIST") {
				//appParentContainer[0].classList.add("width");
				appContainer[0].classList.add('moveRight');
				checkListContainer[0].classList.add('fullWidth');
				filesContainer[0].classList.remove('fullWidth');
			}else{
				setIsCheckListOpened(false)
				setIsMenuOpened(false)
				filesContainer[0].classList.remove('fullWidth');
				checkListContainer[0].classList.remove('fullWidth');
			}
		}
	}
		
	const openForm = (form) => {
		dispatch({ type: "OPEN_FORM", value: form})
	}

	return (
		<>
			<div className="files-library-btn">
				<div className="section" onClick={ toggleCheckListMenu }>
					<div className="button checklist">
						<Image src={checkListIcon} />
						<div className="text">
							<Translate alias="Board" word="Check List" />
						</div>
					</div>
				</div>
				<div className="section" onClick={ toggleFilesMenu }>
					<div className="button">
						<Image src={documentIcon} />
						<div className="text">
							<Translate alias="Board" word="Files Library" />
						</div>
					</div>
				</div>
			</div>
			<div className="checkListContainer">
				<div className="files-header">
					<div className="files-header-logo">
						<Image src={checkListIcon} />
						<div className="text">
							<Translate alias="Board" word="Check List" />
						</div>
					</div>
				</div>
				{ /* checklist */ }
				{ 
					checklist.map(checklistHeader => {
						const checklistItems = checklistHeader.SimcompactChecklists.sort((itemA, itemB) => {
							if (itemA.order > itemB.order) {
								return 1
							}else if (itemB.order > itemA.order) {
								return -1
							}else{
								return 0
							}
						})
						return (
							<div className="checkListRowContainer" key={checklistHeader.id}>
								<div className="checkListTitle">{checklistHeader.title}</div>
								{
									checklistItems.map(checkListObj => {
										return (
											<div className="checkListRow" key={checkListObj.id}>
												<div className="checkListSubtitle">
													{ checkListObj.title }
												</div>
												{ /*<div className="checkListValue">
													<div dangerouslySetInnerHTML={{ __html: checkListObj.subtitle }} />
												</div>*/ }
												<div dangerouslySetInnerHTML={{ __html: checkListObj.subtitle }} />
												<div dangerouslySetInnerHTML={{ __html: checkListObj.description }} />
												<div className="kits-separator" />
											</div>
										);
									})
								}
							</div>
						);
					})
				}
			</div>
			<div className="filesContainer">
				<div className="files-header">
					<div className="files-header-logo">
						<Image src={documentIcon} />
						<div className="text">
							<Translate alias="Board" word="Files Library" />
						</div>
					</div>
				</div>
				
				<div className="blur">
					{
						(sessionState.openedForm !== "") ?
							<>
								{(
									(sessionState.openedForm === "DecisionSheet" && <DecisionSheet canEdit={ canEdit } />)
									|| 
									(sessionState.openedForm === "StrategyPaper" && <StrategyPaper canEdit={ canEdit } />)
									||
									(sessionState.openedForm === "AnnualReport" && <AnnualReport canEdit={ canEdit } />)
								)}
							</>
						:
							<>
								<div className="files-section">
									<div className="filesListTitle">
										<Translate alias="Forms" word="Forms" />
									</div>
									<div className="fileRow">
										<div className="file-link form-link">
											<div onClick={ () => openForm("DecisionSheet") }>
												<Translate alias="Forms" word="Decision Sheet" />
											</div>
										</div>
									</div>
									<div className="fileRow">
										<div className="file-link form-link">
											<div href="#" onClick={ () => openForm("StrategyPaper") } rel="noopener noreferrer">
												<Translate alias="Forms" word="Strategy Paper" />
											</div>
										</div>
									</div>
									<div className="fileRow">
										<div className="file-link form-link">
											<div href="#" onClick={ () => openForm("AnnualReport") } rel="noopener noreferrer">
												<Translate alias="Forms" word="Annual Report" />
											</div>
										</div>
									</div>
								</div>
								{
									(files.length > 0) ?
										<div className="files-section">
											<div className="filesListTitle">
												<Translate alias="Board" word="Files" />
											</div>
											{
												files.map((obj, index) => {
													return (
														<div className="fileRow" key={ index }>
															<div className="file-link">
																<a href={ `${ URLs.backendURL }/uploads/sessions/images/${ obj.file }` } target="_blank" rel="noopener noreferrer">{ obj.name }</a>
															</div>
														</div>
													);
												})
											}
										</div>
										:
										null
								}
								{
									(links.length > 0) ?
										<div className="links-section">
											<div className="linksListTitle">
												<Translate alias="Board" word="Links" />
											</div>
											{
												links.map((obj, index) => {
													return (
														<div className="fileRow" key={ index }>
															<div className="file-link">
																<a href={ `${ obj.file }` } target="_blank" rel="noopener noreferrer">{ obj.name }</a>
															</div>
														</div>
													);
												})
											}
										</div>
										:
										null
								}
							</>
					}
				</div>
			</div>
		</>
	)
}
	

export default FilesLibrary