import React, { useState } from "react";
import { Container, Row, Col, Image } from "react-bootstrap"
import { useDrop } from "react-dnd";
import DraggableContainer from "./SalesDraggableContainer";
import Tooltip from "rc-tooltip";

// Constants
import colors from "../../../utils/colors";
import items from "../../../utils/items";

// Images
import productPlaceholder from "../../../assets/images/product-placeholder.png"

export default function SalesDroppableField(props) {
	const [isHover, setIsHover] = useState(false);

	const [{ isOver, canDrop }, drop] = useDrop({
		accept: [items.productionContainer],
		collect: (monitor) => ({
			isOver: monitor.isOver(),
			canDrop: monitor.canDrop(),
		}),
		drop: (item) => {
			props.appendContainer(item.coins, props.id, item.hasCard, item.cardValue, item.cardColor);
			return item;
		},
	});

	const backgroundColor = isOver && canDrop ? colors.activeBackground : "";
	const opacity = props.design ? 0.5 : 1;
	const canPlayerDrop = drop;

	let showTooltip = props.data.isDragging && props.data.type === "CONTAINER" ? true : false;
	if (!showTooltip) {
		if (isHover && props.data.playerId) {
			showTooltip = true;
		}
	} else if (props.data.playerId === window.playerId) {
		showTooltip = false;
	}
	return (
		<>
			{props.data.hasContainer ? (
				<div
					className={props.class}
					onMouseEnter={() => setIsHover(true)}
					onMouseLeave={() => setIsHover(false)}
				>
					<Tooltip
						placement="topRight"
						align={{
							offset: [0, 5],
						}}
						visible={showTooltip}
						overlay={<span>{props.data.playerName}</span>}
					>
						<DraggableContainer
							id={props.id}
							canEdit={props.canEdit}
							data={props.data}
							isHorizontal={props.isHorizontal}
							containerType={props.containerType}
							productionType={props.productionType}
							removeContainer={props.removeContainer}
							addCoin={props.addCoin}
							removeCoin={props.removeCoin}
							addCard={props.addCard}
							isDraggingHandler={props.isDraggingHandler}
							kind={props.kind}
							pinId={props.mainId}
						/>
					</Tooltip>
				</div>
			) : (
				<div
					className={props.class}
					style={{ backgroundColor: backgroundColor, opacity: opacity }}
					ref={canPlayerDrop}
				>
					{
						(props.productionType === "product" && <div className="field-content">
							<Container>
								<Row className="field-content-image-row product">
									<Col>
										<Image src={productPlaceholder} />
									</Col>
								</Row>
							</Container>
						</div>)
					}
				</div>
			)}
		</>
	);
}
