import React from "react";
import { useDrag, DragPreviewImage } from "react-dnd";
import { Col, Image } from "react-bootstrap";

// Constants
import items from "../../utils/items";

// Images
//import SmileFace from "../../assets/images/smile-face.jpg";
import YellowFace from "../../assets/images/faces/faceYellow.svg"

export default function MainDraggableSmileFace(props) {
	const [, drag, preview] = useDrag({
		item: { type: items.Smile, value: 1, count: props.count },
		collect: (monitor) => ({
			isDragging: !!monitor.isDragging(),
		}),
		end: (dropResult, monitor) => {
			let droppedItem = monitor.getDropResult();
			if (droppedItem !== null && droppedItem.type !== undefined) {
				if (monitor.didDrop() && props.count > 0) {
					props.decreaseStarterKit("smileFace", 1);
				}
			}
		},
	});

	return (
		<Col style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
			{/* <DragPreviewImage connect={preview} src={SmileFace} /> */}
			<div ref={drag}>
				<Image src={YellowFace} style={{ width: 40 }} />
			</div>
		</Col>
	);
}
