import React, { useEffect, useState } from "react";
import {Table, Col, Container, Row, Image, Button} from "react-bootstrap";
import {connect, useSelector} from "react-redux";

import Translate from "../utils/Translate"

// Dispatcher
import mapDispatchToProps from "../Redux/dispatchers";
import * as RealtimeConnection from "./RealtimeConnection";


import "../assets/css/PropertiesPanel.css";

// Images
import containerImg from "../assets/images/container-h.png";
import flippedContainerImg from "../assets/images/box-container-flipped.png";
import prevCoinImg from "../assets/images/dollar_coin.png";
import prevSilverCoinImg from "../assets/images/dollar_coin_silver.png";
import prevBronzeCoinImg from "../assets/images/dollar_coin_bronze.png";

import coinImg from "../assets/images/dollar_coin_h.png";
import coinSilverImg from "../assets/images/dollar_coin_silver_h.png";
import coinBronzeImg from "../assets/images/dollar_coin_bronze_h.png";
import facrotyImgTiles from "../assets/images/factory-img-h.png";
import machineImgTiles from "../assets/images/machine-img-h.png";
import facrotyImg from "../assets/images/factory-card.png";
import green from "../assets/images/tiled-green-card.png";
import yellow from "../assets/images/tiled-yellow-card.png";
import blue from "../assets/images/tiled-blue-card.png";
import red from "../assets/images/tiled-red-card.png";

//const formatNumber = new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' });
const formatNumber = new Intl.NumberFormat('en-US');
const fieldsWithTypeAliases = ["Research", "Purchase", "Production", "ColorField"];

const ContainerProperties = (props) => {

	const startingKitState = useSelector(state => state.startingKit)

	const [canEdit, setCanEdit] = useState(false)

	const [showInvestmentLine, setShowInvestmentLine] = useState(false)
	const [showAddCashQuestion, setShowAddCashQuestion] = useState(false)
	const [showSubtractCashQuestion, setShowSubtractCashQuestion] = useState(false)
	const [addCashValue, setAddCashValue] = useState(0)
	const [subtractCashValue, setSubtractCashValue] = useState(0)

	useEffect(() => {
		setCanEdit(props.canEdit)
	}, [props.canEdit])

	useEffect(() => {
		if (showAddCashQuestion) {
			setShowSubtractCashQuestion(false)
		}
	}, [showAddCashQuestion])

	useEffect(() => {
		if (showSubtractCashQuestion) {
			setShowAddCashQuestion(false)
		}
	}, [showSubtractCashQuestion])

	const addCoinsAction = (currentCoins) => {
		let cashValue = parseFloat(addCashValue);
		cashValue = Math.ceil(cashValue * 2) / 2;

		const addCoinsValue = cashValue
		if (startingKitState.coins >= cashValue) {
			console.log("HERE")
			let newCoins = []
			while (cashValue > 0) {
				if (cashValue >= 10) {
					newCoins.push(10)
					cashValue -= 10
				}else if(cashValue >= 1) {
					newCoins.push(1)
					cashValue -= 1
				}else{
					newCoins.push(0.5)
					cashValue -= 0.5
				}
			}

			if (newCoins.length > 0) {
				if ((newCoins.length + currentCoins.length) <= 20) {
					setShowAddCashQuestion(false)

					if (fieldsWithTypeAliases.includes(props.properties.options.fieldName)) {
						const fieldType = (props.properties.options.title !== undefined) ? props.properties.options.title : props.properties.options.optionType
						//props.addCoinsFieldType(props.properties.options.fieldName, fieldType, newCoins, props.properties.options.id, window.playerId, window.playerName)

						// Update Socket
						RealtimeConnection.addMultipleCoins(props.properties.options.fieldName, {
							fieldType: fieldType,
							id: props.properties.options.id,
							coins: newCoins
						})
					}else{
						//props.addCoins(props.properties.options.fieldName, props.properties.options.id, newCoins, window.playerId, window.playerName)

						// Update Socket
						RealtimeConnection.addMultipleCoins(props.properties.options.fieldName, {
							id: props.properties.options.id,
							coins: newCoins
						})
					}

					RealtimeConnection.decreaseStarterKit({
						type: "coins",
						value: addCoinsValue
					})
					
					props.closePanel();
				}
			}
		}
	}

	const subtractCoinsAction = (currentCoins) => {
		if (currentCoins.length > 0 && subtractCashValue > 0) {
			let cashValue = parseFloat(subtractCashValue);
			cashValue = Math.ceil(cashValue * 2) / 2;

			let currentCoinsValue = 0
			for (let i = 0; i < currentCoins.length; i++) {
				currentCoinsValue += currentCoins[i];
			}

			if (currentCoinsValue >= cashValue) {
				let newCashValue = currentCoinsValue - cashValue;
				let newCoins = []
				while (newCashValue > 0) {
					if (newCashValue >= 10) {
						newCoins.push(10)
						newCashValue -= 10
					}else if(newCashValue >= 1) {
						newCoins.push(1)
						newCashValue -= 1
					}else{
						newCoins.push(0.5)
						newCashValue -= 0.5
					}
				}

				if (newCoins.length <= 20) {
					setShowSubtractCashQuestion(false)
					if (fieldsWithTypeAliases.includes(props.properties.options.fieldName)) {
						const fieldType = (props.properties.options.title !== undefined) ? props.properties.options.title : props.properties.options.optionType
						//props.updateCoinsFieldType(props.properties.options.fieldName, fieldType, newCoins, props.properties.options.id, window.playerId, window.playerName)

						// Update Socket
						RealtimeConnection.updateCoins(props.properties.options.fieldName, {
							fieldType: fieldType,
							id: props.properties.options.id,
							coins: newCoins
						})
					}else{
						//props.updateCoins(props.properties.options.fieldName, props.properties.options.id, newCoins, window.playerId, window.playerName)

						// Update Socket
						RealtimeConnection.updateCoins(props.properties.options.fieldName, {
							id: props.properties.options.id,
							coins: newCoins
						})
					}

					setTimeout(() => {
						RealtimeConnection.increaseStarterKit({
							type: "coins",
							value: parseFloat(cashValue)
						})
					}, 200);

					props.closePanel();
				}
			}
		}
	}

	const factoryRent = (id, coins) => {
		setShowInvestmentLine(false)
		props.rentFactory(id)

		// Update Socket
		RealtimeConnection.rentFactory({
			id: id
		})

		if (coins.length > 0) {
			// Add to starting kit
			let coinsValue = coins.reduce((total, num) => {
				return parseFloat(total) + parseFloat(num);
			})

			// Update Socket
			RealtimeConnection.increaseStarterKit({
				type: "coins",
				value: parseFloat(coinsValue)
			})
		}
	}

	const factoryPurchase = (id) => {
		setShowInvestmentLine(true)
		props.purchaseFactory(id)

		// Update Socket
		RealtimeConnection.purchaseFactory({
			id: id
		})
	}

	const getCoinRow = (key, imgSrc, count, value) => {
		return (
			<tr key={key}>
				<td><Image src={imgSrc} className="prevCoinImg" /></td>
				<td>{ count }</td>
				<td>{ formatNumber.format(parseFloat(value) * count) }</td>
			</tr>
		)
	}

	const properties = props.properties;
	const boardData = props.board;
	let leftRightBottomAliases = ["LeftBlock", "RightBlock", "BottomBlock"];

	let productionType = "";
	let productionObject = {};
	let coins = [];
	let coinsList = {};
	let showActionsButtons = (canEdit) ? true : false;

	if (leftRightBottomAliases.includes(properties.options.fieldName)) {
		const object = boardData[properties.options.fieldName][properties.options.id]
		coins = Array.isArray(object.coins) ? object.coins : [];
		if (!object.hasContainer) {
			showActionsButtons = false
		}
	}else if (properties.options.fieldName === "cash") {
		const object = boardData.CenterBlock.Finance.cash[properties.options.id]
		coins = Array.isArray(object.coins) ? object.coins : [];
		if (!object.hasContainer) {
			showActionsButtons = false
		}
	}else if (properties.options.fieldName === "receivables") {
		const object = boardData.CenterBlock.Finance.receivables[properties.options.id]
		coins = Array.isArray(object.coins) ? object.coins : [];
		if (!object.hasContainer) {
			showActionsButtons = false
		}
	}else if (properties.options.fieldName === "Research") {
		const object = boardData.CenterBlock[properties.options.fieldName][properties.options.title]
		coins = Array.isArray(object.coins) ? object.coins : [];
		if (!object.hasContainer) {
			showActionsButtons = false
		}
	}else if (properties.options.fieldName === "Purchase") {
		const object = boardData.CenterBlock[properties.options.fieldName][properties.options.title][properties.options.id];
		coins = Array.isArray(object.coins) ? object.coins : [];
		if (!object.hasContainer) {
			showActionsButtons = false
		}
	}else if (properties.options.fieldName === "Production") {
		productionType = properties.options.optionType;
		productionObject = boardData.CenterBlock[properties.options.fieldName][properties.options.id][properties.options.optionType];
		coins = productionObject.coins;

		if (!productionObject.hasContainer) {
			showActionsButtons = false
		}
	}else if (properties.options.fieldName === "ColorField") {
		console.log(properties);
		coins = boardData.CenterBlock.Stock[properties.options.optionType][properties.options.id].coins;
		productionObject = boardData.CenterBlock.Stock[properties.options.optionType][properties.options.id];

		showActionsButtons = false;
	}else if (properties.options.fieldName === "DirectCost") {
		coins = boardData[properties.options.fieldName][properties.options.id].coins;
		productionObject = boardData[properties.options.fieldName][properties.options.id];

		showActionsButtons = false;
	}

	if (coins) {
		coins.forEach((value) => {
			coinsList[`${ value }`] = (coinsList[`${ value }`]) ? coinsList[`${ value }`] + 1 : 1;
		});
	}

	let coinsRows = [];
	let totalCoinsValue = 0;
	let totalCoinsCount = 0;

	if (coinsList[`10`]) {
		totalCoinsValue += (10 * parseFloat(coinsList[`10`]));
		totalCoinsCount += parseFloat(coinsList[`10`]);

		coinsRows.push(getCoinRow(0, prevCoinImg, coinsList[`10`], 10));
	}
	if (coinsList[`1`]) {
		totalCoinsValue += (1 * parseFloat(coinsList[`1`]));
		totalCoinsCount += parseFloat(coinsList[`1`]);

		coinsRows.push(getCoinRow(1, prevSilverCoinImg, coinsList[`1`], 1));
	}
	if (coinsList[`0.5`]) {
		totalCoinsValue += (0.5 * parseFloat(coinsList[`0.5`]));
		totalCoinsCount += parseFloat(coinsList[`0.5`]);

		coinsRows.push(getCoinRow(2, prevBronzeCoinImg, coinsList[`0.5`], 0.5));
	}

	return (
		<>
			<div className="containerObjClass">
				{
					(productionObject.isFlip && productionObject.cardType === "factory") ?
						<>
							<Image className="objModelClass" src={flippedContainerImg} />
							<div className="card-droppable">
								{
									(productionObject.hasCard && productionType === "factory")?
										<Image className="Container-Card" src={facrotyImg} />
										:
										null
								}
							</div>
						</>
						:
						<>
							<Image className="objModelClass" src={containerImg}/>
							<div className="coins-container">
								<div className="helper"></div>
								{
									(coins.map((value, index) => {
										return (
											(value === 10 &&
												<Image key={index} src={coinImg} className="coins"/>)
											|| (value === 1 &&
												<Image key={index} src={coinSilverImg} className="coins"/>)
											|| (value === 0.5 &&
												<Image key={index} src={coinBronzeImg} className="coins"/>)
										)
									}))
								}
								<>
									{
										(productionObject.hasCard && productionObject.cardType === "factory")?
											<Image className="Container-Card" src={facrotyImgTiles} />
											:
											null
									}
								</>
								<>
									{
										(productionObject.hasCard && productionObject.cardType === "machine")?
											<Image className="Container-Card" src={machineImgTiles} />
											:
											null
									}
								</>
								<>
									{
										(productionObject.hasCard && productionObject.cardType === "label") ?
											<div className="productCardContainer">
												{(
													(productionObject.cardColor === "standard" &&
														<Image className="productCardImg" src={green} />)
													|| (productionObject.cardColor === "compact" &&
														<Image className="productCardImg" src={yellow} />)
													|| (productionObject.cardColor === "plus" &&
														<Image className="productCardImg" src={blue} />)
													|| (productionObject.cardColor === "luxus" &&
														<Image className="productCardImg" src={red} />)
												)}
												<>
													{
														(productionObject.cardValue > 0) ?
															<div className="productCardValue">{ productionObject.cardValue }</div>
															:
															null
													}
												</>
											</div>
											:
											null
									}
								</>
							</div>
						</>
				}
			</div>

			{
				/*(productionType === "factory" && canEdit) ?
					<Container style={{ float: "right", width: "30%" }}>
						<Row>
							<Col>
								<Button
									className="purchase-btn"
									onClick={ () => factoryPurchase(properties.options.id) }
									size="sm"
								>
									<Translate alias="Board" word="Purchase" />
								</Button>
							</Col>
						</Row>
						<Row style={{ marginTop: "5px" }}>
							<Col>
								<Button
									className="rent-btn"
									onClick={ () => factoryRent(properties.options.id, coins) }
									size="sm"
								>
									<Translate alias="Board" word="Rent" />
								</Button>
							</Col>
						</Row>
					</Container>
					:
					null*/
			}

			<div className="clearfix"></div>
			{
				(showInvestmentLine) ?
					<div className="investmentLine">
						<Translate alias="Board" word="Make sure to make an investment" />
					</div>
					:
					null
			}
			<div style={{ height: 20 }}></div>
			<Table striped bordered>
				<thead>
					<tr style={{ color: 'white', backgroundColor: "#002955"}}>
						<th><Translate alias="Board" word="Coin" /></th>
						<th><Translate alias="Board" word="Count" /></th>
						<th><Translate alias="Board" word="Value" /></th>
					</tr>
				</thead>
				<tbody>
				{ coinsRows }
				</tbody>
				<tbody>
					<tr>
						<td className="bold-text"><Translate alias="Board" word="Total" /></td>
						<td className="bold-text">{ totalCoinsCount }</td>
						<td className="bold-text">{ formatNumber.format(totalCoinsValue) }</td>
					</tr>
				</tbody>
			</Table>
			<div className="horizontal-separator">&nbsp;</div>
			{
				(showActionsButtons && !productionObject.isFlip) ?
					<div className="container-actions">
						<Container>
							<Row>
								<Col xs={1}></Col>
								<Col>
									<Button
										className="purchase-btn"
										onClick={ () => setShowAddCashQuestion(true) }
										size="sm"
									>
										<Translate alias="Board" word="Add" />
									</Button>
								</Col>
								<Col xs={2}></Col>
								<Col>
									<Button
										className="rent-btn"
										onClick={ () => setShowSubtractCashQuestion(true) }
										size="sm"
									>
										<Translate alias="Board" word="Subtract" />
									</Button>
								</Col>
								<Col xs={1}></Col>
							</Row>
							<Row>
								<Col className="questions-container">
									{
										(showAddCashQuestion) ?
											<>
												<div className="question-text">
													<Translate alias="Board" word="How much cash do you want to add?" />
												</div>
												<div className="question-input">
													<input
														type="text"
														autoFocus
														id="units"
														pattern="^-?[0-9]\d*\.?\d*$"
														name="units"
														onKeyPress={
															(event) => {
																if (event.key === "Enter") {
																	addCoinsAction(coins)
																}
															}
														}
														onChange={e => setAddCashValue(e.target.value) }
													/>
												</div>
												<div className="question-btn">
													<Button size="sm" onClick={ () => addCoinsAction(coins) }>
														<Translate alias="Board" word="Confirm" />
													</Button>
												</div>
											</>
											:
											null
									}
									{
										(showSubtractCashQuestion) ?
											<>
												<div className="question-text">
													<Translate alias="Board" word="How much cash do you want to subtract?" />
												</div>
												<div className="question-input">
													<input
														type="text"
														autoFocus
														id="units2"
														pattern="^-?[0-9]\d*\.?\d*$"
														name="units2"
														onKeyPress={
															(event) => {
																if (event.key === "Enter") {
																	subtractCoinsAction(coins)
																}
															}
														}
														onChange={e => setSubtractCashValue( e.target.value )}
													/>
												</div>
												<div className="question-btn">
													<Button size="sm" onClick={ () => subtractCoinsAction(coins) }>
														<Translate alias="Board" word="Confirm" />
													</Button>
												</div>
											</>
											:
											null
									}
								</Col>
							</Row>
						</Container>
					</div>
					:
					null
			}
		</>
	)
}

export default connect(null, mapDispatchToProps)(ContainerProperties);
