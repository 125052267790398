import React, { useEffect, useState } from "react";
import {Col, Container, Row, Image, Button} from "react-bootstrap";
import {connect, useSelector} from "react-redux";

import Translate from "../utils/Translate"

// Dispatcher
import mapDispatchToProps from "../Redux/dispatchers";
import * as RealtimeConnection from "./RealtimeConnection";


import "../assets/css/PropertiesPanel.css";

// Images
import smileImg from "../assets/images/faces/faceYellow.svg";
import WhiteFace from "../assets/images/faces/faceWhite.svg"

const SmileyProperties = (props) => {

	const startingKitState = useSelector(state => state.startingKit)

	const [canEdit, setCanEdit] = useState(false)

	const [showAddCashQuestion, setShowAddCashQuestion] = useState(false)
	const [showSubtractCashQuestion, setShowSubtractCashQuestion] = useState(false)
	const [addSmileyValue, setAddSmileyValue] = useState(0)
	const [subtractSmileyValue, setSubtractSmileyValue] = useState(0)

	useEffect(() => {
		setCanEdit(props.canEdit)
	}, [props.canEdit])

	useEffect(() => {
		if (showAddCashQuestion) {
			setShowSubtractCashQuestion(false)
		}
	}, [showAddCashQuestion])

	useEffect(() => {
		if (showSubtractCashQuestion) {
			setShowAddCashQuestion(false)
		}
	}, [showSubtractCashQuestion])

	const addCoinsAction = () => {
		const smileyValue = parseInt(addSmileyValue)
		if (startingKitState.smileFace >= smileyValue) {

			if (smileyValue > 0) {
                setShowAddCashQuestion(false)

                // Update Socket
                RealtimeConnection.addSmile("BottomBlock", {
                    id: props.properties.options.id,
                    number: smileyValue
                })

                RealtimeConnection.decreaseStarterKit({
                    type: "smileFace",
                    value: smileyValue
                })
                
                props.closePanel();
			}
		}
	}

	const subtractCoinsAction = (currentValue) => {
        const smileyValue = parseInt(subtractSmileyValue);
        if (currentValue >= smileyValue) {
            setShowSubtractCashQuestion(false)
            
            // Update Socket
            RealtimeConnection.removeSmileFace("BottomBlock", {
                id: props.properties.options.id,
                number: smileyValue
            })

            setTimeout(() => {
                RealtimeConnection.increaseStarterKit({
                    type: "smileFace",
                    value: smileyValue
                })
            }, 200);

            props.closePanel();
        }
	}

	const properties = props.properties;
	const boardData = props.board;

	let showActionsButtons = (canEdit) ? true : false;
    const object = boardData.BottomBlock[properties.options.id]
    
	return (
		<>
			<div className="field-container">
				<div className="Vertical-droppable-field" style={{ position: "relative" }}>
					<div className="smileDraggable">
						<Image src={ (object.smileNum > 0) ? smileImg : WhiteFace } />
					</div>
					<p>{ object.smileNum }</p>
				</div>
			</div>

			<div className="clearfix"></div>
			
			<div className="horizontal-separator">&nbsp;</div>
			{
				(showActionsButtons) ?
					<div className="container-actions">
						<Container>
							<Row>
								<Col xs={1}></Col>
								<Col>
									<Button
										className="purchase-btn"
										onClick={ () => setShowAddCashQuestion(true) }
										size="sm"
									>
										<Translate alias="Board" word="Add" />
									</Button>
								</Col>
								<Col xs={2}></Col>
								<Col>
									<Button
										className="rent-btn"
										onClick={ () => setShowSubtractCashQuestion(true) }
										size="sm"
									>
										<Translate alias="Board" word="Subtract" />
									</Button>
								</Col>
								<Col xs={1}></Col>
							</Row>
							<Row>
								<Col className="questions-container">
									{
										(showAddCashQuestion) ?
											<>
												<div className="question-text">
													<Translate alias="Board" word="How much smiley do you want to add?" />
												</div>
												<div className="question-input">
													<input
														type="text"
														autoFocus
														id="units"
														pattern="^-?[0-9]\d*\.?\d*$"
														name="units"
														onKeyPress={
															(event) => {
																if (event.key === "Enter") {
																	addCoinsAction(addSmileyValue)
																}
															}
														}
														onChange={e => setAddSmileyValue(e.target.value) }
													/>
												</div>
												<div className="question-btn">
													<Button size="sm" onClick={ () => addCoinsAction(addSmileyValue) }>
														<Translate alias="Board" word="Confirm" />
													</Button>
												</div>
											</>
											:
											null
									}
									{
										(showSubtractCashQuestion) ?
											<>
												<div className="question-text">
													<Translate alias="Board" word="How much smiley do you want to subtract?" />
												</div>
												<div className="question-input">
													<input
														type="text"
														autoFocus
														id="units2"
														pattern="^-?[0-9]\d*\.?\d*$"
														name="units2"
														onKeyPress={
															(event) => {
																if (event.key === "Enter") {
																	subtractCoinsAction(subtractSmileyValue)
																}
															}
														}
														onChange={e => setSubtractSmileyValue( e.target.value )}
													/>
												</div>
												<div className="question-btn">
													<Button size="sm" onClick={ () => subtractCoinsAction(subtractSmileyValue) }>
														<Translate alias="Board" word="Confirm" />
													</Button>
												</div>
											</>
											:
											null
									}
								</Col>
							</Row>
						</Container>
					</div>
					:
					null
			}
		</>
	)
}

export default connect(null, mapDispatchToProps)(SmileyProperties);
