const startingKit = {
	coins: 80,
	tokens: 40,
	pins: 1,
	factory: 1,
	machine: 1,
	smileFace: 30,
	production: {}
}

export default startingKit;