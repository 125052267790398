export default {
  container: "Container",
  factoryContainer: "FactoryContainer",
  machineContainer: "MachineContainer",
  productionContainer: "ProductionContainer",
  stockContainer: "StockContainer",
  coin: "Coin",
  machineLbl: "MachineLabel",
  factoryLbl: "FactoryLabel",
  productLbl: "ProductLabel",
  pin: "Pin",
  Smile: "Smile",
};