import initState from "../InitStates/starting-kit-init-state";

const productionLblColors = {"green":"STANDARD", "yellow":"COMPACT", "blue":"PLUS", "red":"LUXUS"}

const reducer = (state = initState, action) => {

	if (action.type === "UPDATE_STATE") {
		state = action.data.starterKitStateObj;

		return {
			...state
		}
	}else if(action.type === "UPDATE_TEAM_TABLE") {
		if (action.data.type === "production") {
			return {
				...state,
				production: {
					...state.production,
					[action.data.number]: {
						...state.production[action.data.number],
						[action.data.color]: action.data.value
					}
				}
			}
		}else{
			return {
				...state,
				[action.data.type]: action.data.value
			}
		}
	}else if (action.type === "DECREASE_STARTER_KIT") {
		if (action.data.type === "production") {
			return {
				...state,
				production: {
					...state.production,
					[action.data.number]: {
						...state.production[action.data.number],
						[action.data.color]: (state.production[action.data.number][action.data.color] - action.data.value)
					}
				}
			}
		}else{
			return {
				...state,
				[action.data.type]: (state[action.data.type] - action.data.value)
			}
		}
	}else if (action.type === "INCREASE_STARTER_KIT") {
		console.log(action)
		if (action.data.type === "production") {
			return {
				...state,
				production: {
					...state.production,
					[action.data.number]: {
						...state.production[action.data.number],
						[action.data.color]: (state.production[action.data.number][action.data.color] + action.data.value)
					}
				}
			}
		}else{
			return {
				...state,
				[action.data.type]: (state[action.data.type] + action.data.value)
			}
		}
	}else if (action.type === "REMOVE_START_KIT") {
		state.containers[action.id].hasContainer = false;

		return {
			...state
		}
	}else if (action.type === "DECREASE_COIN") {
		if (state.coins >= action.value)
			state.coins -= parseFloat(action.value);

		return {
			...state
		}
	}else if (action.type === "INCREASE_COIN") {
		state.coins += parseFloat(action.value);

		return {
			...state
		}
	}else if (action.type === "DECREASE_PIN") {
		if (state.pins > 0)
			state.pins -= (action.count !== undefined) ? parseInt(action.count) : 1 ;

		return {
			...state
		}
	}else if (action.type === "INCREASE_PIN") {
		state.pins += (action.count !== undefined)? parseInt(action.count) : 1;

		return {
			...state
		}
	}else if (action.type === "DECREASE_TOKEN") {
		if (state.tokens >= action.value)
			state.tokens -= parseInt(action.value);

		return {
			...state
		}
	}else if (action.type === "INCREASE_TOKEN") {
		state.tokens = parseInt(state.tokens) + parseInt(action.value);

		return {
			...state
		}
	}else if (action.type === "DECREASE_BLACK_TOKEN") {
		if (state.blackTokens >= action.value)
			state.blackTokens = parseInt(state.blackTokens) - parseInt(action.value);

		return {
			...state
		}
	}else if (action.type === "INCREASE_BLACK_TOKEN") {
		state.blackTokens = parseInt(state.blackTokens) + parseInt(action.value);

		return {
			...state
		}
	}else if (action.type === "DECREASE_FACTORY") {
		if (state.factory >= parseInt(action.value))
			state.factory = parseInt(state.factory) - parseInt(action.value);

		return {
			...state
		}
	}else if (action.type === "INCREASE_FACTORY") {
		state.factory = parseInt(state.factory) + parseInt(action.value);

		return {
			...state
		}
	}else if (action.type === "DECREASE_MACHINE") {
		if (state.machine >= parseInt(action.value))
			state.machine = parseInt(state.machine) - parseInt(action.value);

		return {
			...state
		}
	}else if (action.type === "INCREASE_MACHINE") {
		state.machine = parseInt(state.machine) + parseInt(action.value);

		return {
			...state
		}
	}else if (action.type === "UPDATE_PRODUCTION") {
		return {
			...state,
			production: action.value
		}
	}else if (action.type === "INCREASE_PRODUCTION") {
		let production = { ...state.production };
		if (production[action.value.toString()] !== undefined && production[action.value.toString()][productionLblColors[action.color]] !== undefined) {
			let currentQty = parseInt(production[action.value.toString()][productionLblColors[action.color]])
			currentQty += 1;
			production[action.value.toString()][productionLblColors[action.color]] = currentQty
		}else{
			production[action.value.toString()][productionLblColors[action.color]] = 1
		}

		return {
			...state,
			production: production
		}
	}else if (action.type === "DECREASE_PRODUCTION") {
		let production = state.production;
		let currentQty = parseInt(production[action.value.toString()][productionLblColors[action.color]])
		if (currentQty > 0) {
			currentQty -= 1;
		}else{
			currentQty = 0;
		}
		production[action.value.toString()][productionLblColors[action.color]] = currentQty

		return {
			...state,
			production: production
		}
	}



	return state;
}

export default reducer;