import React from 'react';
import { Table, FormControl } from 'react-bootstrap';

import Translate from '../../../utils/Translate'

class ProfitLoss extends React.Component {

	handleChange = (field, value, period) => {
		this.props.inputChanged("profitLoss", field, value, period)
	}

	render() {

		let grossMargin_1 = 0
		let grossMargin_2 = 0
		let grossMargin_3 = 0
		let grossMargin_4 = 0
		let grossMargin_5 = 0
		grossMargin_1 += (!isNaN(parseFloat(this.props.profitLoss.period_1.revenue))) ? parseFloat(this.props.profitLoss.period_1.revenue) : 0;
		grossMargin_1 -= (!isNaN(parseFloat(this.props.profitLoss.period_1.directCosts))) ? parseFloat(this.props.profitLoss.period_1.directCosts) : 0;
		grossMargin_2 += (!isNaN(parseFloat(this.props.profitLoss.period_2.revenue))) ? parseFloat(this.props.profitLoss.period_2.revenue) : 0;
		grossMargin_2 -= (!isNaN(parseFloat(this.props.profitLoss.period_2.directCosts))) ? parseFloat(this.props.profitLoss.period_2.directCosts) : 0;
		grossMargin_3 += (!isNaN(parseFloat(this.props.profitLoss.period_3.revenue))) ? parseFloat(this.props.profitLoss.period_3.revenue) : 0;
		grossMargin_3 -= (!isNaN(parseFloat(this.props.profitLoss.period_3.directCosts))) ? parseFloat(this.props.profitLoss.period_3.directCosts) : 0;
		grossMargin_4 += (!isNaN(parseFloat(this.props.profitLoss.period_4.revenue))) ? parseFloat(this.props.profitLoss.period_4.revenue) : 0;
		grossMargin_4 -= (!isNaN(parseFloat(this.props.profitLoss.period_4.directCosts))) ? parseFloat(this.props.profitLoss.period_4.directCosts) : 0;
		grossMargin_5 += (!isNaN(parseFloat(this.props.profitLoss.period_5.revenue))) ? parseFloat(this.props.profitLoss.period_5.revenue) : 0;
		grossMargin_5 -= (!isNaN(parseFloat(this.props.profitLoss.period_5.directCosts))) ? parseFloat(this.props.profitLoss.period_5.directCosts) : 0;

		let ebitda_1 = grossMargin_1
		let ebitda_2 = grossMargin_2
		let ebitda_3 = grossMargin_3
		let ebitda_4 = grossMargin_4
		let ebitda_5 = grossMargin_5
		ebitda_1 -= (!isNaN(parseFloat(this.props.profitLoss.period_1.sales))) ? parseFloat(this.props.profitLoss.period_1.sales) : 0;
		ebitda_1 -= (!isNaN(parseFloat(this.props.profitLoss.period_1.overheads))) ? parseFloat(this.props.profitLoss.period_1.overheads) : 0;
		ebitda_2 += (!isNaN(parseFloat(this.props.profitLoss.period_2.sales))) ? parseFloat(this.props.profitLoss.period_2.sales) : 0;
		ebitda_2 -= (!isNaN(parseFloat(this.props.profitLoss.period_2.overheads))) ? parseFloat(this.props.profitLoss.period_2.overheads) : 0;
		ebitda_3 += (!isNaN(parseFloat(this.props.profitLoss.period_3.sales))) ? parseFloat(this.props.profitLoss.period_3.sales) : 0;
		ebitda_3 -= (!isNaN(parseFloat(this.props.profitLoss.period_3.overheads))) ? parseFloat(this.props.profitLoss.period_3.overheads) : 0;
		ebitda_4 += (!isNaN(parseFloat(this.props.profitLoss.period_4.sales))) ? parseFloat(this.props.profitLoss.period_4.sales) : 0;
		ebitda_4 -= (!isNaN(parseFloat(this.props.profitLoss.period_4.overheads))) ? parseFloat(this.props.profitLoss.period_4.overheads) : 0;
		ebitda_5 += (!isNaN(parseFloat(this.props.profitLoss.period_5.sales))) ? parseFloat(this.props.profitLoss.period_5.sales) : 0;
		ebitda_5 -= (!isNaN(parseFloat(this.props.profitLoss.period_5.overheads))) ? parseFloat(this.props.profitLoss.period_5.overheads) : 0;

		let ebit_1 = ebitda_1
		let ebit_2 = ebitda_2
		let ebit_3 = ebitda_3
		let ebit_4 = ebitda_4
		let ebit_5 = ebitda_5
		ebit_1 -= (!isNaN(parseFloat(this.props.profitLoss.period_1.deprecitation))) ? parseFloat(this.props.profitLoss.period_1.deprecitation) : 0;
		ebit_2 -= (!isNaN(parseFloat(this.props.profitLoss.period_2.deprecitation))) ? parseFloat(this.props.profitLoss.period_2.deprecitation) : 0;
		ebit_3 -= (!isNaN(parseFloat(this.props.profitLoss.period_3.deprecitation))) ? parseFloat(this.props.profitLoss.period_3.deprecitation) : 0;
		ebit_4 -= (!isNaN(parseFloat(this.props.profitLoss.period_4.deprecitation))) ? parseFloat(this.props.profitLoss.period_4.deprecitation) : 0;
		ebit_5 -= (!isNaN(parseFloat(this.props.profitLoss.period_5.deprecitation))) ? parseFloat(this.props.profitLoss.period_5.deprecitation) : 0;

		let ebt_1 = ebit_1
		let ebt_2 = ebit_2
		let ebt_3 = ebit_3
		let ebt_4 = ebit_4
		let ebt_5 = ebit_5
		ebt_1 -= (!isNaN(parseFloat(this.props.profitLoss.period_1.interest))) ? parseFloat(this.props.profitLoss.period_1.interest) : 0;
		ebt_2 -= (!isNaN(parseFloat(this.props.profitLoss.period_2.interest))) ? parseFloat(this.props.profitLoss.period_2.interest) : 0;
		ebt_3 -= (!isNaN(parseFloat(this.props.profitLoss.period_3.interest))) ? parseFloat(this.props.profitLoss.period_3.interest) : 0;
		ebt_4 -= (!isNaN(parseFloat(this.props.profitLoss.period_4.interest))) ? parseFloat(this.props.profitLoss.period_4.interest) : 0;
		ebt_5 -= (!isNaN(parseFloat(this.props.profitLoss.period_5.interest))) ? parseFloat(this.props.profitLoss.period_5.interest) : 0;

		return (
			<Table bordered>
				<tbody>
					<tr>
						<th></th>
						<th style={{ padding: 5 }}>
							<div style={{ display: "flex" }}>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3, textAlign: "center" }}>Period 1</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3, textAlign: "center" }}>Period 2</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3, textAlign: "center" }}>Period 3</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3, textAlign: "center" }}>Period 4</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3, textAlign: "center" }}>Period 5</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3, textAlign: "center" }}>Period 6</div>
							</div>
						</th>
					</tr>
					<tr>
						<td>
							<div className="bold"><Translate alias="Forms" word="Revenue" /></div>
						</td>
						<td>
							<div style={{ display: "flex" }}>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ this.props.profitLoss.period_1.revenue }
										onChange={ (e) => this.handleChange("revenue", e.target.value, "period_1" )} 
										disabled={ !this.props.status }
									/>
								</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ this.props.profitLoss.period_2.revenue }
										onChange={ (e) => this.handleChange("revenue", e.target.value, "period_2" )} 
										disabled={ !this.props.status }
									/>
								</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ this.props.profitLoss.period_3.revenue }
										onChange={ (e) => this.handleChange("revenue", e.target.value, "period_3" )} 
										disabled={ !this.props.status }
									/>
								</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ this.props.profitLoss.period_4.revenue }
										onChange={ (e) => this.handleChange("revenue", e.target.value, "period_4" )} 
										disabled={ !this.props.status }
									/>
								</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ this.props.profitLoss.period_5.revenue }
										onChange={ (e) => this.handleChange("revenue", e.target.value, "period_5" )} 
										disabled={ !this.props.status }
									/>
								</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ this.props.profitLoss.period_5.revenue }
										onChange={ (e) => this.handleChange("revenue", e.target.value, "period_5" )} 
										disabled={ !this.props.status }
									/>
								</div>
							</div>
						</td>
					</tr>
					<tr>
						<td>
							<div className="bold"><Translate alias="Forms" word="Direct Costs" /></div>
						</td>
						<td>
							<div style={{ display: "flex" }}>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ this.props.profitLoss.period_1.directCosts }
										onChange={ (e) => this.handleChange("directCosts", e.target.value, "period_1" )} 
										disabled={ !this.props.status }
									/>
								</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ this.props.profitLoss.period_2.directCosts }
										onChange={ (e) => this.handleChange("directCosts", e.target.value, "period_2" )} 
										disabled={ !this.props.status }
									/>
								</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ this.props.profitLoss.period_3.directCosts }
										onChange={ (e) => this.handleChange("directCosts", e.target.value, "period_3" )} 
										disabled={ !this.props.status }
									/>
								</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ this.props.profitLoss.period_4.directCosts }
										onChange={ (e) => this.handleChange("directCosts", e.target.value, "period_4" )} 
										disabled={ !this.props.status }
									/>
								</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ this.props.profitLoss.period_5.directCosts }
										onChange={ (e) => this.handleChange("directCosts", e.target.value, "period_5" )} 
										disabled={ !this.props.status }
									/>
								</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ this.props.profitLoss.period_5.directCosts }
										onChange={ (e) => this.handleChange("directCosts", e.target.value, "period_5" )} 
										disabled={ !this.props.status }
									/>
								</div>
							</div>
						</td>
					</tr>
					<tr className="autofillFieldBackground">
						<td>
							<div className="bold"><Translate alias="Forms" word="Gross Margin" /></div>
						</td>
						<td>
							<div style={{ display: "flex" }}>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl aria-label="Small" size="sm" value={ grossMargin_1 } disabled />
								</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl aria-label="Small" size="sm" value={ grossMargin_2 } disabled />
								</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl aria-label="Small" size="sm" value={ grossMargin_3 } disabled />
								</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl aria-label="Small" size="sm" value={ grossMargin_4 } disabled />
								</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl aria-label="Small" size="sm" value={ grossMargin_5 } disabled />
								</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl aria-label="Small" size="sm" value={ grossMargin_5 } disabled />
								</div>
							</div>
						</td>
					</tr>
					<tr>
						<td>
							<div className="bold"><Translate alias="Forms" word="Sales" /></div>
						</td>
						<td>
							<div style={{ display: "flex" }}>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ this.props.profitLoss.period_1.sales }
										onChange={ (e) => this.handleChange("sales", e.target.value, "period_1" )} 
										disabled={ !this.props.status }
									/>
								</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ this.props.profitLoss.period_2.sales }
										onChange={ (e) => this.handleChange("sales", e.target.value, "period_2" )} 
										disabled={ !this.props.status }
									/>
								</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ this.props.profitLoss.period_3.sales }
										onChange={ (e) => this.handleChange("sales", e.target.value, "period_3" )} 
										disabled={ !this.props.status }
									/>
								</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ this.props.profitLoss.period_4.sales }
										onChange={ (e) => this.handleChange("sales", e.target.value, "period_4" )} 
										disabled={ !this.props.status }
									/>
								</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ this.props.profitLoss.period_5.sales }
										onChange={ (e) => this.handleChange("sales", e.target.value, "period_5" )} 
										disabled={ !this.props.status }
									/>
								</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ this.props.profitLoss.period_5.sales }
										onChange={ (e) => this.handleChange("sales", e.target.value, "period_5" )} 
										disabled={ !this.props.status }
									/>
								</div>
							</div>
						</td>
					</tr>
					<tr>
						<td>
							<div className="bold"><Translate alias="Forms" word="Overheads" /></div>
						</td>
						<td>
							<div style={{ display: "flex" }}>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ this.props.profitLoss.period_1.overheads }
										onChange={ (e) => this.handleChange("overheads", e.target.value, "period_1" )} 
										disabled={ !this.props.status }
									/>
								</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ this.props.profitLoss.period_2.overheads }
										onChange={ (e) => this.handleChange("overheads", e.target.value, "period_2" )} 
										disabled={ !this.props.status }
									/>
								</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ this.props.profitLoss.period_3.overheads }
										onChange={ (e) => this.handleChange("overheads", e.target.value, "period_3" )} 
										disabled={ !this.props.status }
									/>
								</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ this.props.profitLoss.period_4.overheads }
										onChange={ (e) => this.handleChange("overheads", e.target.value, "period_" )} 
										disabled={ !this.props.status }
									/>
								</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ this.props.profitLoss.period_5.overheads }
										onChange={ (e) => this.handleChange("overheads", e.target.value, "period_5" )} 
										disabled={ !this.props.status }
									/>
								</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ this.props.profitLoss.period_5.overheads }
										onChange={ (e) => this.handleChange("overheads", e.target.value, "period_5" )} 
										disabled={ !this.props.status }
									/>
								</div>
							</div>
						</td>
					</tr>
					<tr className="autofillFieldBackground">
						<td>
							<div className="bold"><Translate alias="Forms" word="EBITDA" /></div>
						</td>
						<td>
							<div style={{ display: "flex" }}>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl aria-label="Small" size="sm" value={ ebitda_1 } disabled />
								</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl aria-label="Small" size="sm" value={ ebitda_2 } disabled />
								</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl aria-label="Small" size="sm" value={ ebitda_3 } disabled />
								</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl aria-label="Small" size="sm" value={ ebitda_4 } disabled />
								</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl aria-label="Small" size="sm" value={ ebitda_5 } disabled />
								</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl aria-label="Small" size="sm" value={ ebitda_5 } disabled />
								</div>
							</div>
						</td>
					</tr>
					<tr>
						<td>
							<div className="bold"><Translate alias="Forms" word="Deprecitation" /></div>
						</td>
						<td>
							<div style={{ display: "flex" }}>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ this.props.profitLoss.period_1.deprecitation }
										onChange={ (e) => this.handleChange("deprecitation", e.target.value, "period_1" )} 
										disabled={ !this.props.status }
									/>
								</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ this.props.profitLoss.period_2.deprecitation }
										onChange={ (e) => this.handleChange("deprecitation", e.target.value, "period_2" )} 
										disabled={ !this.props.status }
									/>
								</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ this.props.profitLoss.period_3.deprecitation }
										onChange={ (e) => this.handleChange("deprecitation", e.target.value, "period_3" )} 
										disabled={ !this.props.status }
									/>
								</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ this.props.profitLoss.period_4.deprecitation }
										onChange={ (e) => this.handleChange("deprecitation", e.target.value, "period_4" )} 
										disabled={ !this.props.status }
									/>
								</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ this.props.profitLoss.period_5.deprecitation }
										onChange={ (e) => this.handleChange("deprecitation", e.target.value, "period_5" )} 
										disabled={ !this.props.status }
									/>
								</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ this.props.profitLoss.period_5.deprecitation }
										onChange={ (e) => this.handleChange("deprecitation", e.target.value, "period_5" )} 
										disabled={ !this.props.status }
									/>
								</div>
							</div>
						</td>
					</tr>
					<tr className="autofillFieldBackground">
						<td>
							<div className="bold"><Translate alias="Forms" word="EBIT" /></div>
						</td>
						<td>
							<div style={{ display: "flex" }}>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl aria-label="Small" size="sm" value={ ebit_1 } disabled />
								</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl aria-label="Small" size="sm" value={ ebit_2 } disabled />
								</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl aria-label="Small" size="sm" value={ ebit_3 } disabled />
								</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl aria-label="Small" size="sm" value={ ebit_4 } disabled />
								</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl aria-label="Small" size="sm" value={ ebit_5 } disabled />
								</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl aria-label="Small" size="sm" value={ ebit_5 } disabled />
								</div>
							</div>
						</td>
					</tr>
					<tr>
						<td>
							<div className="bold"><Translate alias="Forms" word="Interest" /></div>
						</td>
						<td>
							<div style={{ display: "flex" }}>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ this.props.profitLoss.period_1.interest }
										onChange={ (e) => this.handleChange("interest", e.target.value, "period_1" )} 
										disabled={ !this.props.status }
									/>
								</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ this.props.profitLoss.period_2.interest }
										onChange={ (e) => this.handleChange("interest", e.target.value, "period_2" )} 
										disabled={ !this.props.status }
									/>
								</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ this.props.profitLoss.period_3.interest }
										onChange={ (e) => this.handleChange("interest", e.target.value, "period_3" )} 
										disabled={ !this.props.status }
									/>
								</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ this.props.profitLoss.period_4.interest }
										onChange={ (e) => this.handleChange("interest", e.target.value, "period_4" )} 
										disabled={ !this.props.status }
									/>
								</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ this.props.profitLoss.period_5.interest }
										onChange={ (e) => this.handleChange("interest", e.target.value, "period_5" )} 
										disabled={ !this.props.status }
									/>
								</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ this.props.profitLoss.period_5.interest }
										onChange={ (e) => this.handleChange("interest", e.target.value, "period_5" )} 
										disabled={ !this.props.status }
									/>
								</div>
							</div>
						</td>
					</tr>
					<tr className="autofillFieldBackground">
						<td>
							<div className="bold"><Translate alias="Forms" word="EBT" /></div>
						</td>
						<td>
							<div style={{ display: "flex" }}>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl aria-label="Small" size="sm" value={ ebt_1 } disabled />
								</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl aria-label="Small" size="sm" value={ ebt_2 } disabled />
								</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl aria-label="Small" size="sm" value={ ebt_3 } disabled />
								</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl aria-label="Small" size="sm" value={ ebt_4 } disabled />
								</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl aria-label="Small" size="sm" value={ ebt_5 } disabled />
								</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl aria-label="Small" size="sm" value={ ebt_5 } disabled />
								</div>
							</div>
						</td>
					</tr>
					<tr>
						<td>
							<div className="bold"><Translate alias="Forms" word="EBIT - Ratio" /></div>
						</td>
						<td>
							<div style={{ display: "flex" }}>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ this.props.profitLoss.period_1.EBITRatio }
										onChange={ (e) => this.handleChange("EBITRatio", e.target.value, "period_1" )} 
										disabled={ !this.props.status }
									/>
								</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ this.props.profitLoss.period_2.EBITRatio }
										onChange={ (e) => this.handleChange("EBITRatio", e.target.value, "period_2" )} 
										disabled={ !this.props.status }
									/>
								</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ this.props.profitLoss.period_3.EBITRatio }
										onChange={ (e) => this.handleChange("EBITRatio", e.target.value, "period_3" )} 
										disabled={ !this.props.status }
									/>
								</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ this.props.profitLoss.period_4.EBITRatio }
										onChange={ (e) => this.handleChange("EBITRatio", e.target.value, "period_4" )} 
										disabled={ !this.props.status }
									/>
								</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ this.props.profitLoss.period_5.EBITRatio }
										onChange={ (e) => this.handleChange("EBITRatio", e.target.value, "period_5" )} 
										disabled={ !this.props.status }
									/>
								</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ this.props.profitLoss.period_5.EBITRatio }
										onChange={ (e) => this.handleChange("EBITRatio", e.target.value, "period_5" )} 
										disabled={ !this.props.status }
									/>
								</div>
							</div>
						</td>
					</tr>
					<tr>
						<td>
							<div className="bold"><Translate alias="Forms" word="Return on Sales" /></div>
						</td>
						<td>
							<div style={{ display: "flex" }}>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ this.props.profitLoss.period_1.returnOnSales }
										onChange={ (e) => this.handleChange("returnOnSales", e.target.value, "period_1" )} 
										disabled={ !this.props.status }
									/>
								</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ this.props.profitLoss.period_2.returnOnSales }
										onChange={ (e) => this.handleChange("returnOnSales", e.target.value, "period_2" )} 
										disabled={ !this.props.status }
									/>
								</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ this.props.profitLoss.period_3.returnOnSales }
										onChange={ (e) => this.handleChange("returnOnSales", e.target.value, "period_3" )} 
										disabled={ !this.props.status }
									/>
								</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ this.props.profitLoss.period_4.returnOnSales }
										onChange={ (e) => this.handleChange("returnOnSales", e.target.value, "period_4" )} 
										disabled={ !this.props.status }
									/>
								</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ this.props.profitLoss.period_5.returnOnSales }
										onChange={ (e) => this.handleChange("returnOnSales", e.target.value, "period_5" )} 
										disabled={ !this.props.status }
									/>
								</div>
								<div style={{ width: 50, color: "#FFF", fontSize: 10, paddingRight: 3 }}>
									<FormControl 
										aria-label="Small" 
										size="sm" 
										value={ this.props.profitLoss.period_5.returnOnSales }
										onChange={ (e) => this.handleChange("returnOnSales", e.target.value, "period_5" )} 
										disabled={ !this.props.status }
									/>
								</div>
							</div>
						</td>
					</tr>
				</tbody>
			</Table>
		);
	}

}

export default ProfitLoss;