import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDrop } from "react-dnd";
import DraggablePin from "../../Common/DraggablePin";
import DroppableField from "./SalesDroppableField";
import { useDispatch, useSelector } from "react-redux";
import { addContainer, addCoin } from "../../../Redux/dispatchers";
import dropFieldClassNames from "../../../utils/dropFieldClassNames";
import * as RealtimeConnection from "../../RealtimeConnection";

import items from "../../../utils/items";
import colors from "../../../utils/colors";
import Translate from "../../../utils/Translate";

export default function ProductionBlockSection({ data, id, appendPin, removePin}) {

	//(coins, containerId, kind)
	const appendContainer = (coins, containerId, hasCard, cardValue, cardColor) => {
		console.log("Has Card")
		console.log(hasCard)
		RealtimeConnection.addContainer("Sales", {
			mainId: id,
			id: containerId,
			coins: coins,
			hasCard: hasCard,
			cardValue: cardValue,
			cardColor: cardColor
		});
	};

	const removeContainerHandler = (containerId, isDropToDelete , kind) => {
		/*RealtimeConnection.removeContainer("Sales", {
			mainId: id,
			id: containerId,
			isDropToDelete: isDropToDelete,
		});*/
	};

	const addCoinHandler = (id, coinValue, kind) => {};
	
	const isDraggingHandler = (type, isDragging, data, kind) => {
		let dataObj = {
			teamId: window.teamId,
			sessionId: window.sessionId,
			playerId: window.playerId,
			playerName: window.playerName,
			alias: "Sales",
			isDragging: isDragging,
			type: type,
		};
		dataObj = { ...dataObj, ...data };

		window.socket.emit("item-dragging", dataObj);
	};

	const [{ isOver, canDrop }, drop] = useDrop({
		accept: "Pin",
		collect: (monitor) => ({
			isOver: monitor.isOver(),
			canDrop: monitor.canDrop(),
		}),
		drop: (item) => {
			console.log(item);
			console.log(id);
			appendPin(id);

			return item;
		},
	});

	const backgroundColor = isOver && canDrop ? colors.activeBackground : "";

	return (
		<Container className="Production-block-container">
			<Row className="custom-height">
				<Col xs={4}>
					<div className="Block-title-bold-text Production-title-text-size Same-line">
						<Translate alias="Board" word={ (id === 0) ? "Region A" : "Region B" } />
					</div>
				</Col>
			</Row>
			<Row className={`Research-row sales-row`}>
				{
					(data.pin) ?
						<div style={{ padding: "0px 10px" }}>
							<div className="sales-point">
								O
								<div>
									<DraggablePin removePin={removePin} id={id} />
								</div>
							</div>
						</div>
						:
						<div className=" Sales-number-box" style={{ padding: "0px 10px" }}>
							<div className="sales-point" ref={drop} style={{ backgroundColor: backgroundColor }}>
								O
							</div>
						</div>
				}
				{
					data.containers.map((value, index) => {
						return (
							<React.Fragment key={index}>
								<Col>
									<DroppableField
										id={index}
										class={dropFieldClassNames.Finance}
										isHorizontal={true}
										data={value}
										containerType={items.productionContainer}
										appendContainer={appendContainer}
										removeContainer={removeContainerHandler}
										addCoin={addCoinHandler}
										kind={"Sales"}
										mainId={id}
										isDraggingHandler={isDraggingHandler}
									/>
								</Col>
							</React.Fragment>
						);
					})
				}
			</Row>
		</Container>
	);
}
