const initState = {
	LeftBlock: [
		{
			hasContainer: false,
			coins: [],
			playerId: 0,
			isDragging: false,
			coinsDraggingIndex: -1,
		},
		{
			hasContainer: false,
			coins: [],
			playerId: 0,
			isDragging: false,
			coinsDraggingIndex: -1,
		},
		{
			hasContainer: false,
			coins: [],
			playerId: 0,
			isDragging: false,
			coinsDraggingIndex: -1,
		}
	],
	CenterBlock: {
		Finance: {
			equity: "",
			liabilities: {
				type: "",
				value: 0,
			},
			payables: {
				type: "",
				value: 0,
			},
			receivables: [
				{
					hasContainer: false,
					coins: [],
					playerId: 0,
					isDragging: false,
					coinsDraggingIndex: -1,
				},
				{
					hasContainer: false,
					coins: [],
					playerId: 0,
					isDragging: false,
					coinsDraggingIndex: -1,
				}
			],
			cash: [
				{
					hasContainer: false,
					coins: [],
					playerId: 0,
					isDragging: false,
					coinsDraggingIndex: -1,
				},
				{
					hasContainer: false,
					coins: [],
					playerId: 0,
					isDragging: false,
					coinsDraggingIndex: -1,
				},
				,
				{
					hasContainer: false,
					coins: [],
					playerId: 0,
					isDragging: false,
					coinsDraggingIndex: -1,
				},
				{
					hasContainer: false,
					coins: [],
					playerId: 0,
					isDragging: false,
					coinsDraggingIndex: -1,
				},
				{
					hasContainer: false,
					coins: [],
					playerId: 0,
					isDragging: false,
					coinsDraggingIndex: -1,
				},
				{
					hasContainer: false,
					coins: [],
					playerId: 0,
					isDragging: false,
					coinsDraggingIndex: -1,
				}
			],
		},
		Research: {
			economy: {
				hasContainer: false,
				coins: [],
				playerId: 0,
				isDragging: false,
				coinsDraggingIndex: -1,
			},
			elite: {
				hasContainer: false,
				coins: [],
				playerId: 0,
				isDragging: false,
				coinsDraggingIndex: -1,
			}
		},
		Purchase: {
			economy: [
				{
					hasContainer: false,
					coins: [],
					playerId: 0,
					isDragging: false,
					coinsDraggingIndex: -1,
				},
				{
					hasContainer: false,
					coins: [],
					playerId: 0,
					isDragging: false,
					coinsDraggingIndex: -1,
				},
			],
			elite: [
				{
					hasContainer: false,
					coins: [],
					playerId: 0,
					isDragging: false,
					coinsDraggingIndex: -1,
				},
				{
					hasContainer: false,
					coins: [],
					playerId: 0,
					isDragging: false,
					coinsDraggingIndex: -1,
				},
			],
		},
		Production: [
			{
				pins: {
					fast: {
						standard: false,
						compact: false,
						plus: false,
						luxus: false,
					},
					medium: {
						standard: false,
						compact: false,
						plus: false,
						luxus: false,
					},
					slow: {
						standard: false,
						compact: false,
						plus: false,
						luxus: false,
					},
				},
				factory: {
					hasContainer: false,
					coins: [],
					isFlip: false,
					hasCard: false,
					cardType: "factory",
				},
				machine: {
					hasContainer: false,
					coins: [],
					hasCard: false,
					cardType: "machine",
				},
				product: {
					hasContainer: false,
					coins: [],
					hasCard: false,
					cardColor: "",
					cardValue: 0,
					cardType: "label",
				},
			},
			{
				pins: {
					fast: {
						standard: false,
						compact: false,
						plus: false,
						luxus: false,
					},
					medium: {
						standard: false,
						compact: false,
						plus: false,
						luxus: false,
					},
					slow: {
						standard: false,
						compact: false,
						plus: false,
						luxus: false,
					},
				},
				factory: {
					hasContainer: false,
					coins: [],
					isFlip: false,
					hasCard: false,
					cardType: "factory",
				},
				machine: {
					hasContainer: false,
					coins: [],
					hasCard: false,
					cardType: "machine",
				},
				product: {
					hasContainer: false,
					coins: [],
					hasCard: false,
					cardColor: "",
					cardValue: 0,
					cardType: "label",
				},
			},
			{
				pins: {
					fast: {
						standard: false,
						compact: false,
						plus: false,
						luxus: false,
					},
					medium: {
						standard: false,
						compact: false,
						plus: false,
						luxus: false,
					},
					slow: {
						standard: false,
						compact: false,
						plus: false,
						luxus: false,
					},
				},
				factory: {
					hasContainer: false,
					coins: [],
					isFlip: false,
					hasCard: false,
					cardType: "factory",
				},
				machine: {
					hasContainer: false,
					coins: [],
					hasCard: false,
					cardType: "machine",
				},
				product: {
					hasContainer: false,
					coins: [],
					hasCard: false,
					cardColor: "",
					cardValue: 0,
					cardType: "label",
				},
			},
			{
				pins: {
					fast: {
						standard: false,
						compact: false,
						plus: false,
						luxus: false,
					},
					medium: {
						standard: false,
						compact: false,
						plus: false,
						luxus: false,
					},
					slow: {
						standard: false,
						compact: false,
						plus: false,
						luxus: false,
					},
				},
				factory: {
					hasContainer: false,
					coins: [],
					isFlip: false,
					hasCard: false,
					cardType: "factory",
				},
				machine: {
					hasContainer: false,
					coins: [],
					hasCard: false,
					cardType: "machine",
				},
				product: {
					hasContainer: false,
					coins: [],
					hasCard: false,
					cardColor: "",
					cardValue: 0,
					cardType: "label",
				},
			}
		],
		Sales: [
			{
				pin: false,
				containers: [
					{
						hasContainer: false,
						coins: [],
						cardType: 'label',
						hasCard: false,
						cardColor: "",
						cardValue: 0
					},
					{
						hasContainer: false,
						coins: [],
						cardType: 'label',
						hasCard: false,
						cardColor: "",
						cardValue: 0
					}
				]
			},
			{
				pin: false,
				containers: [
					{
						hasContainer: false,
						coins: [],
						cardType: 'label',
						hasCard: false,
						cardColor: "",
						cardValue: 0
					},
					{
						hasContainer: false,
						coins: [],
						cardType: 'label',
						hasCard: false,
						cardColor: "",
						cardValue: 0
					}
				]
			}
		]
	},
	RightBlock: [
		{
			hasContainer: false,
			coins: []
		}
	],
	BottomBlock: [
		{
			smileNum: 0,
			name: "employees",
		},
		{
			smileNum: 0,
			name: "share holder",
		},
		{
			smileNum: 0,
			name: "customer",
		},
	],
	DirectCost: [
		{
			hasContainer: false,
			coins: [],
			cardType: "label",
			hasCard: false,
			cardColor: "",
			cardValue: 0,
		},
		{
			hasContainer: false,
			coins: [],
			cardType: "label",
			hasCard: false,
			cardColor: "",
			cardValue: 0,
		},
		{
			hasContainer: false,
			coins: [],
			cardType: "label",
			hasCard: false,
			cardColor: "",
			cardValue: 0,
		},
		{
			hasContainer: false,
			coins: [],
			cardType: "label",
			hasCard: false,
			cardColor: "",
			cardValue: 0,
		},
		{
			hasContainer: false,
			coins: [],
			cardType: "label",
			hasCard: false,
			cardColor: "",
			cardValue: 0,
		},
		{
			hasContainer: false,
			coins: [],
			cardType: "label",
			hasCard: false,
			cardColor: "",
			cardValue: 0,
		},
		{
			hasContainer: false,
			coins: [],
			cardType: "label",
			hasCard: false,
			cardColor: "",
			cardValue: 0,
		},
		{
			hasContainer: false,
			coins: [],
			cardType: "label",
			hasCard: false,
			cardColor: "",
			cardValue: 0,
		},
		{
			hasContainer: false,
			coins: [],
			cardType: "label",
			hasCard: false,
			cardColor: "",
			cardValue: 0,
		},
		{
			hasContainer: false,
			coins: [],
			cardType: "label",
			hasCard: false,
			cardColor: "",
			cardValue: 0,
		},
		{
			hasContainer: false,
			coins: [],
			cardType: "label",
			hasCard: false,
			cardColor: "",
			cardValue: 0,
		},
		{
			hasContainer: false,
			coins: [],
			cardType: "label",
			hasCard: false,
			cardColor: "",
			cardValue: 0,
		},
	],
};

export default initState;
