import React from "react";
import { Container, Row, Image } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { addContainer, addCoin } from "../../Redux/dispatchers";
import DroppableField from "../Common/DroppableField";
// import dropFieldClassNames from "../../utils/dropFieldClassNames";

import rightArrow from "../../assets/images/right-arrow.png";
import * as RealtimeConnection from "../RealtimeConnection";
import Translate from "../../utils/Translate";

export default function RightBlock({ title }) {
	const dispatch = useDispatch()

	const { State } = useSelector((state) => ({
		State: state.board.RightBlock,
	}));

	const appendContainer = (coins, id) => {
		RealtimeConnection.addContainer("RightBlock", {
			id: id,
			coins: coins,
		});
	};

	const addCoinHandler = (id, coinValue) => {
		RealtimeConnection.addCoin("RightBlock", {
			id: id,
			value: coinValue,
		});
	};
	const removeContainerHandler = (id, isDropToDelete) => {
		// Update Socket
		RealtimeConnection.removeContainer("RightBlock", {
			id: id,
			isDropToDelete: isDropToDelete,
		});
	};
	const isDraggingHandler = (type, isDragging, data) => {
		let dataObj = {
			teamId: window.teamId,
			sessionId: window.sessionId,
			playerId: window.playerId,
			playerName: window.playerName,
			alias: "RightBlock",
			isDragging: isDragging,
			type: type,
		};
		dataObj = { ...dataObj, ...data };

		window.socket.emit("item-dragging", dataObj);
	};

	const showContainerProperties = (id) => {
		dispatch({type: "PROPERTIES", alias: "FIELD", options: { fieldName: "RightBlock", id: id }})
	};

	const droppableFieldsList = State.map((value, index) => {
		return (
			<div key={index} onClick={ () => showContainerProperties(index) }>
				<DroppableField
					id={index}
					class={"Vertical-droppable-field"}
					isHorizontal={false}
					data={value}
					appendContainer={appendContainer}
					addCoin={addCoinHandler}
					kind={"RightBlock"}
					removeContainer={removeContainerHandler}
					isDraggingHandler={isDraggingHandler}
				/>
			</div>
		);
	});

	return (
		<Container>
			<Row>
				<div className="left-right-block Right-block mb-3">
					<Row>
						<div className="text-column">
							<p className="Text-bold-size">
								<Translate alias="Board" word={ title } />
							</p>
						</div>
						<div className="field-column">{droppableFieldsList}</div>
					</Row>
					<Image src={rightArrow} className="Arrow-class" />
				</div>
			</Row>
		</Container>
	);
}
