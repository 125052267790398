import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import {} from "../Redux/dispatchers";
// CSS
import "../assets/css/SimCompact/Common.css";
import "../assets/css/SimCompact/LeftBlock.css";
import "../assets/css/SimCompact/RightBlock.css";
import "../assets/css/SimCompact/BottomBlock.css";
import "../assets/css/SimCompact/FinanceBlock.css";
import "../assets/css/SimCompact/ResearchBlock.css";
import "../assets/css/SimCompact/PurchaseBlock.css";
import "../assets/css/SimCompact/ProductionBlock.css";
import "../assets/css/SimCompact/SalesBlock.css";
import LeftBlock from "./LeftBlock/LeftBlock";
import CenterBlock from "./CenterBlock/CenterBlock";
import RightBlock from "./RightBlock/RightBlock";
import BottomRightBlock from "./RightBlock/BottomRightBlock";
import BottomBlock from "./BottomBlock/BottomBlock";

let scrollIntervalTime = undefined;

export default function SimCompact(props) {
	const [canEdit, setCanEdit] = useState(true);

	const updateDragMousePosition = (ev) => {
		//console.log(ev)
		if (ev.clientX >= window.innerWidth - 100) {
			if (!scrollIntervalTime) {
				scrollIntervalTime = setInterval(() => {
					scrollRightAction();
				}, 50);
			}
		} else if (ev.clientX <= 100) {
			if (!scrollIntervalTime) {
				scrollIntervalTime = setInterval(() => {
					scrollLeftAction();
				}, 50);
			}
		} else {
			if (scrollIntervalTime) {
				clearInterval(scrollIntervalTime);
				scrollIntervalTime = undefined;
			}
		}
	};

	useEffect(() => {
		window.addEventListener("dragover", updateDragMousePosition);
		return () =>
			window.removeEventListener("dragover", updateDragMousePosition);
	}, []);

	const scrollRightAction = () => {
		window.scrollBy({
			left: 50,
			behavior: "smooth",
		});

		if (
			document.body.scrollWidth - window.innerWidth ===
			window.pageXOffset - (window.innerWidth - document.body.offsetWidth)
		) {
			if (scrollIntervalTime) {
				clearInterval(scrollIntervalTime);
				scrollIntervalTime = undefined;
			}
		}
	};

	const scrollLeftAction = () => {
		window.scrollBy({
			left: -50,
			behavior: "smooth",
		});

		if (window.pageXOffset === 0) {
			if (scrollIntervalTime) {
				clearInterval(scrollIntervalTime);
				scrollIntervalTime = undefined;
			}
		}
	};

	useEffect(() => {
		setCanEdit(props.canEdit);
	}, [props.canEdit]);

	return (
		<div className="App">
			<Container fluid>
				<Row className="Board">
					<div className="Left-column">
						<LeftBlock canEdit={canEdit} id={0} title={"interest"} />
						<LeftBlock canEdit={canEdit} id={1} title={"depreciation"} />
						<LeftBlock canEdit={canEdit} id={2} title={"sales"} />
					</div>
					<div className="Middle-column">
						<CenterBlock canEdit={canEdit} />
						<div>
							<Row>
								<Col>
									<BottomBlock canEdit={canEdit} id={3} />
								</Col>
							</Row>
						</div>
					</div>
					<div className="Right-column">
						<RightBlock title={"overheads"} />
						<BottomRightBlock canEdit={canEdit} id={4} title={"Direct costs"} />
					</div>
				</Row>
			</Container>
		</div>
	);
}
