import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Tooltip from "rc-tooltip/es";

// Components
import DroppableField from "../../Common/DroppableField";
import TokenDroppableContainer from "./TokenDroppableContainer"

// Helpers
import dropFieldClassNames from "../../../utils/dropFieldClassNames";
import * as RealtimeConnection from "../../RealtimeConnection";
import Translate from "../../../utils/Translate";

export default function FinanceBlock(props) {
	const dispatch = useDispatch()
	const FinanceState = useSelector((state) => state.board.CenterBlock.Finance)
	
	const [canEdit, setCanEdit] = useState(false);

	useEffect(() => {
		setCanEdit(props.canEdit);
	}, [props.canEdit]);

	const appendContainer = (coins, id, kind) => {
		// Update Socket
		RealtimeConnection.addContainer(kind, {
			id: id,
			coins: coins,
		});
	};

	const addCoinHandler = (id, coinValue, kind) => {
		RealtimeConnection.addCoin(kind, {
			id: id,
			value: coinValue,
		});
	};

	const removeContainerHandler = (id, isDropToDelete , kind) => {
		// Update Socket
		RealtimeConnection.removeContainer(kind, {
			id: id,
			isDropToDelete: isDropToDelete,
		});
	};

	const removeCoinHandler = (id, index, isDropToDelete,kind) => {
		// Update Socket
		RealtimeConnection.removeCoin(kind, {
			id: id,
			index: index,
			isDropToDelete: isDropToDelete,
		});
	};

	const onCapitalFocus = () => {
		console.log(" On Focus ");
		const dataObj = {
			teamId: window.teamId,
			sessionId: window.sessionId,
			playerId: window.playerId,
			playerName: window.playerName,
			alias: "Finance",
			isDragging: true,
			type: "equity"
		}
		window.socket.emit('capital-editing', dataObj);
	}

	const onCapitalEnd = () => {
		console.log(" On End ");
		const dataObj = {
			teamId: window.teamId,
			sessionId: window.sessionId,
			playerId: window.playerId,
			playerName: window.playerName,
			alias: "Finance",
			isDragging: false,
			type: "equity"
		}
		window.socket.emit('capital-editing', dataObj);
	}

	const onCapitalChange = (event) => {
		dispatch({ type: "UPDATE_CAPITAL", value: event.target.value });

		const dataObj = {
			teamId: window.teamId,
			sessionId: window.sessionId,
			playerId: window.playerId,
			playerName: window.playerName,
			value: event.target.value
		}
		window.socket.emit('update-capital', dataObj);
	}

	const isDraggingHandler = (type, isDragging, data,kind) => {
		let dataObj = {
			teamId: window.teamId,
			sessionId: window.sessionId,
			playerId: window.playerId,
			playerName: window.playerName,
			alias: kind,
			isDragging: isDragging,
			type: type,
		};
		dataObj = { ...dataObj, ...data };

		window.socket.emit("item-dragging", dataObj);
	};

	const showContainerProperties = (id, fieldName) => {
		dispatch({type: "PROPERTIES", alias: "FIELD", options: { fieldName: fieldName, id: id }})
	}

	const openTokenProperties = (tokenType) => {
		dispatch({ type: "PROPERTIES", alias: "TOKEN", options: { tokenType: tokenType } })
	}

	let showTooltip = false
	let canEditCapital = !canEdit
	if (FinanceState.isDragging && FinanceState.type === "equity") {
		if (FinanceState.playerId !== window.playerId) {
			showTooltip = true
			canEditCapital = false
		}
	}

	return (
		<div className="Finance-container">
			<div className="wrapper">
				<div style={{ width: "58%" }}>
					<Row>
						<Col>
							<div className="Finance-bold-title-text-size">
								<Translate alias="Board" word="Finance" />
							</div>
						</Col>
					</Row>
					<Row>
						<div>
							<span className="Finance-cash-text-size">
								<span className="Text-bold">
									<Translate alias="Board" word="Receivables" />
								</span>
							</span>
							<div className="Finance-droppable-container">
								<Row>
									{
										FinanceState.receivables.map((value, index) => {
											return (
												<React.Fragment key={index}>
													<Col onClick={ () => showContainerProperties(index, "receivables") }>
														<DroppableField
															id={index}
															class={dropFieldClassNames.Finance}
															canEdit={ true }
															isHorizontal={true}
															data={value}
															appendContainer={appendContainer}
															addCoin={addCoinHandler}
															kind={"Receivables"}
															removeContainer={removeContainerHandler}
															removeCoin={removeCoinHandler}
															isDraggingHandler={isDraggingHandler}
														/>
													</Col>
												</React.Fragment>
											);
										})
									}
								</Row>
							</div>
						</div>
						<div>
							<span className="Finance-cash-text-size">
								<span className="Text-bold">
									<Translate alias="Board" word="Cash" />
								</span>
							</span>
							<div className="Finance-droppable-container">
								<Row>
									{
										FinanceState.cash.map((value, index) => {
											return (
												<div key={index}>
													<Col onClick={ () => showContainerProperties(index, "cash") }>
														<DroppableField
															id={index}
															class={dropFieldClassNames.Finance}
															canEdit={ true }
															isHorizontal={true}
															data={value}
															appendContainer={appendContainer}
															addCoin={addCoinHandler}
															kind={"Cash"}
															isDraggingHandler={isDraggingHandler}
															removeContainer={removeContainerHandler}
															removeCoin={removeCoinHandler}
														/>
													</Col>
												</div>
											);
										})
									}
								</Row>
							</div>
						</div>
					</Row>
				</div>

				<div style={{ width: "40%" }}>
					<div className="Liability-block">
						<div className="">
							<Container>
								<Row>
									<Col>
										<div className="Finance-capital-text">
											<Translate alias="Board" word="Equity" />
										</div>
									</Col>
								</Row>
							</Container>
						</div>
						<div className="">
							<div className="equity-wrapper">
								<Tooltip placement="topRight" visible={ showTooltip }  overlay={<span>{ FinanceState.playerName }</span>}>
									<input
										id="capitalInput"
										value={ FinanceState.equity }
										readOnly={ canEditCapital }
										onChange={ onCapitalChange }
										onFocus={ onCapitalFocus }
										onBlur={ onCapitalEnd }
										className="capitalInput"
										type="text"
									/>
								</Tooltip>
							</div>
						</div>
						<div className="">
							<Container>
								<Row>
									<Col>
										<div className="Finance-capital-text">
											<Translate alias="Board" word="Liabilities" />
										</div>
									</Col>
								</Row>
							</Container>
						</div>
						<div className="" onClick={ () => openTokenProperties("liabilities") }>
							<TokenDroppableContainer
								fieldType={ "liabilities" }
								value={ FinanceState.liabilities }
								type={ FinanceState.liabilities.type }
								canEdit={ true }
							/>
						</div>
						<div className="">
							<Container>
								<Row>
									<Col>
										<div className="Finance-capital-text">
											<Translate alias="Board" word="Payables" />
										</div>
									</Col>
								</Row>
							</Container>
						</div>
						<div className="" onClick={ () => openTokenProperties("payables") }>
							<TokenDroppableContainer
								fieldType={ "payables" }
								value={ FinanceState.payables }
								type={ FinanceState.payables.type }
								canEdit={ true }
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
