import React, { useState, useEffect } from "react";
import { DndProvider } from "react-dnd";
import Backend from "react-dnd-html5-backend";
import { useDispatch, useSelector } from "react-redux";
import socketIOClient from "socket.io-client";
import axios from "axios";

import URLs from "./utils/urls";

// Components
import Header from "./components/Header";
import KitsAndObjects from "./components/KitsAndObjects";
import FilesLibrary from "./components/FilesLibrary";
import SimCompact from "./components/SimCompact";
import {
  updateDragStatus,
  updateCapitalStatus,
  addMessage,
  addMessagesList,
  updateTeamName,
  updateYear,
  restoreFormStatus,
  updateFormStatus,
  updateFiles,
  addFile,
  removeFile,
  updateCounter,
  updateState,
  updateCapital,
  addToken,
  removeToken,
  addContainerDirectCost,
  addContainer,
  addContainerColorField,
  addContainerFieldType,
  removeContainer,
  removeContainerColorField,
  removeContainerFieldType,
  addCoin,
  addCoinFieldType,
  removeCoin,
  removeCoinFieldType,
  addCoins,
  addCoinsFieldType,
  updateCoins,
  updateCoinsFieldType,
  addCard,
  rentFactory,
  purchaseFactory,
  updateTeamTable,
  addPin,
  removePin,
  removeStartKit,
  decreaseStarterKit,
  increaseStarterKit,
  increaseStartKitCoin,
  decreaseStartKitCoin,
  increaseStartKitToken,
  decreaseStartKitToken,
  increaseStartKitBlackToken,
  decreaseStartKitBlackToken,
  increaseStartKitPin,
  decreaseStartKitPin,
  increaseStartKitFactory,
  decreaseStartKitFactory,
  increaseStartKitMachine,
  decreaseStartKitMachine,
  decreaseStartKitProductCard,
  updateStartKitProduction,
  updateFormOption,
  updateForm,
  addSmile,
  removeSmileFace,
} from "./Redux/dispatchers";

export default function AppProvider(props) {
  const [socket, setSocket] = useState(undefined);
  const [canEdit, setCanEdit] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [teamName, setTeamName] = useState("");
  const [checklist, setChecklist] = useState([]);
  const [zoomValue, setZoomValue] = useState(100);

  // const [languages, setLanguages] = useState([]);
  const dispatch = useDispatch();
  const { State } = useSelector((state) => ({
    State: state.session,
  }));
  useEffect(() => {
    if (!socket) {
      const io = socketIOClient(URLs.socketEndpoint, {
        path: process.env.REACT_APP_SOCKET_PATH,
        upgrade: true,
        transports: ["websocket", "polling"],
      });
      window.socket = io;
      setSocket(io);
    }
  }, [socket]);
  useEffect(() => {
    setCanEdit(props.canEdit);
  }, [props.canEdit]);
  useEffect(() => {
    setIsAdmin(props.isAdmin);
  }, [props.isAdmin]);

  useEffect(() => {
    setTeamName(props.teamName);
  }, [props.teamName]);
  useEffect(() => {
    if (socket) {
      socket.on("connect", (data) => {
        console.log("Connected To WS");

        socket.emit("user-data", {
          user_id: window.playerId,
          team_id: window.teamId,
          session_id: window.sessionId,
          token: window.token,
        });
      });

      socket.on("item-dragging", (data) => {
        dispatch(
          updateDragStatus(data.alias, data.type, data.isDragging, data)
        );
      });
      socket.on("capital-editing", (data) => {
        console.log(data);
        dispatch({
          type: "UPDATE_CAPITAL_STATUS",
          itemType: data.type,
          data: data,
          isDragging: data.isDragging,
        });
      });

      socket.on("add-message", (data) => {
        dispatch(addMessage(data));
      });

      socket.on("messages-list", (data) => {
        dispatch(addMessagesList(data));
      });

      socket.on("change-team", (data) => {
        dispatch({ type: "UPDATE_TEAM_NAME", value: data.value });
        //dispatch(updateTeamName(data));
      });

      socket.on("change-year", (data) => {
        dispatch(updateYear(data));
      });

      socket.on("restore-form-status", (data) => {
        console.log(data);
        dispatch(restoreFormStatus(data));
      });

      socket.on("form-status", (data) => {
        console.log(data);
        dispatch(updateFormStatus(data));
      });

      socket.on("files-list", (data) => {
        console.log("Files List");
        console.log(data);
        dispatch({ type: "UPDATE_FILES", value: data });
        //dispatch(updateFiles(data));
      });

      socket.on("add-file", (data) => {
        console.log("Add File");
        console.log(data);
        if (
          parseInt(data.teamId) === parseInt(window.teamId) ||
          parseInt(data.teamId) === 0
        ) {
          dispatch({ type: "ADD_FILE", value: data });
        }
      });

      socket.on("remove-file", (data) => {
        if (
          parseInt(data.teamId) === parseInt(window.teamId) ||
          parseInt(data.teamId) === 0
        ) {
          dispatch(removeFile(data._id));
        }
      });

      socket.on("add-timer", (data) => {
        dispatch(updateCounter(data.data));
      });

      socket.on("remove-timer", (data) => {
        dispatch(
          updateCounter({
            duration: 0,
            createdAt: 0,
          })
        );
      });

      socket.on("restore-session", (data) => {
        console.log("Restore Session");
        console.log(data);
        dispatch({ type: "UPDATE_STATE", data: data });
        //dispatch(updateState(data));
        if (data.activeForm !== undefined) {
          console.log(data.activeForm);
          updateFormStatus(data.activeForm);
        }
      });

      socket.on("update-capital", (data) => {
        dispatch(updateCapital(data.value));
      });

      socket.on("add-token", (data) => {
        dispatch(addToken(data.type, data.value, data.droppedTokenType));
      });

      socket.on("remove-token", (data) => {
        dispatch(removeToken(data.type, data.value));
      });

      socket.on("add-container", (data) => {
        dispatch({ type: "ADD_CONTAINER", data: data });
      });

      socket.on("remove-container", (data) => {
        dispatch({ type: "REMOVE_CONTAINER", data: data });
      });

      socket.on("add-coin", (data) => {
        dispatch({ type: "ADD_COIN", data: data });

        /*if (data.fieldType === undefined) {
				console.log(data);

					dispatch(
						addCoin(
							data.alias,
							data.id,
							data.value,
							data.playerId,
							data.playerName
						)
					);
				} else {
					dispatch(
						addCoinFieldType(
							data.alias,
							data.fieldType,
							data.value,
							data.id,
							data.playerId,
							data.playerName
						)
					);
				}*/
      });

      socket.on("remove-coin", (data) => {
        if (data.fieldType === undefined) {
          dispatch(
            removeCoin(
              data.alias,
              data.id,
              data.index,
              data.playerId,
              data.playerName
            )
          );
        } else {
          dispatch(
            removeCoinFieldType(
              data.alias,
              data.fieldType,
              data.index,
              data.id,
              data.playerId,
              data.playerName
            )
          );
        }
      });

      socket.on("add-coins", (data) => {
        if (data.fieldType === undefined) {
          dispatch(
            addCoins(
              data.alias,
              data.id,
              data.coins,
              data.playerId,
              data.playerName
            )
          );
        } else {
          dispatch(
            addCoinsFieldType(
              data.alias,
              data.fieldType,
              data.coins,
              data.id,
              data.playerId,
              data.playerName
            )
          );
        }
      });

      socket.on("update-coins", (data) => {
        if (data.fieldType === undefined) {
          dispatch(
            updateCoins(
              data.alias,
              data.id,
              data.coins,
              data.playerId,
              data.playerName
            )
          );
        } else {
          dispatch(
            updateCoinsFieldType(
              data.alias,
              data.fieldType,
              data.coins,
              data.id,
              data.playerId,
              data.playerName
            )
          );
        }
      });

      socket.on("add-card", (data) => {
        dispatch(
          addCard(data.alias, data.optionType, data.id, data.value, data.color)
        );
      });

      socket.on("rent-factory", (data) => {
        dispatch(rentFactory(data.id));
      });

      socket.on("purchase-factory", (data) => {
        dispatch(purchaseFactory(data.id));
      });

      socket.on("update-team-table", (data) => {
        dispatch(updateTeamTable(data));
      });

      socket.on("add-pin", (data) => {
        dispatch(addPin(data.alias, data.id));
      });

      socket.on("add-smile-face", (data) => {
        console.log("here");

        dispatch(addSmile(data.alias, data.id, data.number));
      });

      socket.on("remove-pin", (data) => {
        dispatch(removePin(data.alias, data.title, data.speed, data.id));
      });

      socket.on("remove-smile-face", (data) => {
        dispatch(removeSmileFace(data.alias, data.id, data.number));
      });

      socket.on("remove-starter-kit", (data) => {
        dispatch(removeStartKit(data.id));
      });

      socket.on("decrease-starter-kit", (data) => {
        dispatch(decreaseStarterKit(data));
      });

      socket.on("increase-starter-kit", (data) => {
        console.log("increase-starter-kit");
        console.log(data);
        dispatch(increaseStarterKit(data));
      });

      socket.on("add-starter-kit-coin", (data) => {
        dispatch(increaseStartKitCoin(data.value));
      });

      socket.on("remove-starter-kit-coin", (data) => {
        dispatch(decreaseStartKitCoin(data.value));
      });

      socket.on("add-starter-kit-token", (data) => {
        dispatch(increaseStartKitToken(data.value));
      });

      socket.on("remove-starter-kit-token", (data) => {
        dispatch(decreaseStartKitToken(data.value));
      });

      socket.on("add-starter-kit-black-token", (data) => {
        dispatch(increaseStartKitBlackToken(data.value));
      });

      socket.on("remove-starter-kit-black-token", (data) => {
        dispatch(decreaseStartKitBlackToken(data.value));
      });

      socket.on("add-starter-kit-pin", (data) => {
        dispatch(increaseStartKitPin(data.count));
      });

      socket.on("remove-starter-kit-pin", (data) => {
        dispatch(decreaseStartKitPin(data.count));
      });

      socket.on("add-starter-kit-factory", (data) => {
        dispatch(increaseStartKitFactory(data.count));
      });

      socket.on("remove-starter-kit-factory", (data) => {
        dispatch(decreaseStartKitFactory(data.count));
      });

      socket.on("add-starter-kit-machine", (data) => {
        dispatch(increaseStartKitMachine(data.count));
      });

      socket.on("remove-starter-kit-machine", (data) => {
        dispatch(decreaseStartKitMachine(data.count));
      });

      socket.on("remove-starter-kit-production", (data) => {
        dispatch(decreaseStartKitProductCard(data.value, data.color));
      });

      socket.on("update-starter-kit-production", (data) => {
        console.log("On Update Starter Kit Production");
        console.log(data);

        dispatch(updateStartKitProduction(data.value));
      });

      socket.on("reset-form", (data) => {
        dispatch({ type: "UPDATE_FORM", form: data.type, value: data.value });
      });

      socket.on("update-form", (data) => {
        if (State.year === State.formSelectedYear) {
          if (data.option !== undefined) {
            dispatch(
              updateFormOption(
                data.type,
                data.section,
                data.field,
                data.option,
                data.value
              )
            );
          } else {
            dispatch(
              updateFormOption(
                data.type,
                data.section,
                data.field,
                undefined,
                data.value
              )
            );
          }
        }
      });

      socket.on("session-status-changed", (data) => {
        checkSessionStatus(data.status);
      });

      socket.on("change-checklist-period", () => {
        fetchChecklist();
      });
    }
  }, [socket]);

  const fetchChecklist = async () => {
    const response = await axios.post(URLs.checklist, {
      sessionId: window.sessionId,
      languageId: localStorage.langId,
    });
    console.log(response);
    if (response.data.success) {
      setChecklist(response.data.checklist);
    }
  };

  const checkSessionStatus = (status) => {
    switch (parseInt(status)) {
      case 2: // Session Running
        setCanEdit(true);
        break;
      case 3: // Session Paused
        setCanEdit(false);
        break;
      default:
        break;
    }
  };

  return (
    <DndProvider backend={Backend}>
      <Header
        canEdit={canEdit}
        isAdmin={isAdmin}
        logoutAction={props.logout}
        teamName={teamName}
        zoomValue={zoomValue}
        setZoomValue={setZoomValue}
        languages={props.languages}
      />
      <KitsAndObjects canEdit={canEdit} />
      <div className={`main-app-bg zoom${zoomValue}`}>
        <SimCompact canEdit={canEdit} />
      </div>
      <FilesLibrary canEdit={canEdit} checklist={checklist} />
    </DndProvider>
  );
}
